import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { FILE_TYPE } from 'src/app/constants/file-types';
import { BusinessPostService } from 'src/app/services/business_post.service';
import { FormValidatorServiceService } from 'src/app/services/form-validator-service.service';
import { environment } from '../../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
const BASE_URL =  environment.BASE_URL;

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss'],
    standalone: false
})

export class PostComponent implements OnInit , OnDestroy {
  
  submited = false;
  isCopied = "fa-copy";
  addPostForm:UntypedFormGroup
  filterForm:UntypedFormGroup
  
  PostDetails:Array<any> = []

  postTypes:Array<any> = []
  Products:Array<any> = []

  viewTimer:any
  hasRun = null

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private businessPostService:BusinessPostService,
    private formBuilder:FormBuilder,
    private formValidationService:FormValidatorServiceService,
    private titleService:Title,
		private metaService: Meta,
    private userService: UserService,
  ){}

  ngOnInit(): void {
    
    const { params } = this.route.snapshot ;
    const { id } = params;

    this.titleService.setTitle("Posts | Gujarat Tiles Info");
    this.metaService.addTags([
      //For Whatsapp Link Preview
      { property: "og:title", content: "WhatsApp" },
      { property: "og:description", content: "Simple. Secure. Reliable messaging." },
      { property: "og:url", content: "https://whatsapp.com" },
      { property: "og:image", content: "https://static.whatsapp.net/rsrc.php/ym/r/36B424nhiL4.svg" },

      //For Twitter Link Preview
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: "summary_large_image" },
      { name: "twitter:description", content: "summary_large_image" },
      { name: "twitter:image", content: "summary_large_image" },
    ])

    this.initAddPostForm()
    this.initFilterForm()
    this.loadPost({selected:id ?? 0},{},!Boolean(id),true)
  }

  ngOnDestroy() {
    this.clearTime();
  }

  setMetaData(post) {
    const title = (post.company?.name ?? 'Business') + ' | Post'
    this.titleService.setTitle(title);
    setTimeout(() => {

      this.titleService.setTitle(title);

      this.metaService.removeTag('name = "description"');
      this.metaService.removeTag('name = "title"');

      this.metaService.removeTag('property = "og:title"');
      this.metaService.removeTag('property = "og:url"');
      this.metaService.removeTag('property = "og:description"');
      this.metaService.removeTag('property = "og:image"');
      
      this.metaService.removeTag('name = "twitter:card"');
      this.metaService.removeTag('name = "twitter:title"');
      this.metaService.removeTag('name = "twitter:description"');
      this.metaService.removeTag('name = "twitter:image"');

      this.metaService.addTags([
        { name: "title", content: title},
        { name: "description", content: post?.name ?? '' },
        

        { property: "og:title", content: title },
        { property: "og:description", content: post?.name ?? '' },
        { property: "og:url", content: this.getPostUrl(post?.slug) },
        { property: "og:image", content: post.image },

        //For Twitter Link Preview
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: post?.name ?? '' },
        { name: "twitter:image", content: post.image },
      ]);
    }, 0);
  }

  initFilterForm():void{
    this.filterForm = this.formBuilder.group({
      'product_id' : [''],
      'post_type' : [''] 
    })
  }
  initAddPostForm():void{
    this.addPostForm = this.formBuilder.group({
      'description' : ['',[Validators.required]],
      'post_url' : [''],
      'post' : ['',[Validators.required]],
      'product_id' :  ['',[Validators.required]],
      'category_id' :  ['',[Validators.required]],
      'post_type' : ['',[Validators.required]] 
    })
  }
  get description(){
    return this.addPostForm.get('description')
  }
  get post_type(){
    return this.addPostForm.get('post_type')
  }
  get product(){
    return this.addPostForm.get('post_type')
  }
  get post(){
    return this.addPostForm.get('post')
  }

  loadPost(params:any={},headers:any={},set_active=false,update_option=false):void{
   
    this.businessPostService.get(params,headers)
      .subscribe(response=>{
        if (response.success) {

          if(response.data.length <= 0){
            this.userService.errorMessage('Posts Not Found')
            return ;
          }

          this.PostDetails = response.data
          if((set_active&&this.PostDetails.length > 0)||(!update_option&&this.hasRun!=this.PostDetails[0].slug)){
            this.activePost(this.PostDetails[0])
          }
          if(update_option){
            this.postTypes = response.extra.post_types
            this.Products = response.extra.products
          }
          if (this.hasRun) {
            document.getElementById('posts-wrapper').scrollTo(0,0)
          }
        }
      })
  }

  manageLike(post) {
    this.businessPostService.manage_like(post.id)
      .subscribe(response=>{
        if (response.success) {
          post.likes =  !post.is_liked ? post.likes + 1 : post.likes - 1
          post.is_liked = !post.is_liked
        }
      });
  }
  manageShare(post) {
    this.businessPostService.manage_shares(post.id)
      .subscribe(response=>{
        if (response.success) {
          post.shares = post.shares + 1
        }
      });
  }
  manageWhatsapp(post) {
    this.businessPostService.manage_whatsapp(post.id)
      .subscribe(response=>{
        if (response.success) {
          post.whatsapp = post.whatsapp + 1
        }
      });
  }
  manageView(post) {
    this.businessPostService.manage_view(post.id)
      .subscribe((resonse)=>{
        if(resonse.success){
          post.views = post.views + 1
        }
    })  
  }

  callbackLoadPost(){
    const filter = this.getFilters()
    filter['selected'] = this.hasRun
    this.loadPost(filter,{
        'X-Skip-Interceptor' : 'true',
    })
  }

  activePost(post){
    this.clearTime()
    if (this.hasRun!==post.slug) {
      this.viewTimer = setTimeout(()=>{
        this.hasRun = post.slug;
        this.manageView(post)
      },3000)  
    }
    
    this.setMetaData(post)
    this.router.navigate([this.getPostUrl(post.slug,false)])
  }

  clearTime(){
    if(this.viewTimer){
      clearTimeout(this.viewTimer)
      this.viewTimer = null
    }
  }

  getPostUrl(slug:string,full:boolean=true):string{
    const base_url = full ? BASE_URL : ''
    return base_url + `post/${slug}`
  }
  
  copyText(value) {
    this.isCopied = "fa-clipboard"
    navigator.clipboard.writeText(value);
    setTimeout(()=>{
      this.isCopied = "fa-copy"
    },1000)
  }
// socialShare(){
//   if(navigator.share){
//    navigator.share({
//     title:'share your Link',
//     url: 'https://www.gujarattilesinfo.com/'
//    }).then(() => {
//     console.log('send is success');
//    })
//    .catch(console.error);
//   }
// }

  filterChange():void{
    const params = this.getFilters()
    this.loadPost(params)
  }

  getFilters(){
    const {product_id , post_type } = this.filterForm.value
    const params = {
      product_id : product_id ?? 0,
      post_type : post_type ?? 0 ,
      category_id : this.Products.find((p)=>p.id == product_id)?.category_id ?? 0 
    } 
    return params
  }

  onProductChange(event){
    const { value }  = event.target
    console.log(value)
    const category_id = this.Products.find((p)=>p.id == value)?.category_id ?? 0 
    this.addPostForm.patchValue({
      category_id : category_id
    })
  }

  onSubmit():void{
    this.submited = true
    if (this.addPostForm.valid) {
      let formData = new FormData();

      Object.keys(this.addPostForm.value)
        .filter(key=>!key.includes('_url'))
        .forEach(key=>{
          formData.append(key,this.addPostForm.value[key])
        })


      this.businessPostService.store(formData)
        .subscribe(response=>{
          if(response.success){
            this.loadPost()
            this.initAddPostForm()
          }
        })
    }else{
      console.log('please fill all inputs')
    }
  }
  
  onSelectImage(form: FormGroup,files: FileList,id: string,id_post_fix: string | null = null) {
    if (files.length === 0) return;

    const elementId = id_post_fix !== null ? id + id_post_fix : id;
    const isInValidSize = files[0].size > 2 * 1024 * 1024;

    const isValidExtension = FILE_TYPE.indexOf(files[0].name.split(".").pop().toLowerCase()) > -1;

    if (isInValidSize) {
      this.formValidationService.validateFormField(
        form,
        id,
        "File size is larze; maximum file size 2 MB (2048 KB)"
      );
      this.clearInput(elementId);
      return;
    }

    if (!isValidExtension) {
      this.formValidationService.validateFormField(
        form,
        id,
        "Image type must be:" + FILE_TYPE.join(", ")
      );
      this.clearInput(elementId);
      return;
    }

    form.patchValue({ [id]: files[0] });
    var reader = new FileReader();
    reader.onload = (event: any) => {
      form.patchValue({ [id + "_url"]: event.target.result });
    };
    reader.readAsDataURL(files[0]);
  }

  clearInput(id): void {
    const documentElement = <HTMLInputElement>document.getElementById(id);
    if (documentElement) {
      documentElement.value = "";
    }
  }

  

}
