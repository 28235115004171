<main>
	<!-- <app-breadcrumb [pageName]="'Analytics'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Total Analytics</h4>
						<h1>Analytics</h1>
						<!-- <img src="assets/img/line.svg" width="120"> -->
					</div>
				</div>
			</div>

			<div class="row box-h mt-30">
				<div class="col-xl-4 col-md-4 col-sm-4 col-xs-12 col-h">
					<div class="details analyticss-data bg-transparent gti_anali_detai_list_ban">
						
						<div style="overflow-x:auto;">
							<table>
								<thead>
									<tr>
										<th colspan="2"><h4>Details</h4></th>
								    </tr>
								</thead>
								<tr>
									<td>Inquiries</td>
									<td>{{data.inquiry}}</td>
								</tr>
								<!-- <tr>
									<td>Inquiries</td>
									<td>{{data.inquiry}}</td>
								</tr> -->
								<tr>
									<td>Call</td>
									<td>{{data.call_click}}</td>
								</tr>
								<tr>
									<td>Whatsapp</td>
									<td>{{data.whatsapp_click}}</td>
								</tr>
								<tr>
									<td>Location</td>
									<td>{{data.location_click}}</td>
								</tr>
								<tr>
									<td>Mail</td>
									<td>{{data.mail_click}}</td>
								</tr>
								<tr>
									<td>Social Media</td>
									<td>{{data.social_media_click}}</td>
								</tr>
								<tr>
									<td>Product Gallery</td>
									<td>{{data.product_gallery_click}}</td>
								</tr>
								<tr>
									<td>Company Photos</td>
									<td>{{data.company_photos_click}}</td>
								</tr>
								<tr>
									<td>Catalogue</td>
									<td>{{data.catalogue_click}}</td>
								</tr>
								<tr>
									<td>Profile View</td>
									<td>{{data.profile_view}}</td>
								</tr>
								<tr>
									<td class="border-0">Website Click</td>
									<td class="border-0">{{data.website_click}} </td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div class="col-xl-8 col-md-8 col-sm-8 col-xs-12 col-h">
					<div class="details bg-transparent ">
						<canvas 
							*ngIf="pieChartData.datasets[0].data.length > 0"
							baseChart 
							[data]="pieChartData" 
							[labels]="pieChartLabels" 
							[options]="pieChartOptions" 
							[plugins]="pieChartPlugins" 
							[legend]="pieChartLegend"
							[type]="pieChartType"
							>
							 
						</canvas>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->

</main>