import { FormValidatorServiceService } from '../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TilesManufactureService } from '../../services/tiles-manufacture.service';
import { UserService } from '../../services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tiles-calculator',
    templateUrl: './tiles-calculator.component.html',
    styleUrls: ['./tiles-calculator.component.css'],
    standalone: false
})

export class TilesCalculatorComponent implements OnInit {

	sizes = [];
	result = {
		requiredBoxes:0,
		requiredTiles:0,
		totalSquareMeter:0,
		totalSquareFeet:0
	}
	subCategories = [];
	calculationForm: FormGroup;
	// categories = [];

  	constructor(
	  	private formBuilder: FormBuilder,
	  	private userService : UserService,
	  	private tilesManufactureService:TilesManufactureService,
	  	private formValidationService:FormValidatorServiceService
	) {}
	
	ngOnInit(): void {
		this.calculationForm = this.formBuilder.group({
			// category:["",[Validators.required]],
			sub_category:["",[Validators.required]],
			size:["",[Validators.required]],
			width:  ["",[Validators.required]],
			length: ["",[Validators.required]],
		});
		this.getSubCategory()
		// this.tilesManufactureService.getCategory().subscribe((response)=>{
  		// 	if(response.success) {
  		// 		this.categories = response.data;
  		// 		//this.userService.sucessMessage(response.message);
  		// 	}
  		// 	else {
  		// 		this.userService.errorMessage(response.message);
  		// 	}
  		// },error=>{
  		// 	this.userService.errorMessage("Internal server error");
  		// });
  	}

  	getSubCategory() {
  		this.tilesManufactureService.getSubCategory({}).subscribe((response)=>{
  			if(response.success) {
  				this.subCategories = response.data;
  				//this.userService.sucessMessage(response.message);
  			}
  			else {
  				this.userService.errorMessage(response.message);
  			}
  		},error=>{
  			this.userService.errorMessage("Internal server error");
  		});
  	}

  	getSize() {
  		const data = {sub_category:this.calculationForm.value.sub_category};
  		this.tilesManufactureService.getSize(data).subscribe((response)=>{
  			if(response.success) {
				// console.log(response)
				
  				this.sizes = response.data;
  				//this.userService.sucessMessage(response.message);
  			}
  			else {
  				this.userService.errorMessage(response.message);
  			}
  		},(error)=> {
  			this.userService.errorMessage("Internal server error");
  		});
  	}

  	onSubmit() {
  		if (this.calculationForm.valid) {
  			this.tilesManufactureService.calculator(this.calculationForm.value).subscribe((response)=>{
	  			if(response.success) {
					this.result.requiredTiles = response.data.requiredTiles,
	  				this.result.requiredBoxes = response.data.requiredBoxes,
					this.result.totalSquareMeter = response.data.totalSquareMeter,
					this.result.totalSquareFeet = response.data.totalSquareFeet,
	  				this.userService.sucessMessage(response.message);
	  			}
	  			else {
	  				this.userService.errorMessage(response.message);
	  			}
	  		},(error)=> {
	  			this.userService.errorMessage("Internal server error");
	  		});
  		}
  		else {
  			this.formValidationService.validateAllFormFields(this.calculationForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
  		}
  	}
}
