import { environment } from '../../environments/environment';
const url =  environment.API_URL;
// const url =  'http://192.168.1.197:8000/api/';
export const apiConstants = {
	statusListById: url + 'company/statuslistbyid',
	statusList: url + 'company/statuslist',
	updateStatus: url + 'company/updatestatus',
	getCompanyList: url + 'company/list',
	getCompanyListall: url + 'company/listall',
	forgot_password: url + 'forgot_password',
	verify_forgot_password_otp: url + 'verify_forgot_password_otp',
	reset_password: url + 'reset_password',
	loginMobile				: url + 'loginMobile',
	login				: url + 'login',
	register			: url + 'register',
	profile				: url + 'profile',
	profileSave			: url + 'profile/save',
	verifyOtp			: url + 'verify',
	registerFields		: url + 'registerfields',
	home				: url + 'web/home',
	products			: url + 'options/products',
	directory			: url + 'directory',
	directorymore		: url + 'directorymore',
	follow				: url + 'follow',
	followers			: url + 'followers',
	followings			: url + 'followings',
	notification		: url + 'notification',
	getAccessRole		: url + 'getAccessRole',
	search				: url + 'search',
	searchByCategory	: url + 'searchByCategory',
	searchMore			: url + 'search/moreCompanies',
	newsletter			: url + 'newsletter',
	support				: url + 'support',
	feedback				: url + 'feedback',
	digitalCard			: url + 'digitalvisitorcard',
	manufacture:{
		tiles:{
			list	: url+'tilesmanufacture/list',
			more	: url+'tilesmanufacture/morecompany',
			details	: url+'tilesmanufacture/details',
			category: url+'tilesmanufacture/category',
			sub_category: url+'tilesmanufacture/sub_category',
			size: url+'tilesmanufacture/size',
			calculator: url+'tilesmanufacture/calculator',
		},
		sanitary:{
			list	: url+'sanitarymanufacture/list',
			more	: url+'sanitarymanufacture/morecompany',
			details	: url+'sanitarymanufacture/details',
		},
		various:{
			list	: url+'variousmanufacture/list',
			more	: url+'variousmanufacture/morecompany',
			details	: url+'variousmanufacture/details',
		},
		variousmore:{
			list	: url+'variousmanufactureMore/list',
			more	: url+'variousmanufactureMore/morecompany',
			details	: url+'variousmanufactureMore/details',
		}
	},
	supplier:{
		list	: url+'supplier/list',
		more	: url+'supplier/morecompany',
		details	: url+'supplier/details',
	},
	serviceprovider:{
		list	: url+'serviceprovider/list',
		more	: url+'serviceprovider/morecompany',
		details	: url+'serviceprovider/details',
	},
	cab:{
		list	: url+'cab/list',
		more	: url+'cab/morecompany',
		details	: url+'cab/details',
	},
	transporter:{
		list	: url+'transporter/list',
		more	: url+'transporter/morecompany',
		details	: url+'transporter/details',
	},
	hotel:{
		list	: url+'hotel/list',
		more	: url+'hotel/morecompany',
		details	: url+'hotel/details',
	},
	dealer:{
		list	: url+'dealer/list',
		more	: url+'dealer/morecompany',
		details	: url+'dealer/details',
	},
	trader:{
		list	: url+'trader/list',
		more	: url+'trader/morecompany',
		details	: url+'trader/details',
	},
	sellerpost:{
		fields	: url+'sellerpost/fields',
		save	: url+'sellerpost/save',
		list	: url+'sellerpost/list',
		details	: url+'sellerpost/details',
		mypost	: url+'sellerpost/mypost',
		getpost	: url+'sellerpost/getpost',
		update	: url+'sellerpost/update',
	},
	buyerpost:{
		fields	: url+'buyerpost/fields',
		save	: url+'buyerpost/save',
		list	: url+'buyerpost/list',
		details	: url+'buyerpost/details',
		mypost	: url+'buyerpost/mypost',
		getpost	: url+'buyerpost/getpost',
		update	: url+'buyerpost/update',
	},
	business_post:{
		list: url+'business-post',
		store: url+'business-post/store',
		manage_like: url+'business-post/manage-likes/',
		manage_shares: url+'business-post/manage-shares/',
		manage_whatsapp: url+'business-post/manage-whatsapp/',
		manage_view: url+'business-post/manage-view/',
	},
	companyJob:{
		list	: url+'company/jobs',
		save	: url+'company/jobs/save',
	},
	candidate:{
		list	: url+'candidate/list',
		save	: url+'candidate/list/save',
	},
	inquiry:{
		list	: url+'inquiry/',
		save	: url+'inquiry/store',
		general	: url+'inquiry/general',
		readInquiry	: url+'inquiry/read',
	},
	analytics:{
		save	: url+'analytics',
		count	: url+'analytics/count',
	},
	pages:{
		page	: url+'pages/', 
		about	: url+'pages/about',
		privacy	: url+'pages/privacy-policy',
		term	: url+'pages/term-condition',
		member : url+'pages/membership-plan',
		specification : url+ 'pages/specification',
		domestic	: url+'pages/packaging-detail/domestic',
		export	: url+'pages/packaging-detail/export',
	},
	blogs:{
		list	: url+'blogs',
		getById	: url+'blogs/',
	},
	defaulter:{
		list	: url+'defaulter',
		save	: url+'defaulter/save',
	},
	company:{
		identity_find : url+'company/search/identity-find',
		refrence_find : url+'company/search/refrence-find',
		list : url+'directory/companies/',
		detail : url+'directory/companies/details',
		search : url+'options/companies/',
		directory_search : url+'directory/companies/search'
	},
	exhibition : {
		list : url + 'exhibition',
		company_exhibiton : url + 'exhibition/company',
		virtual_exhibiton : url + 'exhibition/virtual',
		company_exhibiton_click : url + 'exhibition/company/click',
		virtual_exhibiton_click : url + 'exhibition/virtual/click',
		banner_click : url + 'exhibition/banner/click'
	}
};
