import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-trending-brand',
    templateUrl: './trending-brand.component.html',
    styleUrls: ['./trending-brand.component.scss'],
    standalone: false
})
export class TrendingBrandComponent {
  @Input() brands:any = []

  @Output('onBannerClick') onBannerClick:EventEmitter<any> = new EventEmitter<any>();

  onClick(banner){
    const analytic_type = 15
    this.onBannerClick.emit({banner,analytic_type})
  }
}
