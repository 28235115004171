import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Notification } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	currentMessage = new BehaviorSubject(null);
	constructor(private angularFireMessaging: AngularFireMessaging) {
		
	}
	requestPermission() {
		this.angularFireMessaging.requestToken.subscribe((token) => {
			localStorage.setItem('fcm_token',token);
		});
	}
	receiveMessage() {
		this.angularFireMessaging.messages.subscribe((payload) => {	
			console.log('fromservice',payload);
			// this.customNotification(payload)
			this.currentMessage.next(payload);
		})
	}
	// customNotification(payload : any){
	// 	console.log('custom')
	// 	let data = payload['notification'];
	// 	let title = data['title'];
	// 	let option = {
	// 		body: data['body']+'adasasd---------------',
	// 		icon: "../../assets/img/logo.png",
	// 		badge: "../../assets/img/logo.png",
	// 	}
	// 	let notify:any = new Notification(title, option);
	// 	notify.onclick = event =>{
	// 		window.location.href = "https://google.com"
	// 	}
	// }
}
