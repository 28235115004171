import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { UserService } from '../../services/user.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { InquiryService } from '../../services/inquiry.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
    standalone: false
})
export class SearchComponent implements OnInit {
    premium:any = [];
	statndard:any = [];
	classic:any = [];
	general:any = [];
    data:any = [];

	totalPages:number = 0;
	nextPage:number = 0;

	tiles_manufacture:boolean = false;
	sanitary_manufacture:boolean = false;
	various_manufacture:boolean = false;
	trader:boolean = false;
	dealer_india:boolean = false;
	dealer_other:boolean = false;
	transporter:boolean = false;
	supplier:boolean = false;
	hotel:boolean = false;
	service_provider:boolean = false;
	cab:boolean = false;

    customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		autoplay: true,
		autoplayTimeout: 2000,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 1
			},
			740: {
				items: 1
			},
			940: {
				items: 1
			}
		},
		nav: true
	}

    constructor(
        private searchService : SearchService,
        private userService : UserService,
        private router : Router,
        private inquiryService: InquiryService,
    ) { }

    ngOnInit(): void {
        this.setAccessRole();
		this.userService.accessRoleListener.subscribe((response) => {
			this.setAccessRole();
		});
        
        if(history.state.category){
            this.searchService.searchBycategory({category: history.state.category}).subscribe(response => {
                this.response(response,true);
            })
        }else if(history.state.search){
            this.searchService.search({search: history.state.search}).subscribe(response => {
                this.response(response,true);
            })
        }else{
            this.router.navigate(['/']);
        }
    }
    setAccessRole():void{
		let temp_access_role = this.userService.getAccessRole();
		if(temp_access_role?.is_access_role_available == '1'){
			temp_access_role = JSON.parse(temp_access_role.access_role);
			this.tiles_manufacture = temp_access_role?.tiles_manucature ? true : false;
			this.sanitary_manufacture = temp_access_role?.sanitary_manucature ? true : false;
			this.various_manufacture = temp_access_role?.various_manucature ? true : false;
			this.dealer_india = temp_access_role?.dealer_india ? true : false;
			this.dealer_other = temp_access_role?.dealer_other ? true : false;
			this.trader = temp_access_role?.trader ? true : false;
			this.transporter = temp_access_role?.transporter ? true : false;
			this.supplier = temp_access_role?.supplier ? true : false;
			this.hotel = temp_access_role?.hotel ? true : false;
			this.service_provider = temp_access_role?.service_provider ? true : false;
			this.cab = temp_access_role?.cab ? true : false;
		}
	}
    response(response, flag = false):void{
        if(response.success){
            if(flag){
                this.data = response.data;
                this.premium = response.data.premium;
                this.statndard = response.data.statndard;
                this.classic = response.data.classic;
                this.general = response.data.general;
            }else{
                response.data.forEach(element => {
					this.general.push(element)
				});
            }
            this.totalPages = response.extra.total_pages;
            this.nextPage = response.extra.next_page;   
        }else{
            this.userService.errorMessage(response.message);
        }
    }
    Inquiry(id, type, is_indian):void{
		if(this.getAccess(type, is_indian)){
			let user = this.userService.getUser();
			let company_id = user.company_id ? user.company_id : 0; 
			this.inquiryService.save({from_company_id: company_id, to_company_id: id}).subscribe(res => {
				if(res.success){
					this.userService.sucessMessage(res.message);
				}else{
					this.userService.errorMessage(res.message);
				}
			})
		}
	}
    moreCompanies():void{
		let param = {next_page: this.nextPage};
		
        if(history.state.category){
            param = {...param,...{category : history.state.category}}
            this.searchService.more(param).subscribe(response => {
                this.response(response);
            })
        }else if(history.state.search){
            param = {...param,...{search : history.state.search}}
            this.searchService.more(param).subscribe(response => {
                this.response(response);
            })
        }
	}
    getAccess(type, is_indian):boolean{
        if(type == 1){
            return this.tiles_manufacture;
        }else if(type == 2){
            return this.sanitary_manufacture;
        }else if(type == 3){
            return this.various_manufacture;
        }else if(type == 4){
            return this.trader;
        }else if(type == 5){
            return is_indian ? this.dealer_india : this.dealer_other;
        }else if(type == 6){
            return this.transporter;
        }else if(type == 7){
            return this.supplier;
        }else if(type == 8){
            return this.hotel;
        }else if(type == 9){
            return this.service_provider;
        }else if(type == 10){
            return this.cab;
        }else{
            return false;
        }
        return false;
    }
}
