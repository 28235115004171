import { Component, OnInit } from '@angular/core';
import { InquiryService } from '../../services/inquiry.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.css'],
    standalone: false
})
export class InboxComponent implements OnInit {

	data:any = [];

	constructor(
		private inquiryService: InquiryService,
		private userService: UserService,
	) { }

	ngOnInit(): void {
		this.inquiryService.getList().subscribe(res => {
			if(res.success){
				console.log(res.data)
				this.data = res.data;
			}else{
				this.userService.errorMessage(res.message);
			}
		});
		this.userService.setSidebarCountListener({type: "inquiry", count: 0, curruntUrl: "/inbox"});
	}

}
