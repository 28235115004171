import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { FormValidatorServiceService } from '../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

//import { HomeService } from '../../../services/home.service';
//import { UserService } from '../../../services/user.service';
import { SellerPostService } from '../../../services/seller-post.service';
import { MatDialog } from '@angular/material/dialog';
import { SellerFormComponent } from '../../seller/form/form.component';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: false
})
export class FooterComponent implements OnInit {
	
	sellerPostList: Array<any> = [];
	buyerPostList: Array<any> = [];
	extraseller:any = [];
	data:any = [];
	dir:any = []; 
	links:any = [];

	form:FormGroup;
	constructor(
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
	) { }

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			email : ['', [Validators.required, Validators.email]],
		});
		this.links = this.getLinks()
	}

	getLinks():any{
		return this.userService.getLinks()
			.reduce((obj, item) => Object.assign(obj, { [item.name]: item.link }), {});
	}

	onSubmit():void{
		if (this.form.valid) {
			this.userService.newsletter(this.form.value).subscribe(response => {
				if(response.success){
					this.userService.sucessMessage(response.message) 
					this.form.reset();
					// console.log(response.data)
				}else{
					this.userService.errorMessage(response.message)
				}
			})
		}else {
			this.formValidationService.validateAllFormFields(this.form);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	openModal(){
		this.dialog.open(SellerFormComponent,{
			data:{
				modal : true
			}
		})
	}
}
