<div class="details content-details manue_cont_app_pro_size flex-grow-1">
    <h5 class="manue_cont_app_pro_size_title"><img src="assets/img/product_size.png" class="img-fluid" alt=""> <span>Deal of Products</span> </h5>
    <!-- <h2>Deal Of Product</h2> -->
    <div style="overflow-x:auto;"class="manue_cont_app_pro_size_sub-ban border-bottom border-secondary-subtle" >
        <!-- <h4>Category</h4> -->
        <!-- <div class="manue_cont_app_pro_size_sub_text">
            <p class="tiles_product_size_ts">{{c.category.name}}</p>
        </div> -->
        <div class="" *ngFor="let c of categories">
            <div class="manue_cont_app_pro_size_sub_text ml-2" *ngFor="let sc of c.subcategories">
                <p class="tiles_product_size_ts">{{sc.product}}</p>
            </div>
        </div>
    </div>
    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban mt-2">
        <!-- <h4>Sub Category</h4> -->
        <div class="manue_cont_app_pro_size_sub-dec">
            <h4>Product Description</h4>
            <p class="manue_cont_app_pro_size_sub-decp">{{description}}</p>
        </div>
    </div>
</div>