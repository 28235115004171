import { Component, OnInit } from '@angular/core';
import { InformativePagesService } from 'src/app/services/informative-pages.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-specification',
    templateUrl: './specification.component.html',
    styleUrls: ['./specification.component.css', '../../../assets/css/ckeditor.css'],
    standalone: false
})
export class SpecificationComponent implements OnInit {
  data : any = '';

  constructor(

    private pageService : InformativePagesService,
    private userServices : UserService
  ){}

  ngOnInit(): void {
    this.pageService.specification().subscribe(res => {
      if(res.success){
        this.data = res.data
      }else{
        this.userServices.errorMessage(res.message);

      }
    })

  }

}
