import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { BuyerPostService } from '../../../services/buyer-post.service';
import { UserService } from '../../../services/user.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-buyer-post-detail',
    templateUrl: './buyer-post-detail.component.html',
    styleUrls: ['./buyer-post-detail.component.css'],
    standalone: false
})
export class BuyerPostDetailComponent implements OnInit {
	id:number = 0;
	data:any = [];
	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		autoplay: true,
		autoplayTimeout: 2000,
		dots: false,
		navSpeed: 700,
		nav: true,
		navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 2
			},
			740: {
				items: 3
			},
			940: {
				items: 4
			}
		}
	}
	constructor(
		private route: ActivatedRoute,
		
		private router : Router,
		private buyerPostService : BuyerPostService,
		private userService : UserService,
		private analyticsService: AnalyticsService,
	) { }

	ngOnInit(): void {
		console.log("Parama");
		this.route.queryParams.subscribe(params => {
			console.log(params);
			if(params.id) {
	      		history.state.id  = params.id;
	      	}
	      }
	    );
	    console.log(history.state);

		if(history.state.id){
			this.id = history.state.id;
			this.buyerPostService.getPostDetails({id: this.id}).subscribe(response => {
				if(response.success){
					this.data = response.data;
				}else{
					this.userService.errorMessage(response.message);
				}
			});
		}else{
			this.router.navigate(['/buyer-posts']);
		}
	}
	analytics(id, type):void{
		this.analyticsService.saveCount({id: id, type : type}).subscribe();
	}
}
