import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { refrenceFind } from 'src/app/services/refrence-find.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-find-reference',
    templateUrl: './find-reference.component.html',
    styleUrls: ['./find-reference.component.css'],
    standalone: false
})
export class FindReferenceComponent implements OnInit {

  refrence: Array<any> = [];
  title = "intlInputNew"
  mobile:FormControl = new FormControl()
  constructor(
    private RefrenceFind : refrenceFind,
		private userService : UserService,
  ) { }

  inboxCount:number = 0;
	notificationCount:number = 0;
	chatCount:number = 0;


  ngOnInit(): void {}

  onSubmit():void{

    const params = new HttpParams({'fromObject':{
      'mobile' :  String(this.mobile.value).replace(/[\s]/g, '')
    }})

    this.RefrenceFind.getList(params).subscribe(res => {
			if(res.success){
				// console.log(res.data)
        this.userService.sucessMessage(res.message)
        this.refrence = res.data
        console.log(res.data)
			}else{
				this.userService.errorMessage(res.message);
			}
		});
  }
}
