import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { APIResponse } from "../Modal/apiresponse";
import { apiConstants } from "../constants/api-constants";

@Injectable({
  providedIn: "root",
})
export class ExhibitionService {
  constructor(private http: HttpClient) {}

  get_banners(data = {}): Observable<APIResponse> {
    return this.http
      .get(apiConstants.exhibition.list, { params: data })
      .pipe(catchError(this.handleError));
  }
  get_company_exhibition(data = {}): Observable<APIResponse> {
    return this.http
      .get(apiConstants.exhibition.company_exhibiton, { params: data })
      .pipe(catchError(this.handleError));
  }
  get_virtual_exhibition(data = {}): Observable<APIResponse> {
    return this.http
      .get(apiConstants.exhibition.virtual_exhibiton, { params: data })
      .pipe(catchError(this.handleError));
  }
  click_banner(form: any): Observable<APIResponse> {
    return this.http
      .post(apiConstants.exhibition.banner_click, form,{
        'headers':{
          'X-Skip-Interceptor' : 'true',
          'X-Skip-Response' : 'true',
        } 
      })
      .pipe(catchError(this.handleError));
  }
  click_virtual_exhibition(form: any): Observable<APIResponse> {
    return this.http
      .post(apiConstants.exhibition.virtual_exhibiton_click, form,{
        'headers':{
          'X-Skip-Interceptor' : 'true',
          'X-Skip-Response' : 'true',
        } 
      })
      .pipe(catchError(this.handleError));
  }
  click_company_exhibition(form: any): Observable<APIResponse> {
    return this.http
      .post(apiConstants.exhibition.company_exhibiton_click, form,{
        'headers':{
          'X-Skip-Interceptor' : 'true',
          'X-Skip-Response' : 'true',
        } 
      })
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
