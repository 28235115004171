import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { InquiryService } from '../../../services/inquiry.service';
import { FormValidatorServiceService } from '../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-buyer-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    standalone: false
})
export class BuyerFormComponent implements OnInit {
	inquiryForm:FormGroup;
	@Input('tiles') tiles = [];
	@Input('sanitary') sanitary = [];
	@Input('various') various = [];
	sizes:any = []; 
	constructor(
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder,
		private router : Router,
		private inquiryService : InquiryService,
	) { }

	ngOnInit(): void {
		let user = this.userService.getUser();
		this.inquiryForm = this.formBuilder.group({
			product : ['', [Validators.required]],
			size : ['', [Validators.required]],
			comapny_id : [0, [Validators.required]],
			type : ['1', [Validators.required]],
			requirement : ['1', [Validators.required]],
			firm_name : ['', [Validators.required]],
			city : ['', [Validators.required]],
			cell : ['', [Validators.required]],
			platform: ['web'],
		});
		if(user.id){
			this.inquiryForm.patchValue({
				'comapny_id' : user.company_id,
				'firm_name' : user.company_name,
				'city' : user.company_city,
				'cell' : user.company_contact,
			});
		} 
	}
	onChangeProduct(event):void{
		this.sizes = [];
		if(event.target.value){
			const category = this.tiles.find((c) => { return c.id == event.target.value; });
			this.inquiryForm.patchValue({'size':''});
			this.sizes = category.sizes;
		}
	}
	onRequirementChange(event):void{
		if(event.target.value == 1){
			this.inquiryForm.get('size').setValidators([Validators.required]);
			this.inquiryForm.get('size').updateValueAndValidity();
		}else{
			this.inquiryForm.get('size').setValidators(null);
			this.inquiryForm.get('size').updateValueAndValidity();
		}
	}
	onSubmit():void{
		if (this.inquiryForm.valid) {
			this.inquiryService.general(this.inquiryForm.value).subscribe(response =>{
				if(response.success){
					this.ngOnInit();
					this.userService.sucessMessage(response.message);
				}else{
					this.userService.errorMessage(response.message);
				}
			})
		}else {
			alert("Please fill up all the required fields");
			this.formValidationService.validateAllFormFields(this.inquiryForm);
			const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

}
