import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DefaulterService } from '../../services/defaulter.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-defaulter-details',
    templateUrl: './defaulter-details.component.html',
    styleUrls: ['./defaulter-details.component.css'],
    standalone: false
})
export class DefaulterDetailsComponent implements OnInit {
	data:any = {};
	constructor(
		private defaulterService : DefaulterService,
		private userService : UserService,
		private router: Router
	) { }

	ngOnInit(): void {
		if(history.state.deft){
			this.data = history.state.deft;
		}else{
			this.router.navigate(['defaulter'])
		}
	}

}
