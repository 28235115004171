import { HomeService } from './../../../services/home.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Input, inject, ChangeDetectionStrategy, Inject, Optional } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { InquiryService } from '../../../services/inquiry.service';
import { FormValidatorServiceService } from '../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-seller-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    standalone: false
})
export class SellerFormComponent implements OnInit {
	

	readonly dialogRef = inject(MatDialogRef<SellerFormComponent>);
	readonly data = inject<any>(MAT_DIALOG_DATA);

	inquiryForm: FormGroup;
	products = []
	_products = []
	sizes = [];
	isModal:boolean = false;

	get user () : any {
		return this.userService.getUser();
	}
	constructor(
		private userService: UserService,
		private homeService: HomeService,
		private formValidationService: FormValidatorServiceService,
		private formBuilder: FormBuilder,
		private router: Router,
		private inquiryService: InquiryService,
	) { 
		this.isModal = Boolean(this.data?.modal)
	}

	getProducts():void{
		this.homeService.getProducts()
			.subscribe(res=>{
				this._products = res.data
				this.getRequiredProducts(1)
			})
	}

	closeModal(){
		if(Object.keys(this.dialogRef).length){
			this.dialogRef.close()
			this.initForm()
		}
		
	}

	ngOnInit(): void {
		
		this.initForm()
		this.getProducts()
		this.router.events.subscribe(()=>{
			this.closeModal()
		})
		
	}

	initForm():void{
		this.inquiryForm = this.formBuilder.group({
			product: ['', [Validators.required]],
			size: ['', [Validators.required]],
			qty: ['', [Validators.required]],
			comapny_id: [0, [Validators.required]],
			type: ['1', [Validators.required]],
			requirement: ['1', [Validators.required]],
			firm_name: ['', [Validators.required]],
			city: ['', [Validators.required]],
			cell: ['', [Validators.required]],
			platform: ['web'],
		});
		if (this.user.id) {
			this.inquiryForm.patchValue({
				'comapny_id': this.user.company_id,
				'firm_name': this.user.company_name,
				'city': this.user.company_city,
				'cell': this.user.company_contact,
			});
		}
	}

	getRequiredProducts(requirement){
		
		if (!requirement) {
			this.products = []
			return 
		}
		if (requirement==3) {
			this.products = this._products.filter(p=>[-2].includes(p.category_id))
			return
		}

		if (requirement==2) {
			this.products = this._products.filter(p=>[-1].includes(p.category_id))
			return
		}

		this.products = this._products.filter(p=>![-1,-2].includes(p.category_id))
		return
	}

	onChangeProduct(event): void {
		if (event.target.value&&this.inquiryForm.value.requirement == 1) {
			this.sizes = this.products.find(p=>p.id==event.target.value)?.tile_sizes ?? []
			this.inquiryForm.patchValue({ 'size': '' });
		}
	}
	onRequirementChange(event): void {
		if (event.target.value == 1) {
			this.inquiryForm.get('size').setValidators([Validators.required]);
			this.inquiryForm.get('size').updateValueAndValidity();
		} else {
			this.inquiryForm.get('size').setValidators(null);
			this.inquiryForm.get('size').updateValueAndValidity();
		}
		this.getRequiredProducts(Number(event.target.value))
	}
	onSubmit(): void {
		if (this.inquiryForm.valid) {
			this.inquiryService.general(this.inquiryForm.value).subscribe(response => {
				if (response.success) {
					this.ngOnInit();
					this.userService.sucessMessage(response.message);
				} else {
					this.userService.errorMessage(response.message);
				}
			})
			
		} else {
			this.formValidationService.validateAllFormFields(this.inquiryForm);
			const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
}
