<main>
	<!-- <app-breadcrumb [pageName]="'Notifications'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12  text-right noti_main_add_ban">
					<a href="javascript:void(0)" class="view-btn view-new" (click)="markAllAsRead()">Mark All Read</a>
				</div>
				<div class="col-xl-6 notifi-data">
					<ul class="single-notification noti_main_ban" *ngFor="let notification of notifications">
						<ng-container *ngIf="notification.c_id != 0 && (notification.notification_type == 'buyer_post' || notification.notification_type == 'seller_post')" >
							<li routerLink="/buyer-posts-detail" [state]="{id: notification.c_id}" *ngIf="notification.notification_type == 'buyer_post'">
								<div class="not-det">
									<p class="noti_main_ban_tit">{{notification.title}}</p>
									<p class="noti_main_ban_text">{{notification.text}}</p>
									<p class="noti_main_ban_date">{{notification.date}}</p>
								</div>
								<div class="noti_main__delete_ban"><button type="button" value="" name=""><img src="assets/img/delete_button_noti.png" class="img-fluid" alt=""> </button></div>
							</li>
							<li routerLink="/seller-posts-detail" [state]="{id: notification.c_id}" *ngIf="notification.notification_type == 'seller_post'">
								<div class="not-det">
									<p class="noti_main_ban_tit">{{notification.title}}</p>
									<p class="noti_main_ban_text">{{notification.text}}</p>
									<p class="noti_main_ban_date">{{notification.date}}</p>
								</div>
								<div class="noti_main__delete_ban"><button type="button" value="" name=""><img src="assets/img/delete_button_noti.png" class="img-fluid" alt=""> </button></div>
							</li>
						</ng-container>
						<ng-container *ngIf="notification.c_id != 0 && notification.notification_type != 'buyer_post' && notification.notification_type != 'seller_post'" >
							<a  routerLink="/company/details" [state]="{id: notification.c_id, type:notification.c_type}" class="noti-wraper d-block">
								<li *ngIf="notification.image != ''">
									<img [src]="notification.image">
								</li>
								<li class="d-flex">
									<div class="not-det">
										<p class="noti_main_ban_tit">{{notification.title}}</p>
										<p class="noti_main_ban_text">{{notification.text}}</p>
										<p class="noti_main_ban_tit" *ngIf="notification.notification_type  == 'single_company'">{{notification.c_name}}</p>
										<p *ngIf="notification.link != ''"><a href="{{notification.link}}" target="_blank" class="noti_main_ban_tit">{{notification.link}}</a></p>
										<!-- <p *ngIf="notification.link != ''"><a href="{{notification.link}}" target="_blank" class="noti_main_ban_tit">{{notification.link}}</a></p> -->
										<p class="noti_main_ban_date">{{notification.date}}</p>
									</div>
									<div class="noti_main__delete_ban">
										<button type="button" value="" name=""><img src="assets/img/delete_button_noti.png" class="img-fluid" alt=""> </button>
										<!-- <a routerLink="/company/details" [state]="{id: notification.c_id, type:notification.c_type}" class="view-btn view-new">View profile</a> -->
									</div>
									<!-- <div class="noti_main__more_ban"><a routerLink="/company/details" [state]="{id: notification.c_id, type:notification.c_type}" class="view-btn view-new">View profile</a></div> -->
								</li>
							</a>
						</ng-container>
						<ng-container *ngIf="notification.c_id == 0  && notification.notification_type != 'buyer_post' && notification.notification_type != 'seller_post'">
							<li *ngIf="notification.image != ''">
								<img [src]="notification.image">
							</li>
							<li>
								<div class="not-det">
									<p class="noti_main_ban_tit">{{notification.title}}</p>
									<p class="noti_main_ban_text">{{notification.text}}</p>
									<p *ngIf="notification.link != ''"><a href="{{notification.link}}" target="_blank" class="noti_main_ban_tit">{{notification.link}}</a></p>
									<p class="noti_main_ban_date">{{notification.date}}</p>
								</div>
								<div class="noti_main__delete_ban"><button type="button" value="" name=""><img src="assets/img/delete_button_noti.png" class="img-fluid" alt=""> </button></div>
							</li>
						</ng-container>
					</ul>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new comp-info-more-btn" style="cursor: pointer;" (click)="more()"> View More</a>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>