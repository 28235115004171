<div [id]="id" class="carousel slide" data-bs-ride="carousel">
    <ul class="carousel-indicators">
      <li [attr.data-bs-target]="'#'+id" [attr.data-bs-slide-to]="i" [ngClass]="{'active':i==0}" *ngFor="let banner of banners;let i = index"></li>
    </ul>
    <div class="carousel-inner">
      <div class="carousel-item" (click)="onClick(banner)" [ngClass]="{'active':imgi1==0}" *ngFor="let banner of banners;let imgi1 = index">
        <img [src]="banner.image" alt="Exhibition Image">
      </div>
    </div>
    <a class="carousel-control-prev" [href]="'#'+id" data-bs-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next" [href]="'#'+id" data-bs-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
  
  
  