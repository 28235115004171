import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

import { HomeService } from "../../../services/home.service";
import { UserService } from "../../../services/user.service";
import { SellerPostService } from "../../../services/seller-post.service";
import { ExhibitionService } from "src/app/services/exhibition.service";

declare let $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  standalone: false,
})
export class HomeComponent implements OnInit {
  extraseller: any = [];
  data: any = [];
  royalBanners: any = [];
  exclusiveBanners: any = [];
  classicBanners: any = [];
  trandingBrands: any = [];
  dir: any = [];
  dirmore: any = [];
  sellerPostList: Array<any> = [];
  buyerPostList: Array<any> = [];
  category: any = "";
  search: any = "";
  keyword: string = "name";
  companyList: Array<any> = [];

  dataList: any = [];

  customOptionsMessage: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ["&#8249", "&#8250;"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    nav: true,
  };

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayTimeout: 2000,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };

  constructor(
    private homeService: HomeService,
    private userService: UserService,
    private router: Router,
    private exhibitionService: ExhibitionService
  ) {}

  ngOnInit(): void {
    this.homeService.getHome().subscribe((response) => {
      if (response.success) {
        this.data = response.data;
        this.royalBanners = response.data.banners.royal;
        this.exclusiveBanners = response.data.banners.exclusive;
        this.classicBanners = response.data.banners.classic;
        this.trandingBrands = response.data.banners.tranding_brand;
        if (response.data.links?.length) {
          this.userService.setLinks(response.data.links);
        }
      } else {
        this.userService.errorMessage(response.message);
      }
    });

    this.getStatusList();
  }
  clickCompany({ banner, analytic_type }) {
    const { id, name, type } = banner.company;
    this.exhibitionService
      .click_banner({ id: banner.id, type: analytic_type })
      .subscribe();
    this.router.navigate([`/company/${this.slug(name)}/${id}`], {
      state: { id: id, type: type },
    });
  }

  slug(value) {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^A-Z0-9]/gi, "-");
  }

  onClickStatusMessage(id, type, name): void {
    this.router.navigate([
      "/company/" +
        id +
        "/" +
        type +
        "/" +
        name.replace(/\s+/g, "-").replace("&", "-").toLowerCase(),
    ]);
  }

  searchCompany(): void {
    // console.log("Search",this.search);
    if (this.search.name.trim()) {
      this.router.navigate(["/search"], {
        state: { search: this.search.name },
      });
    }
  }

  selectEvent(item) {
    // do something with selected item
  }

  onChange(val) {
    let subid = -2;
    //-2 mean blank
    if (this.category == "") {
      subid - 2;
    } else {
      subid = this.category;
    }
    this.homeService.getCompanyListAll(subid).subscribe((response) => {
      if (response.success) {
        this.companyList = response.data;
      } else {
        this.userService.errorMessage(response.message);
      }
    });
  }

  getStatusList() {
    this.homeService.statusList(10).subscribe((res) => {
      if (res.success) {
        this.dataList = res;
      } else {
      }
    });
  }

  public handleMissingImage(event: Event) {
    //(event.target as HTMLImageElement).style.display = 'none';
    (event.target as HTMLImageElement).src = "assets/img/placeholdermsg.png";
  }
}
