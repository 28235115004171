import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BuyerPostService } from '../../../services/buyer-post.service';
import { UserService } from '../../../services/user.service';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
    selector: 'app-buyer-posts-list',
    templateUrl: './buyer-posts-list.component.html',
    styleUrls: ['./buyer-posts-list.component.css'],
    standalone: false
})
export class BuyerPostsListComponent implements OnInit {
	list:any = [];
	nextPage:number = 0;
	totalPages:number = 0;

	products:any = [];
	sizes:any = [];

	selectedProduct:any = '';
	selectedProductType:any = '';
	selectedSize:any = '';

	extra:any = [];

	constructor(
		private buyerPostService: BuyerPostService,
		private userService: UserService,
		private router: Router,
		private analyticsService: AnalyticsService,
	) { }
	onChangeProduct(val):void{
		if(val != ''){
			val = val.split("-")
			this.selectedProduct = val[0];
			this.selectedProductType = val[1];
		}else{
			this.selectedProduct = ''
			this.selectedProductType = '';
		}
	}
	onChangeSize(val):void{
		this.selectedSize = val;
	}
	ngOnInit(): void {
		let params = {};
		if(history.state.filter && history.state.product_type){
			params = { ...params, ...{filter : history.state.filter}};
			params = { ...params, ...{product_type : history.state.product_type}};
		}
		this.buyerPostService.getPostList(params).subscribe(resposnse => {
			if(resposnse.success){
				this.extra = resposnse.extra;
				this.products = resposnse.extra.product;
				this.sizes = resposnse.extra.size;
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				this.list = resposnse.data;
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		});
	}
	filterOption(): void {
		let param = {};
		if (this.selectedProduct != '') {
			param = { ...param, ...{ product_id: this.selectedProduct } }
			param = { ...param, ...{ product_type: this.selectedProductType } }
		}
		if (this.selectedSize != '') {
			param = { ...param, ...{ size_id: this.selectedSize } }
		}
		if(history.state.filter && history.state.product_type){
			param = { ...param, ...{filter : history.state.filter}};
			param = { ...param, ...{product_type : history.state.product_type}};
		}
		this.buyerPostService.getPostList(param).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				this.list = resposnse.data
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		})
	}
	morePosts():void{
		let param = {next_page: this.nextPage};
		if (this.selectedProduct != '') {
			param = { ...param, ...{ product_id: this.selectedProduct } }
			param = { ...param, ...{ product_type: this.selectedProductType } }
		}
		if (this.selectedSize != '') {
			param = { ...param, ...{ size_id: this.selectedSize } }
		}
		if(history.state.filter && history.state.product_type){
			param = { ...param, ...{filter : history.state.filter}};
			param = { ...param, ...{product_type : history.state.product_type}};
		}
		this.buyerPostService.getPostList(param).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				resposnse.data.forEach(element => {
					this.list.push(element)
				});;
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		})
	}
	analytics(id, type):void{
		this.analyticsService.saveCount({id: id, type : type}).subscribe();
	}
}
