<div class="details content-details manue_cont_app_pro_size flex-grow-1">
    <h5 class="manue_cont_app_pro_size_title"><img src="assets/img/product_size.png" class="img-fluid" alt=""> <span>Product and Size</span> </h5>
    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">
        <h4>Service</h4>
            <div class="manue_cont_app_pro_size_sub-text" *ngFor="let s of services">
                <p class="tiles-product-size">{{s}}</p>
            </div>
    </div>
    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">
        <h4>Available Cars</h4>
        <div class="manue_cont_app_pro_size_sub-text" *ngFor="let c of cars">
            <p class="tiles-product-size">{{c}}</p>
        </div>
    </div>
</div>