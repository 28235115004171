<div class="form-box login_main_form_ban">
  <h2>Login</h2>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <div class="login_main_form_div">
        <input
          type="text"
          name=""
          formControlName="email"
          placeholder="Mobile"
        />
        <label
          [ngClass]="{
            'is-invalid':
              loginForm.get('email').touched && loginForm.get('email').invalid
          }"
          class="invalid-feedback font-medium"
          >Please enter email</label
        >
      </div>
      <div class="login_main_form_div">
        <input
          type="password"
          name=""
          formControlName="password"
          placeholder="Password"
        />
        <label
          [ngClass]="{
            'is-invalid':
              loginForm.get('password').touched &&
              loginForm.get('password').invalid
          }"
          class="invalid-feedback font-medium"
          >Please enter password</label
        >
      </div>
      <div class="text-left login_main_form_div">
        <a type="button" routerLink="/forgot-password" title="Forgot Password"
          >Forgot password</a
        >
      </div>
      <!-- <div class="text-center login_main_form_button">
        <input type="submit" name="" value="Login">
      </div> -->

      <div class="text-left login_main_form_reg">
        <p class="">
          Not a member? <a type="button" routerLink="/register" title="">Register Now</a>
        </p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-block">
      <div class="text-center login_main_form_button mt-0">
        <input type="submit" value="Login" cdkFocusInitial />
      </div>
    </mat-dialog-actions>
  </form>
</div>
