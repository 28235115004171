import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BuyerPostService } from '../../services/buyer-post.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-my-buy-post',
    templateUrl: './my-buy-post.component.html',
    styleUrls: ['./my-buy-post.component.css'],
    standalone: false
})
export class MyBuyPostComponent implements OnInit {
	list:any = [];
	nextPage:number = 0;
	totalPages:number = 0;

	products:any = [];
	sizes:any = [];

	selectedProduct = [];
	selectedSize = [];


	constructor(
		private buyerPostService: BuyerPostService,
		private userService: UserService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.buyerPostService.getMyPostList({}).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				this.list = resposnse.data;
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		});
	}
	morePosts():void{
		let param = {next_page: this.nextPage};
		this.buyerPostService.getMyPostList(param).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				resposnse.data.forEach(element => {
					this.list.push(element)
				});;
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		})
	}
}
