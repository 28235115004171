import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-sanitary-product',
    templateUrl: './sanitary-product.component.html',
    styleUrls: ['./sanitary-product.component.css'],
    standalone: false
})
export class SanitaryProductComponent implements OnInit {
	@Input('mainProducts') mainProducts = [];
	@Input('subProducts') subProducts = [];
	@Input('description') description = '';
	constructor() { }

	ngOnInit(): void {
	}

}
