<main>
	<!-- <app-breadcrumb [pageName]="'OTP Verification'"></app-breadcrumb>	 -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>{{'OTP Verification'}}</h1>
						<img src="assets/img/line.svg" width="120" />
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-xl-6 col-md-6 col-sm-12 col-xs-12">
					<div class="form-box">
						<div class="passcode-wrapper mt-4">
							<input id="codeBox1" [(ngModel)]="otpIndex1" type="number" maxlength="1" onkeyup="onKeyUpEvent(1, event)" onfocus="onFocusEvent(1)">
							<input id="codeBox2" [(ngModel)]="otpIndex2" type="number" maxlength="1" onkeyup="onKeyUpEvent(2, event)" onfocus="onFocusEvent(2)">
							<input id="codeBox3" [(ngModel)]="otpIndex3" type="number" maxlength="1" onkeyup="onKeyUpEvent(3, event)" onfocus="onFocusEvent(3)">
							<input id="codeBox4" [(ngModel)]="otpIndex4" type="number" maxlength="1" onkeyup="onKeyUpEvent(4, event)" onfocus="onFocusEvent(4)">
						</div>
						<div class="text-center mt-4">
							<input type="submit" (click)="onClickverifyOtp()" class="mt-5" name="" value="Verify" title="Verify" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</main> 