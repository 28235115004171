import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-company-about',
    templateUrl: './company-about.component.html',
    styleUrls: ['./company-about.component.css'],
    standalone: false
})
export class CompanyAboutComponent implements OnInit {
	about: any = '';
	constructor(private router: Router) { }

	ngOnInit(): void {
		if (history.state.about) {
			this.about = history.state.about;
		} else {
			this.router.navigate(['/'])
		}
	}
}
