import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
    standalone: false
})
export class BreadcrumbComponent implements OnInit {
  @Input() pageName = '';

  constructor() { }

  ngOnInit(): void {
  }

}
