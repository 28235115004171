<main>
	<section class="design-area home_main_blog_ban">
		<div class="container">
			<div class="row mb-3 box_bb">
				<div class="col-sm-4 " *ngFor="let blog of blogList">
					<div class="box_bb blog_news_ban mt-5">
						<a [routerLink]="['/blogs', blog.id,blog.slug]" [state]="{id: blog.id}">
							<div class="img_bb">
								<img [src]="blog.image" class="blog-image" />
							</div>
							<div class="other_in_b">
								<div class="meta_in">
									<p class="by-author mt-2">{{blog.published_dt | date}}</p>
								</div>
								<h4>
									<a [routerLink]="['/blogs', blog.id,blog.slug]" [state]="{id: blog.id}">{{blog.title}}</a>
								</h4>
								<div class="excepp">
									<p [innerHTML]="blog.summary" class="mb-0"></p>
								</div>
								<div class="read_mmm_b mb-2 mt-3">
								<!--	<a [routerLink]="['/blog-detail']" [state]="{id: blog.id}" class="sel-btn">Read More</a> -->
									
									<a [routerLink]="['/blogs', blog.id,blog.slug]" [state]="{id: blog.id}" class="sel-btn">Read More</a>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="show_page">
				<div class="col-sm-12">
		
					<pagination class="mt-4 mb-4 text-center justify-content-center pagi_desi_ban" [itemsPerPage]="itemPerPage" pageBtnClass="btn" [totalItems]="totalPage"  
					[(ngModel)]="currentPage" (pageChanged)="pageChanged($event)">
				</pagination>
				<!--
					<pagination class="mt-4" pageBtnClass="btn" [totalItems]="totalPage"   *ngIf="totalPage"
						[(ngModel)]="currentPage" (pageChanged)="pageChanged($event)">
					</pagination>
				-->
				</div>
			</div>
		</div>
	</section>
</main>