import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { FormValidatorServiceService } from '../../services/form-validator-service.service';
import { UserService } from '../../services/user.service'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.css'],
    standalone: false
})
export class ProfileEditComponent implements OnInit {
	companyForm: FormGroup;
	public Editor = ClassicEditor;
	countries: any = [];
	states: any = [];
	districts: any = [];
	zones: any = [];
	comapnies: any = [];
	extra:any = [];
	data;
	years:any = [];

	fileTypes: Array<string> = ['jpg', 'jpeg', 'png'];

	dropdownSettings: IDropdownSettings = {
		singleSelection: false,
		defaultOpen: false,
		idField: 'id',
		textField: 'name',
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		enableCheckAll:false,
		itemsShowLimit: 5,
		allowSearchFilter: true,
		limitSelection:-1,
	};

	@ViewChild('companyLogo') companyLogo: HTMLElement;
	@ViewChild('companyBanner') companyBanner: HTMLElement;
	@ViewChild('personalProfile') personalProfile: HTMLElement;
	@ViewChild('companyImage') companyImage: HTMLElement;
	@ViewChild('galleryImage') galleryImage: HTMLElement;
	@ViewChild('businessCard') businessCard: HTMLElement;

	constructor(
		private formBuilder: FormBuilder,
		private formValidationService: FormValidatorServiceService,
		private userService: UserService,
		private router : Router
	) { }

	ngOnInit(): void {
		for (let i = 1950; i <= 2050; i++) {
			this.years.push(i);
		}
		this.companyForm = this.formBuilder.group({
			companyName: ['', [Validators.required]],
			companyLogo: [''],
			companyLogoImage: [''],
			companyBanner: [''],
			companyBannerImage: [''],
			country: ['', [Validators.required]],
			state: ['', [Validators.required]],
			district: ['', [Validators.required]],
			zone: ['', [Validators.required]],
			city: ['', [Validators.required]],
			address: ['', [Validators.required]],
			c_contact_no: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
			c_wp_no: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
			c_email: ['', [Validators.required, Validators.email]],
			c_website: ['', [Validators.required, Validators.pattern('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')]],
			c_gstin: ['', [Validators.required]],
			c_establised_year: ['', [Validators.required]],
			c_facebook: ['', [Validators.required, Validators.pattern('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')]],
			c_instagram: ['', [Validators.required, Validators.pattern('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')]],
			c_twitter: ['', [Validators.required, Validators.pattern('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')]],
			c_youtube: ['', [Validators.required, Validators.pattern('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')]],
			c_longitude: ['', [Validators.required]],
			c_latitude: ['', [Validators.required]],
			c_brand: ['', [Validators.required]],
			c_role: [''],
			c_groupofcompany: [[]],
			c_about: ['', [Validators.required]],
			c_catelog_pdf: ['', [Validators.required, Validators.pattern('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')]],
			c_business_card: [''],
			c_business_cardImage: [''],
			c_product_description: ['', [Validators.required]],
			c_photos: this.formBuilder.array([]),
			productGallary: this.formBuilder.array([]),
			tiles_categories:this.formBuilder.array([]),
			sanitary_main_categories:this.formBuilder.array([]),
			sanitary_sub_categories:this.formBuilder.array([]),
			various_categories:[[]],
			trader_dealer_categories:this.formBuilder.array([]),
			transporter_service: [''],
			transporter_area: [[]],
			supplier_categories:this.formBuilder.array([]),
			hotel_amenities:[[]],
			hotel_rooms:this.formBuilder.array([]),
			serviceprovider_service:[[]],
			cab_service:[[]],
			cab_cars:[[]],
		});
		this.productGallaryList(this.productGallary);
		this.companyPhotos(this.c_photos)

		if(!history.state.editCid){
			this.router.navigate(['/profile']);
			return;
		}
		this.userService.getProfile({id:history.state.editCid}).subscribe(response => {
		// this.userService.getProfile({id:71}).subscribe(response => {
			if(response.success){
				this.data = response.data; 
				this.countries = response.extra.country;
				this.comapnies = response.extra.comapnies;
				this.extra = response.extra;

				let record:any = response.data;

				const country = this.countries.find((c) => {
					return c.id == record.country_id;
				});
				this.states = country.state;
		
				const state = this.states.find((c) => {
					return c.id == record.state_id;
				});
				this.districts = state.districts;
				
				const district = this.districts.find((c) => {
					return c.id == record.district_id;
				});
				this.zones = district.zones;	

				this.c_photos.clear();
				record.company_photos.forEach(element => {
					this.c_photos.push(this.formBuilder.group({
						id	: [element.id],
						title: [element.title, [Validators.required]],
						image: [''],
					}));
				});

				this.productGallary.clear();
				record.product_photos.forEach(element => {
					this.productGallary.push(this.formBuilder.group({
						id	: [element.id],
						title: [element.title, [Validators.required]],
						image: [''],
					}));
				});
				this.companyForm.patchValue({
					'companyName' 			: record.name,
					'companyLogoImage' 		: record.logo,
					'companyBannerImage' 	: record.banner,
					'country' 				: record.country_id,
					'state' 				: record.state_id,
					'district' 				: record.district_id,
					'zone' 					: record.zone_id,
					'city' 					: record.city,
					'address' 				: record.address,
					'c_contact_no' 			: record.contact,
					'c_wp_no' 				: record.whatsapp,
					'c_email' 				: record.email,
					'c_website' 			: record.website,
					'c_gstin' 				: record.gstin,
					'c_establised_year' 	: record.year,
					'c_facebook' 			: record.facebook,
					'c_instagram' 			: record.instagram,
					'c_twitter' 			: record.twitter,
					'c_youtube' 			: record.youtube,
					'c_longitude' 			: record.long,
					'c_latitude' 			: record.lat,
					'c_brand' 				: record.brand,
					'c_role' 				: record.role,
					'c_about' 				: record.about,
					'c_catelog_pdf' 		: record.catalog_url,
					'c_business_cardImage' 	: record.bussiness_card,
					'c_product_description' : record.product_description,
				})
				record.goc = record.goc.map(e => Number(e));
				this.companyForm.patchValue({'c_groupofcompany': this.comapnies.filter(e => record.goc.includes(e.id))});
				this.setTypeWiseData();
			}else{
				this.userService.errorMessage(response.message);
			}
		});
	}
	//Dynamic product gallery Start
	get productGallary(): FormArray {
		return this.companyForm.controls.productGallary as FormArray;
	}
	productGallaryList(list: any): void {
		list.push(this.formBuilder.group({
			title: ['', [Validators.required]],
			image: ['', [Validators.required]],
		}));
	}
	onSelectGallaryImage(file, i) {
		this.productGallary.controls[i].patchValue({ 'image': file[0] })
	}
	addProductGallary() {
		this.productGallary.push(this.formBuilder.group({
			title: ['', [Validators.required]],
			image: ['', [Validators.required]],
		}));
	}
	removeProductGallary(index) {
		this.productGallary.removeAt(index);
	}
	//Dynamic product gallery end
	//Dynamic Company Photots Start
	get c_photos(): FormArray {
		return this.companyForm.controls.c_photos as FormArray;
	}
	companyPhotos(list: any): void {
		list.push(this.formBuilder.group({
			title: ['', [Validators.required]],
			image: ['', [Validators.required]],
		}));
	}
	onSelectCompanyImage(file, i) {
		this.c_photos.controls[i].patchValue({ 'image': file[0] })
	}
	addCompanyPhotos() {
		this.c_photos.push(this.formBuilder.group({
			title: ['', [Validators.required]],
			image: ['', [Validators.required]],
		}));
	}
	removeCompanyPhotos(index) {
		this.c_photos.removeAt(index);
	}
	//Dynamic Company Photots End
	onSelectImage(files, elementId) {
		if (files.length === 0) {
			return;
		}
		const extension = files[0].name.split('.').pop().toLowerCase();
		const isSuccess = this.fileTypes.indexOf(extension) > -1;
		if (isSuccess) {
			this.companyForm.patchValue({ [elementId]: files[0] });
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.companyForm.patchValue({ [elementId + 'Image']: event.target.result })
			}
			reader.readAsDataURL(files[0]);
		}
	}
	onChangeCountry(event): void {
		const category = this.countries.find((c) => {
			return c.id == event.target.value;
		});
		this.states = category.state;
		this.districts = [];
		this.zones = [];
		this.companyForm.patchValue({ 'state': '' });
		this.companyForm.patchValue({ 'district': '' });
		this.companyForm.patchValue({ 'zone': '' });
	}
	onChangeState(event): void {
		const category = this.states.find((c) => {
			return c.id == event.target.value;
		});

		this.districts = category.districts;
		this.zones = [];
		this.companyForm.patchValue({ 'district': '' });
		this.companyForm.patchValue({ 'zone': '' });
	}
	onChangeDistrict(event): void {
		const category = this.districts.find((c) => {
			return c.id == event.target.value;
		});
		this.zones = category.zones;
		this.companyForm.patchValue({ 'zone': '' });
	}
	setTypeWiseData():void{
		if(this.data.type == 1){
			this.tiles_category = this.extra.tiles_category;
			
			this.data.tiles_category_data.forEach(element => {

				const category = this.extra.tiles_category.find((c) => { return c.id == element.category_id; });
				const sub_category = category.sub_category.find((sc)=>{ return sc.id == element.sub_category_id })
				element.sizes = element.sizes.map(e=>Number(e));

				this.tiles_categories.push(this.formBuilder.group({
					id			: [element.id],
					category	: [element.category_id, [Validators.required]],
					subcategory	: [element.sub_category_id, [Validators.required]],
					subcategoryList	: [category.sub_category],
					size		: [sub_category.sizes.filter(e => element.sizes.includes(e.id)), [Validators.required]],
					sizeList		: [sub_category.sizes],
				}));
			});
		}else if(this.data.type == 2){
			this.sanitary_category = this.extra.sanitary_category;
			this.data.main_product_data.forEach(element => {
				this.sanitary_main_categories.push(this.formBuilder.group({
					id			: [element.id],
					category	: [element.product, [Validators.required]],
				}));
			});
			this.data.sub_product_data.forEach(element => {
				this.sanitary_sub_categories.push(this.formBuilder.group({
					id			: [element.id],
					category	: [element.product, [Validators.required]],
				}));
			});
		}else if(this.data.type == 3){
			this.various_category = this.extra.various_category;
			let var_product = this.data.primary_product_data.map(e =>  e.product);
			this.companyForm.patchValue({'various_categories': this.various_category.filter(e => var_product.includes(e.id))})
			this.companyForm.get('various_categories').setValidators([Validators.required]);
			this.companyForm.get('various_categories').updateValueAndValidity();
		}else if(this.data.type == 4 || this.data.type == 5){
			this.trader_dealer_category = this.data.type == 4 ? this.extra.trader_category : this.extra.dealer_category;
			const existingData = this.data.type == 4 ? this.data.trader_categories_data : this.data.dealer_categories_data;
			existingData.forEach(element => {
				const category = this.trader_dealer_category.find((c) => {
					return c.id == element.category_id;
				});
				element.sub_category_ids = element.sub_category_ids.map(e => Number(e))
				this.trader_dealer_categories.push(this.formBuilder.group({
					id			: [element.id],
					category	: [element.category_id, [Validators.required]],
					subcategory	: [category.sub_category.filter(e => element.sub_category_ids.includes(e.id)), [Validators.required]],
					subcategoryList	: [category.sub_category],
				}));
			});
			this.companyForm.get('c_role').setValidators([Validators.required]);
			this.companyForm.get('c_role').updateValueAndValidity();
			this.companyForm.get('c_brand').setValidators(null);
			this.companyForm.get('c_brand').updateValueAndValidity();
		}else if(this.data.type == 6){
			this.transporter_service = this.extra.transporter_service;
			const service = this.transporter_service.find((c) => {
				return c.id ==  this.data.services_data.service_id;
			});
			this.transporter_area = [];
			if(service.type == 2){
				this.countries.forEach(element => {
					this.transporter_area.push({
						id : element.id,
						name : element.country
					});
				});
			}else{
				this.countries.forEach(element => {
					element.state.forEach(s => {
						this.transporter_area.push({
							id : s.id,
							name : s.state
						});
					});
				});
			}
			this.data.services_data.areas = this.data.services_data.areas.map(e => Number(e));
			this.companyForm.patchValue({'transporter_service' : this.data.services_data.service_id });
			this.companyForm.patchValue({'transporter_area' : this.transporter_area.filter(e => this.data.services_data.areas.includes(e.id))});
			this.companyForm.get('c_catelog_pdf').setValidators(null);
			this.companyForm.get('c_catelog_pdf').updateValueAndValidity();
			this.companyForm.get('transporter_service').setValidators([Validators.required]);
			this.companyForm.get('transporter_service').updateValueAndValidity();
			this.companyForm.get('transporter_area').setValidators([Validators.required]);
			this.companyForm.get('transporter_area').updateValueAndValidity();
			this.companyForm.get('c_brand').setValidators(null);
			this.companyForm.get('c_brand').updateValueAndValidity();
		}else if(this.data.type == 7){
			this.supplier_category = this.extra.supplier_category;
			this.data.supplier_category_data.forEach(element => {
				const category = this.supplier_category.find((c) => {
					return c.id == element.category;
				});
				element.sub_category = element.sub_category.map(e => Number(e))
				this.supplier_categories.push(this.formBuilder.group({
					id			: [element.id],
					category	: [element.category, [Validators.required]],
					subcategory	: [category.sub_category.filter(e => element.sub_category.includes(e.id)), [Validators.required]],
					subcategoryList	: [category.sub_category, [Validators.required]],
				}));
			});

			this.companyForm.get('c_brand').setValidators(null);
			this.companyForm.get('c_brand').updateValueAndValidity();
			this.companyForm.get('c_role').setValidators([Validators.required]);
			this.companyForm.get('c_role').updateValueAndValidity();
		}else if(this.data.type == 8){
			this.hotel_amenities = this.extra.hotel_amenities;
			this.data.hotel_rooms.forEach(element => {
				this.hotel_rooms.push(this.formBuilder.group({
					id: [element.id],
					room_name: [element.room_name, [Validators.required]],
					price	: [element.price, [Validators.required]],
				}));
			});
			this.companyForm.patchValue({'hotel_amenities' : this.hotel_amenities.filter(e => this.data.hotel_amenities_data.includes(e.id))})
			this.companyForm.get('hotel_amenities').setValidators([Validators.required]);
			this.companyForm.get('hotel_amenities').updateValueAndValidity();
			this.companyForm.get('c_brand').setValidators(null);
			this.companyForm.get('c_brand').updateValueAndValidity();
			this.companyForm.get('c_catelog_pdf').setValidators(null);
			this.companyForm.get('c_catelog_pdf').updateValueAndValidity();
		}else if(this.data.type == 9){
			this.serviceprovider_service = this.extra.serviceprovider_service;
			
			this.companyForm.patchValue({'serviceprovider_service': this.serviceprovider_service.filter(e => this.data.serviceprovider_service_data.includes(e.id))});
			this.companyForm.get('serviceprovider_service').setValidators([Validators.required]);
			this.companyForm.get('serviceprovider_service').updateValueAndValidity();
		}else if(this.data.type == 10){
			this.cab_services_list = this.extra.cab_services;
			this.cab_cars_list = this.extra.cab_cars;
			
			this.companyForm.patchValue({'cab_service': this.cab_services_list.filter(e => this.data.cab_services_data.includes(e.id))});
			this.companyForm.patchValue({'cab_cars': this.cab_cars_list.filter(e => this.data.cab_cars_data.includes(e.id))});

			this.companyForm.get('cab_service').setValidators([Validators.required]);
			this.companyForm.get('cab_service').updateValueAndValidity();
			this.companyForm.get('cab_cars').setValidators([Validators.required]);
			this.companyForm.get('cab_cars').updateValueAndValidity();
			this.companyForm.get('c_catelog_pdf').setValidators(null);
			this.companyForm.get('c_catelog_pdf').updateValueAndValidity();
			this.companyForm.get('c_brand').setValidators(null);
			this.companyForm.get('c_brand').updateValueAndValidity();
		}
	}

	// Tiles Manufacture Type 1 start
	tiles_category = [];
	//Dynamic Tiles Category Start
	get tiles_categories(): FormArray {
		return this.companyForm.controls.tiles_categories as FormArray;
	}
	addTilesCategories() {
		this.tiles_categories.push(this.formBuilder.group({
			category	: ['', [Validators.required]],
			subcategory	: ['', [Validators.required]],
			subcategoryList	: [[]],
			size			: [[], [Validators.required]],
			sizeList		: [[]],
		}));
	}
	removeTilesCategories(index) {
		this.tiles_categories.removeAt(index);
	}
	//Dynamic Tiles Category End
	onTilesChangeCategory(event, index):void{
		const category = this.extra.tiles_category.find((c) => {
			return c.id == event.target.value;
		});
		this.tiles_categories.controls[index].patchValue({'subcategoryList': category.sub_category});
		this.tiles_categories.controls[index].patchValue({'subcategory': ''})
		this.tiles_categories.controls[index].patchValue({'size': []})
		this.tiles_categories.controls[index].patchValue({'sizeList': []})
	}
	onTilesChangeSubCategory(event, index):void{
		const subcategory = this.tiles_categories.controls[index].value.subcategoryList.find((c) => {
			return c.id == event.target.value;
		});
		this.tiles_categories.controls[index].patchValue({'sizeList': subcategory.sizes})
		this.tiles_categories.controls[index].patchValue({'size': []})
	}
	// Tiles Manufacture Type 1 end


	// Sanitary Manufacture Type 2 start
	sanitary_category = [];
	get sanitary_main_categories(): FormArray {
		return this.companyForm.controls.sanitary_main_categories as FormArray;
	}
	addSanitaryMainProduct() {
		this.sanitary_main_categories.push(this.formBuilder.group({
			category	: ['', [Validators.required]],
		}));
	}
	removeSanitaryMainProduct(index) {
		this.sanitary_main_categories.removeAt(index);
	}
	get sanitary_sub_categories(): FormArray {
		return this.companyForm.controls.sanitary_sub_categories as FormArray;
	}
	addSanitarySubProduct() {
		this.sanitary_sub_categories.push(this.formBuilder.group({
			category	: ['', [Validators.required]],
		}));
	}
	removeSanitarySubProduct(index) {
		this.sanitary_sub_categories.removeAt(index);
	}
	// Sanitary Manufacture Type 2 end

	// Various Manufacture Type 3 start
	various_category = [];
	// Various Manufacture Type 3 end
	
	// Trader type 4 and dealer Type 5 start
	trader_dealer_category = [];
	
	get trader_dealer_categories(): FormArray {
		return this.companyForm.controls.trader_dealer_categories as FormArray;
	}
	addTraderDealerCategories() {
		this.trader_dealer_categories.push(this.formBuilder.group({
			category	: ['', [Validators.required]],
			subcategory	: [[], [Validators.required]],
			subcategoryList	: [[], [Validators.required]],
		}));
	}
	removeTraderDealerCategories(index) {
		this.trader_dealer_categories.removeAt(index);
	}
	onChangeTraderDealerCategory(event, index):void{
		const category = this.trader_dealer_category.find((c) => {
			return c.id == event.target.value;
		});

		this.trader_dealer_categories.controls[index].patchValue({'subcategoryList': category.sub_category})
		this.trader_dealer_categories.controls[index].patchValue({'subcategory': []})
	}
	// Trader type 4 and dealer Type 5 end
	// Transporter type 6 start
	transporter_service = [];
	transporter_area = [];
	onChangeTransporterServiceType(event):void{
		const service = this.transporter_service.find((c) => {
			return c.id == event.target.value;
		});
		this.transporter_area = [];
		if(service.type == 2){
			this.countries.forEach(element => {
				this.transporter_area.push({
					id : element.id,
					name : element.country
				});
			});
		}else{
			this.countries.forEach(element => {
				element.state.forEach(s => {
					this.transporter_area.push({
						id : s.id,
						name : s.state
					});
				});
			});
		}
		this.companyForm.patchValue({'transporter_area':[]});
	}
	// Transporter type 6 end

	// Supplier type 7 start
	supplier_category = [];
	supplier_subcategory = [];
	get supplier_categories(): FormArray {
		return this.companyForm.controls.supplier_categories as FormArray;
	}
	addSupplierCategories() {
		this.supplier_categories.push(this.formBuilder.group({
			category	: ['', [Validators.required]],
			subcategory	: [[], [Validators.required]],
			subcategoryList	: [[], [Validators.required]],
		}));
	}
	removeSupplierCategories(index) {
		this.supplier_categories.removeAt(index);
	}
	onChangeSupplierCategory(event, index):void{
		const category = this.supplier_category.find((c) => {
			return c.id == event.target.value;
		});
		this.supplier_categories.controls[index].patchValue({'subcategory': []})
		this.supplier_categories.controls[index].patchValue({'subcategoryList': category.sub_category})
	}
	// Supplier type 7 end
	// Hotel type 8 start
	hotel_amenities = [];
	get hotel_rooms(): FormArray {
		return this.companyForm.controls.hotel_rooms as FormArray;
	}
	addHotelRoom() {
		this.hotel_rooms.push(this.formBuilder.group({
			room_name: ['', [Validators.required]],
			price	: ['', [Validators.required]],
		}));
	}
	removeHotelRoom(index) {
		this.hotel_rooms.removeAt(index);
	}
	// Hotel type 8 end
	
	// Service Provider type 9 start
	serviceprovider_service = []
	// Service Provider type 9 end
	
	// Cab type 10 start
	cab_services_list = []
	cab_cars_list = []
	// Cab type 10 end



	onSubmit(): void {
		if (this.companyForm.valid) {
			this.companyForm.patchValue({'c_groupofcompany': this.companyForm.value.c_groupofcompany.map(e => e.id)})
			if(this.data.type == 1){
				this.companyForm.value.tiles_categories.forEach((element, index) => {
					this.companyForm.value.tiles_categories[index].size = element.size.map(e => e.id);
				});
			}
			if(this.data.type == 3){
				this.companyForm.value.various_categories = this.companyForm.value.various_categories.map(e => e.id)
			}
			if(this.data.type == 4 || this.data.type == 5){
				this.companyForm.value.trader_dealer_categories.forEach((element, index) => {
					this.companyForm.value.trader_dealer_categories[index].subcategory = element.subcategory.map(e => e.id);
				});
			}
			if(this.data.type == 6){
				this.companyForm.value.transporter_area =  this.companyForm.value.transporter_area.map(e => e.id);
			}
			if(this.data.type == 7){
				this.companyForm.value.supplier_categories.forEach((element, index) => {
					this.companyForm.value.supplier_categories[index].subcategory = element.subcategory.map(e => e.id);
				});
			}
			if(this.data.type == 8){
				this.companyForm.value.hotel_amenities =  this.companyForm.value.hotel_amenities.map(e => e.id);
			}
			if(this.data.type == 9){
				this.companyForm.value.serviceprovider_service =  this.companyForm.value.serviceprovider_service.map(e => e.id);
			}
			if(this.data.type == 10){
				this.companyForm.value.cab_service =  this.companyForm.value.cab_service.map(e => e.id);
				this.companyForm.value.cab_cars =  this.companyForm.value.cab_cars.map(e => e.id);
			}
			const formData = new FormData();
			for (let value of Object.keys(this.companyForm.value)) {
				if(
					value == 'c_photos' || 
					value == "productGallary" || 
					value == "tiles_categories" || 
					value == "sanitary_main_categories" ||
					value == "sanitary_sub_categories" ||
					value == "trader_dealer_categories" ||
					value == "supplier_categories" ||
					value == "hotel_rooms" 
				){
					this.companyForm.value[value].forEach((element,index) => {
						Object.keys(element).forEach(element1 => {
							formData.append(`${value}[${index}][${element1}]`, element[element1]);
						});
						
					});
				}else{
					formData.append(value, this.companyForm.value[value]);
				}
			}
			formData.append('id', this.data.id.toString());
			this.userService.saveProfile(formData).subscribe(response => {
				if(response.success){
					this.router.navigate(['/profile']);
				}else{
					this.userService.errorMessage(response.message);
				}
			});
		}
		else {
			this.formValidationService.validateAllFormFields(this.companyForm);
			const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
}
