<main>
	<!-- <app-breadcrumb [pageName]="'Buyer LIST VIEW'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area seller-post-page" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Buyer List</h4>
						<h1>Buyer List View</h1>
						<!-- <img src="assets/img/line.svg" width="120"> -->
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-xl-10  col-md-12">
					<div class="search-bx buylist-form">
						<form>
							<div class="search-box5">
								<select (change)="onChangeProduct($event.target.value)">
									<option value="">Select Product</option>
									<option *ngFor="let p of products" [value]="p.id+'-'+p.type">{{p.product}}</option>
								</select>
							</div>
							<div class="search-box3">
								<select (change)="onChangeSize($event.target.value)">
									<option value="">Select Size</option>
									<option *ngFor="let s of sizes" [value]="s.id">{{s.size}}</option>
								</select>
								<button class="ser-btn"  (click)="filterOption()"><i class="fa fa-search"></i></button>
							</div>
						</form>
					</div>
					<div *ngFor="let post of list; let i = index">
						<div class="single-buy" >
							<div class="row align-center">
								<div class="col-xl-2 col-md-2" routerLink="/buyer-posts-detail"
								[state]="{id: post.id}">
									<div style="text-align: center;">
										<img [src]="post.logo" alt="Company Logo">
									</div>
								</div>
								<div class="col-xl-2 col-md-2" routerLink="/buyer-posts-detail"
								[state]="{id: post.id}">
									<div class="tiles-info">
										<h5>{{post.grade}}</h5>
										<h5>{{post.requirement_type}}</h5>
										<h5>{{post.product}}</h5>
										<h5 *ngIf="post.size != ''">{{post.size}}</h5>
									</div>
								</div>
								<div class="col-xl-3  col-md-3" routerLink="/buyer-posts-detail"
								[state]="{id: post.id}">
									<div class="users-info">
										<h4>{{post.name}}</h4>
										<h5 class="profile-post">{{post.company_role}}</h5>
										<p>{{post.city}}</p>
										<p><b>Tile Size:</b>{{post.tile_size}}</p>
									</div>
								</div>
								<div class="col-xl-3  col-md-3" routerLink="/buyer-posts-detail"
								[state]="{id: post.id}">
									<div class="users-info">
										<h5 class="tiles-post">Quantity: {{post.quantity}}</h5>
										<p>{{post.date}}</p>
										<p>{{post.time}}</p>

									</div>
								</div>
								<div class="col-xl-2 col-md-2">
									<div class="buy-btn">
										<a (click)="analytics(post.id, 1)" href="tel:{{post?.contact}}"><button class="call-btn">Call</button> </a>
										<a (click)="analytics(post.id, 2)" href="https://api.whatsapp.com/send?phone={{post?.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website."
											target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
										<a routerLink="/chat" [state]="{cIdChatid: post?.id, name: post?.name,image: post?.logo}"><button class="chat-btn">Chat</button> </a>
									</div>
								</div>
							</div>
						</div>
						<app-buyer-form *ngIf="i % 5 === 4" [tiles]="extra?.tiles" [sanitary]="extra?.sanitary" [various]="extra?.various"></app-buyer-form>
					</div>
					<app-buyer-form *ngIf="list.length <= 4" [tiles]="extra?.tiles" [sanitary]="extra?.sanitary" [various]="extra?.various"></app-buyer-form>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new" style="cursor: pointer;" (click)="morePosts()"> View More</a>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>