
<ng-template *ngIf="isModal then inquryModal else inquryForm"></ng-template>

<ng-template #inquryModal>
  <mat-dialog-content style="padding: 0;width: 100%;overflow: hidden;">
    <form [formGroup]="inquiryForm" (ngSubmit)="onSubmit()">
      <div class="row mx-0">
        <div class="col-md-4 form_toogle_pad_ban">
          <img src="assets/img/exhibition_popup2.jpg"alt="" class="img-fluid" />
        </div>
        <div class="col-md-8 form_toogle_pad_ban">
          <div class="d-flex justify-content-end w-100">
            <button
              (click)="closeModal()"
              type="button"
              class="btn btn-default hideobj close form_toogle_close_ban p-1"
            >
              &times;
            </button>
          </div>
          <div class="form_toogle_text_ban row">
            <div class="col-md-12">
              <h3>GET BEST OFFER</h3>
            </div>
            <div class="col-12 mt-3">
              <div class="radio-main">
                <label class="radio-con">
                  <span>Best Rate</span>
                  <input
                    type="radio"
                    formControlName="type"
                    name="type"
                    value="1"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="radio-con">
                  <span>Best Quality</span>
                  <input
                    type="radio"
                    formControlName="type"
                    name="type"
                    value="2"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="radio-con"
                  ><span>Average Rate Quality</span>
                  <input
                    type="radio"
                    formControlName="type"
                    name="type"
                    value="3"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
  
            <div class="col-12 mt-3">
              <div class="radio-main">
                <label class="radio-con"
                  ><span>Tiles</span>
                  <input
                    type="radio"
                    name="requirement"
                    formControlName="requirement"
                    value="1"
                    (change)="onRequirementChange($event)"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="radio-con"
                  ><span>Sanitary</span>
                  <input
                    type="radio"
                    name="requirement"
                    formControlName="requirement"
                    value="2"
                    (change)="onRequirementChange($event)"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="radio-con">
                  <span>Various Products</span>
                  <input
                    type="radio"
                    name="requirement"
                    formControlName="requirement"
                    value="3"
                    (change)="onRequirementChange($event)"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
  
            <div class="{{inquiryForm.value.requirement == 1 ? 'col-md-6' : 'col-12'}} mt-3">
              <select class="gti_form_popup_select"
                (change)="onChangeProduct($event)"
                formControlName="product"
              >
                <option value="">Select Product</option>
                <option *ngFor="let p of products" [value]="p.id">
                  {{ p.product }}
                </option>
              </select>
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('product').touched &&
                    inquiryForm.get('product').invalid
                }"
                class="invalid-feedback font-medium"
                >Please select product</label
              >
            </div>
  
            <div class="col-md-6 mt-3" *ngIf="inquiryForm.value.requirement == 1">
              <select formControlName="size" class="gti_form_popup_select">
                <option value="">Select Size</option>
                <option *ngFor="let s of sizes" [value]="s.id">
                  {{ s.size }}
                </option>
              </select>
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('size').touched &&
                    inquiryForm.get('size').invalid
                }"
                class="invalid-feedback font-medium"
                >Please select size</label
              >
            </div>
  
            <div class="col-6 mt-3">
              <input class="gti_form_popup_inpt"
                type="text"
                formControlName="firm_name"
                placeholder="Enter Firm Name"
              />
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('firm_name').touched &&
                    inquiryForm.get('firm_name').invalid
                }"
                class="invalid-feedback font-medium"
                >Please enter firm name</label
              >
            </div>
            <div class="col-md-6 mt-3">
              <input type="number" min="1" class="gti_form_popup_inpt" formControlName="qty" placeholder="Qty." />
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('qty').touched &&
                    inquiryForm.get('qty').invalid
                }"
                class="invalid-feedback font-medium"
                >Please enter qty</label
              >
            </div>
            <div class="col-md-6 mt-3">
              <input class="gti_form_popup_inpt" type="text" formControlName="city" placeholder="City" />
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('city').touched &&
                    inquiryForm.get('city').invalid
                }"
                class="invalid-feedback font-medium"
                >Please enter city</label
              >
            </div>
            <div class="col-md-6 mt-3">
              <input type="text" class="gti_form_popup_inpt" formControlName="cell" placeholder="Cell No." />
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('cell').touched &&
                    inquiryForm.get('cell').invalid
                }"
                class="invalid-feedback font-medium"
                >Please enter Cell</label
              >
            </div>
        <div class="col-12 mt-3 text-center">
        <input 
          type="submit"
          class="view-btn form_toogle_col_btn"
          name=""
          value="Submit"
        />
        </div>
            <!-- <label class="label-heading">Products :-</label> -->
            <!-- <div class="select-box mt-3">
        
              
            </div> -->
            <!-- <label class="label-heading" *ngIf="inquiryForm.value.requirement == 1">Size :-</label> -->
  
            <div class="form_toogle_col_ban row"></div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</ng-template>

<ng-template #inquryForm>
  <div class="home_form_get_offer">
    <form [formGroup]="inquiryForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-7 form_toogle_pad_ban">
          <div class="form_offer_text_ban pe-3">
            <h5>Get Best Offer</h5>
            <h3>Just one click & you can send multiple Inquiries to Manufacturers Companies</h3>
            <p class="">Looking for a Great deal on ceramics and building materials? GTI Directory's "Get Best Offer" service offers you the best of all! With just one click, you can send multiple Inquiries at once to hundreds of manufacturers. Manufacturers will contact you and offer you the best deal available. Now you can get high-quality products in the shortest possible time.</p>
            <div class="radio-main">
              <label class="radio-con">Best Rate
                <input type="radio" formControlName="type" name="type" value="1">
                <span class="checkmark"></span>
              </label>
              <label class="radio-con"> Best Quality
                <input type="radio" formControlName="type" name="type" value="2">
                <span class="checkmark"></span>
              </label>
              <label class="radio-con">Average Rate Quality
                <input type="radio" formControlName="type" name="type" value="3">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="radio-main">
              <label class="radio-con">Tiles
                <input type="radio" name="requirement" formControlName="requirement" value='1'>
                <span class="checkmark"></span>
              </label>
              <label class="radio-con">Sanitary
                <input type="radio" name="requirement" formControlName="requirement" value='2'>
                <span class="checkmark"></span>
              </label>
              <label class="radio-con"> Various Products
                <input type="radio" name="requirement" formControlName="requirement" value='3'>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="get_best_offer_img_ban"><img src="assets/img/get_best_offer_ban.jpg" alt=""></div>
          </div>
        </div>
        <div class="col-md-5 form_toogle_pad_ban" >
          <div class="form_toogle_col_ban">
            <div class="select-box" >
              <select 
                (change)="onChangeProduct($event)"
                formControlName="product"
              >
                <option value="">Select Product</option>
                <option *ngFor="let p of products" [value]="p.id">
                  {{ p.product }}
                </option>
              </select>
              
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('product').touched &&
                    inquiryForm.get('product').invalid
                }"
                class="invalid-feedback font-medium"
                >Please select product</label
              >
            </div>
            <div class="select-box" *ngIf="inquiryForm.value.requirement == 1">
              <select formControlName="size">
                <option value="">Select Size</option>
                <option *ngFor="let s of sizes" [value]="s.id">
                  {{ s.size }}
                </option>
              </select>
              <label
                [ngClass]="{
                  'is-invalid':
                    inquiryForm.get('size').touched &&
                    inquiryForm.get('size').invalid
                }"
                class="invalid-feedback font-medium"
                >Please select size</label
              >
            </div>
            <div class="input-form ">
              <input type="text" class="search-input" formControlName="qty" placeholder="Enter Quantity" />
              <label  class="invalid-feedback font-medium">Quantity</label>
            </div>
            <div class="input-form ">
              <input type="text" class="search-input" formControlName="firm_name" placeholder="Enter Firm Name" />
              <label  class="invalid-feedback font-medium">Firm name</label>
            </div>
            <div class="input-form">
              <input type="text" class="search-input" formControlName="city" placeholder="City" />
              <label class="invalid-feedback font-medium">City</label>
            </div>
            <div class="input-form ">
              <input type="text" class="search-input" formControlName="cell" placeholder="Cell No." />
              <label  class="invalid-feedback font-medium">Cell No.</label>
            </div>
            <div style="text-align: center;">
              <input type="submit" class="view-btn form_toogle_col_btn" name="" value="Submit" />
            </div>
          </div>
  
        </div>
      </div>
    </form>
  </div>
</ng-template>