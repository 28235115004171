import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { TilesManufactureService } from "../../services/tiles-manufacture.service";
import { SanitaryService } from "../../services/sanitary.service";
import { VariousService } from "../../services/various.service";
import { SupplierService } from "../../services/supplier.service";
import { ServiceProviderService } from "../../services/service-provider.service";
import { CabService } from "../../services/cab.service";
import { TransporterService } from "../../services/transporter.service";
import { HotelService } from "../../services/hotel.service";
import { TraderService } from "../../services/trader.service";
import { DealerService } from "../../services/dealer.service";
import { FollowService } from "../../services/follow.service";


import { ViewChild } from '@angular/core';

import { HomeService } from '../../services/home.service';

import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CatelougeModalComponent } from "../common/catelouge-modal/catelouge-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { CompanyService } from "src/app/services/company.service";
//import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.css"],
    standalone: false
})
export class ProfileComponent implements OnInit {
	
	statusForm: FormGroup;
	comapanyId: number = 0;
	type: number = 0;
	data: any = [];
	dataList: any = [];
	myMessageList: any = [];
	user;
	
	fileTypes: Array<string> = ['jpg', 'jpeg', 'png'];

	/*

	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		navSpeed: 600,
		navText: ['&#8249', '&#8250;'],
		responsive: {
		  0: {
			items: 1 
		  },
		  400: {
			items: 2
		  },
		  760: {
			items: 3
		  },
		  1000: {
			items: 3
		  }
		},
		nav: true
	  }
*/
	@ViewChild('statuspic') statuspic: HTMLElement;

	constructor(
		private router: Router,
		private userService: UserService,
		private companyService: CompanyService,
		private followService: FollowService,
		private homeService : HomeService,
		private formBuilder: FormBuilder,
		public route: ActivatedRoute,
		public dialog: MatDialog,
		
	) { }

	ngOnInit(): void {

	

		this.user = this.userService.getUser()
		// console.log(this.user)
		this.comapanyId = this.user.company.id;
		this.type = this.user.company.type;

		if (this.comapanyId) {
			history.state.id = this.comapanyId;
			this.route.url.subscribe((url) => {
			  this.fetchCompanyDetails(this.comapanyId);
			});
		  } else {
			this.router.navigate(["/"]);
		  }

		this.statusForm = this.formBuilder.group({
			id: [this.comapanyId.toString(), [Validators.required]],
			statusmessage: ['', [Validators.required]],
			statuspic: [''],
			statuspicImg: [''],
		});
     
	//	this.getStatusList()
		this.getStatusListById()
	}

	fetchCompanyDetails(id,headers={},profile_view=true) {
		this.companyService.getCompanyDetail({ id },headers).subscribe((response) => {
		  this.response(response);
		  this.route.queryParams.subscribe((params) => {
			if (params.id) {
			  history.state.id = params.id;
			  history.state.type = params.type;
			  this.comapanyId = history.state.id;
			  this.type = history.state.type;
			}
		  });
		});
	  }



		//Dynamic Company Photots End
		onSelectImage(files) {
			if (files.length === 0) {
				return;
			}
			const extension = files[0].name.split('.').pop().toLowerCase();
			const isSuccess = this.fileTypes.indexOf(extension) > -1;
			if (isSuccess) {
				this.statusForm.patchValue({ 'statuspic': files[0] });
				var reader = new FileReader();
				reader.onload = (event: any) => {
					this.statusForm.patchValue({ 'statuspicImg': event.target.result })
				}
				reader.readAsDataURL(files[0]);
			}
		}


	updateMessagestatus() {
		
		const form = new FormData();
		form.append(`id`,  this.comapanyId.toString());
		form.append(`statusmessage`, this.statusForm.value.statusmessage);



		form.append(`statuspic`, this.statusForm.value.statuspic);
	if(this.statusForm.value.statusmessage.length>0) {
		this.homeService.updateStatus(form).subscribe(res => {
			
			if(res.success){
				alert(res.message)
			}else{
				alert(res.message);
			}
		});
	} else {
     alert("Please add your status")
	}

	}

	getStatusList() {
		
		this.homeService.statusList(10).subscribe(res => {
			if(res.success){
			//	console.log(res)
				this.dataList = res
				//console.log(this.dataList )
			}else{
				
			}
		});
	}


	getStatusListById() {
		
		this.homeService.statusListById(this.comapanyId).subscribe(res => {
			if(res.success){
				
				this.myMessageList = res
				
			//	console.log(this.myMessageList )
			}else{
				
			}
		});
	}

	public handleMissingImage(event: Event) {
		//(event.target as HTMLImageElement).style.display = 'none';
		(event.target as HTMLImageElement).src="assets/img/placeholdermsg.png"
	//	assets/img/placeholdermsg.png

	  }

	/*
	updatestatus() {
		
		const form = new FormData();
		let r = (Math.random() + 1).toString(36).substring(7);
		form.append(`id`,  this.comapanyId.toString());
		form.append(`statusmessage`, "This is it" + r);
		this.homeService.updateStatus(form).subscribe(res => {
			if(res.success){
				alert(res.message)
			}else{
				alert(res.message);
			}
		});
	}
	*/

	followUnfollow(status):void{
		
		this.followService.followUnfolllowCompany({company_id : this.comapanyId, status: status}).subscribe(response => {
			if(response.success){
				console.log(response.data)
				this.userService.sucessMessage(response.message)
				this.ngOnInit();
			}else{
				this.userService.errorMessage(response.message)
			}
		})
	}

	response(response):void{
		if (response.success) {
			this.data = response.data;	
		} else {
			this.userService.errorMessage(response.message)
		}
	}
	edit(){
		this.router.navigate(['profile/edit'],{state: {editCid: this.comapanyId, type: this.type}})
	}
	open_link = (link) => {
		window.open(link,'_blank')
	  }
	
	  onActionBtnClick(action_type=null,value=null){
	
		const actions = {
		  call : ()=>{
			this.open_link(`tel:${this.data.contact}`)
		  },
		  whatsapp : ()=>{
			this.open_link(`https://api.whatsapp.com/send?phone=${this.data.whatsapp}&text=Hi,%20Got%20reference%20from%20GTI%20website`)
		  },
		  email : ()=>{
			this.open_link(`mailto:${this.data.email}`)
		  },
		  web : ()=>{
			this.open_link(`${this.data.website}`)
		  },
		  social_media : ()=>{
			this.open_link(`${value}`)
		  },
		  personal_whatsapp_sale : ()=>{
			this.open_link(`${value}`)
		  },
		  personal_call_sale : ()=>{
			this.open_link(`${value}`)
		  },
		  personal_whatsapp_purchase : ()=>{
			this.open_link(`${value}`)
		  },
		  personal_call_purchas : ()=>{
			this.open_link(`${value}`)
		  },
		  product_gellery : () => {
			this.router.navigate(['gallery'],{relativeTo: this.route,state:{photos : this.data?.product_photos}})
		  },
		  company_photo : () => {
			this.router.navigate(['photos'],{relativeTo: this.route,state:{photos : this.data?.company_photos}})
		  },
		  about_company : () => {
			this.router.navigate(['about'],{relativeTo: this.route,state:{about  : this.data?.about}})
		  },
		  inquiry : () => {
			console.log('hello')
		  },
		  view_catelouge : () => {
			this.dialog.open(CatelougeModalComponent,{
			  'data' : {
				'catalogs':this.data.catalogs
			  }
			})
		  },
		}
	
	
		
	
	
		if(actions[action_type]){
		  actions[action_type]()
		  return ;
		}
		
	  }
}
