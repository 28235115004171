<main>
	<!-- <app-breadcrumb [pageName]="'Help & Support'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Connect With Customer</h4>
						<h1>Help & Support</h1>
						<!-- <img src="assets/img/line.svg" width="120"> -->
					</div>
				</div>
			</div>
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="form-box help_supp_form_ban">
                        <div class="help_supp_form_cont">
                            <h2>Contact</h2>
                            <div class="">                                
                                <p>Email : <span>gti.directory&#64;gmail.com</span></p>
                                <p>Mobile : <span>+91 909 908 907 1</span></p>
                            </div>
                        </div>
                        <div class="help_supp_form_inpt">
                            <label for="image" class="label">Message</label>
                            <textarea placeholder="Write message..." [(ngModel)]="message" class="form-control" rows="6"></textarea>
                        </div>
                        <div class="help_supp_form_btn">
                            <input type="submit" name="" value="Submit" (click)="submit()">
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</section>
	<!-- design area end -->
</main>