import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root'
})
export class AccessRoleGuard  {
	constructor(private userService: UserService, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot) :  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const { access_type , access_key } = route.data;
		const access = this.userService.getAccessRoleByType(access_type)
		const key = access_key ?? 'view'
		const is_allowed = access? ( access[key] ?? false ) : false;

		if(!is_allowed){
			this.userService.errorMessage("You don't have permission to use this feature!")	
			this.router.navigate(['/']);
			return false;
		}

		return true
	}
}
