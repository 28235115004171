<main>
  <!-- <app-breadcrumb [pageName]="slug"></app-breadcrumb> -->

  <section class="design-area" id="service">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 text-center">
          <div class="section-title">
            <h4>{{ title[type] ?? "Firms" }}</h4>
            <h1>{{ page_title }}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="search-bx buylist-form change_filter_ban">
            <form class="head_nav_serch_w my-2 position-relative ">
              <div class="head_drop_serc_ban head_drop_serc_ban-active w-100 shadow-sm head_drop_serc_ban_direc_detai">
                <input type="text" class="form-control w-100" placeholder="Search Company......" [formControl]="search" (input)="onSearchCompany()"
                  (focus)="openBox()" />
                <div *ngIf="!isSearchLoading" class="head_drop_serc_oncli_ban" (click)="clearSearch()">
                  <i class="fa fa-{{
                      isBoxOpen && companies.length ? 'times' : 'search'
                    }}" aria-hidden="true"></i>
                </div>
                <div *ngIf="isSearchLoading" class="head_drop_serc_oncli_ban" style="cursor: progress">
                  <div class="spinner-border" style="height: 1.5rem; width: 1.5rem" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <div *ngIf="isBoxOpen && companies.length" class="d-flex flex-column list-container shadow-sm">
                <div class="list-container-wapper">
                  <div *ngFor="let company of companies">
                    <div class="d-flex p-2" (click)="onCompanyClick(company)">
                      <div class="">
                        <img [src]="company.logo" class="img-fluid company-logo" alt="Company Logo" />
                      </div>
                      <div class="company-name w-100">
                        <p  class="">
                          {{ company.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <form [formGroup]="filterForm" (submit)="onFilterChange()" class="gap-2">
              <ng-container *ngIf="type === type_of_directory['manufacturers']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="size_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Size</ng-option>
                    <ng-option *ngFor="let s of sizes" [value]="s.id">{{
                      s.size
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="zone_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Zone</ng-option>
                    <ng-option *ngFor="let z of zones" [value]="z.id">{{
                      z.zone
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="type === type_of_directory['sanitaryware']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="district_id"
                    (change)="onCityChange($event)"
                  >
                    <ng-option [value]="0">Select City</ng-option>
                    <ng-option *ngFor="let c of cities" [value]="c.id">{{
                      c.city
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="zone_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Zone</ng-option>
                    <ng-option *ngFor="let z of zones" [value]="z.id">{{
                      z.zone
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container
                *ngIf="type === type_of_directory['various-manufacturer']"
              >
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="product_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Product</ng-option>
                    <ng-option *ngFor="let p of products" [value]="p.id">{{
                      p.product
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="zone_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Zone</ng-option>
                    <ng-option *ngFor="let z of zones" [value]="z.id">{{
                      z.zone
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="type === type_of_directory['traders']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="role"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Roles</ng-option>
                    <ng-option *ngFor="let role of roles" [value]="role">{{
                      role
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="zone_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Zone</ng-option>
                    <ng-option *ngFor="let z of zones" [value]="z.id">{{
                      z.zone
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="type === type_of_directory['dealers']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="state_id"
                    (change)="onStateChange($event)"
                  >
                    <ng-option [value]="0"
                      >Select
                      {{ this.category ? "Country" : "State" }}</ng-option
                    >
                    <ng-option *ngFor="let s of states" [value]="s.id">{{
                      s.state
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="district_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0"
                      >Select {{ this.category ? "State" : "City" }}</ng-option
                    >
                    <ng-option *ngFor="let c of cities" [value]="c.id">{{
                      c.district
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="type === type_of_directory['transpoters']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="service_id"
                    (change)="onServiceChange($event)"
                  >
                    <ng-option [value]="0">Select Service</ng-option>
                    <ng-option *ngFor="let s of services" [value]="s.id">{{
                      s.service
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="area_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Area</ng-option>
                    <ng-option *ngFor="let a of areas" [value]="a.id">{{
                      a.name
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="type === type_of_directory['suppliers']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="product_id"
                    (change)="onProductChange($event)"
                  >
                    <ng-option [value]="0">Select Product</ng-option>
                    <ng-option *ngFor="let p of products" [value]="p.id">{{
                      p.product
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="sub_category_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Sub Product</ng-option>
                    <ng-option
                      *ngFor="let sc of sub_products"
                      [value]="sc.id"
                      >{{ sc.sub_category }}</ng-option
                    >
                  </ng-select>
                </div>
              </ng-container>
              <ng-container
                *ngIf="type === type_of_directory['service-provider']"
              >
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="service_id"
                    (change)="onProviderServiceChange($event)"
                  >
                    <ng-option [value]="0">Select Service</ng-option>
                    <ng-option *ngFor="let s of services" [value]="s.id">{{
                      s.service
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="sub_service_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Sub Service</ng-option>
                    <ng-option
                      *ngFor="let ss of sub_services"
                      [value]="ss.id"
                      >{{ ss.sub_service }}</ng-option
                    >
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="type === type_of_directory['cab-service']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="service_id"
                    (change)="onFilterChange($event)"
                  >
                    <ng-option [value]="0">Select Service</ng-option>
                    <ng-option *ngFor="let s of services" [value]="s.id">{{
                      s.service
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="car_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Cars</ng-option>
                    <ng-option *ngFor="let c of cars" [value]="c.id">{{
                      c.car
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="type === type_of_directory['hotel']">
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="price_id"
                    (change)="onFilterChange($event)"
                  >
                    <ng-option [value]="0">Select Prices</ng-option>
                    <ng-option *ngFor="let p of prices" [value]="p.id">{{
                      p.price
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="search-box3">
                  <ng-select
                    class="w-100"
                    formControlName="zone_id"
                    (change)="onFilterChange()"
                  >
                    <ng-option [value]="0">Select Zone</ng-option>
                    <ng-option *ngFor="let z of zones" [value]="z.id">{{
                      z.zone
                    }}</ng-option>
                  </ng-select>
                </div>
              </ng-container>
            </form>
            
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <ng-template
            *ngTemplateOutlet="
              OwlCarouselSection;
              context: { companies: premium, cardType: 'comp-pro-img-r' }
            "
          ></ng-template>
        </div>
        <div class="col-xl-12">
          <ng-template
            *ngTemplateOutlet="
              OwlCarouselSection;
              context: { companies: standard, cardType: 'comp-pro-img-e' }
            "
          ></ng-template>
        </div>
        <div class="col-xl-12">
          <ng-template
            *ngTemplateOutlet="
              OwlCarouselSection;
              context: { companies: classic, cardType: 'comp-pro-img-c' }
            "
          ></ng-template>
        </div>
        <div class="col-xl-12">
          <ng-template
            *ngTemplateOutlet="GeneralSection; context: { companies: general }"
          ></ng-template>
        </div>
      </div>
    </div>
  </section>
</main>

<ng-template
  #OwlCarouselSection
  let-companies="companies"
  let-cardType="cardType"
>
  <div class="dire-item cro-side-icn row">
      <div class="col-md-6" *ngFor="let company of companies">
        <ng-template
          *ngTemplateOutlet="
            PaidCompanyCard;
            context: { comapny: company, cardType: cardType }
          "
        ></ng-template>
      </div>
  </div>
</ng-template>

<ng-template #PaidCompanyCard let-company="comapny" let-cardType="cardType">
  <div class="single-buy dire_detai_box">
    <div class="row align-center">
      <div class="col-xl-8 col-md-8">
        <div class="tiles_info_ban" style="background-image:url('../../../../assets/img/pattern-10.png');"></div>
        <div class="tiles-info"  routerLink="/company/{{ company.slug }}/{{ company.id }}/"
        [state]="{ id: company.id, type: company.type, cp_id: category }">
          <h4>
            {{ company.name }}
          </h4>
          <h6>
            {{ company.brand }} | {{ company.city }}
          </h6>
          <!-- <h6 routerLink="/company/{{pre.id}}/{{pre.name | slug}}" [state]="{id: pre.id, type:1, cp_id: categoryId}">{{pre.address}}</h6> -->
          <div class="address">
            <h4>
              {{ company.address }}
            </h4>
          </div>
          <div class="buy-btn">
            <button
              (click)="
                onActionBtnClick(company, paid_companies_buttons[company.type])" class="view-btn">
              {{ paid_companies_buttons[company.type] }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-xl-4 col-md-4"
        routerLink="/company/{{ company.slug }}/{{ company.id }}"
        [state]="{ id: company.id, type: company.type, cp_id: category }">
        <div [class]="'profile-img comp-pro-img ' + cardType">
          <img [src]="company.logo" alt="Company Logo" />
        </div>
      </div>
      
    </div>
  </div>
</ng-template>

<ng-template #GeneralSection let-companies="companies">
  <div class="row mt-5">
    <div
      class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6"
      *ngFor="let company of companies"
      >
      <div class="sta-box tile-info-box">
        <div
          routerLink="/company/{{ company.slug }}/{{ company.id }}"
          [state]="{ id: company.id, type: company.type, cp_id: category }"
        >
          <div class="">
            <div class="profile-img">
              <img [src]="company.logo" alt="Company Logo" />
            </div>
            <h6>{{ company.name }}</h6>
            <p class="inf-ect-pera" >{{company.type === 1 ? company.zone.zone : company.address}}</p>
          </div>
        </div>
        <div class="buy-btn">
          <button (click)="onActionBtnClick(company,free_companies_buttons[company.type])" class="view-btn">
            <div
                *ngIf="is_inquiry_loading"
                class="head_drop_serc_oncli_ban"
                style="cursor: progress; margin-right: 0.3rem"
              >
                <div
                  class="spinner-border"
                  style="height: 1rem; width: 1rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            {{ free_companies_buttons[company.type] }}
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="page < totalPages && !isLoading"
      class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center"
    >
      <a
        class="view-btn view-new comp-info-more-btn"
        style="cursor: pointer"
        (click)="onPageChange()"
      >
        View More</a
      >
    </div>
    <div
      *ngIf="isLoading"
      class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</ng-template>
