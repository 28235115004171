import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-raw-material',
    templateUrl: './raw-material.component.html',
    styleUrls: ['./raw-material.component.css'],
    standalone: false
})
export class RawMaterialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
