<footer class="footer-area" id="contact">
  <div class="position-relative footer_bg_banner">
    <div class="header-top pt-120 pb-80">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-6">
            <div class="widget footer-sidebar">
              <div class="site-logo footer-site-info follow_us">
                <!-- <a href="index.html">
                  <img src="assets/img/logo.png" alt="" />
                  <h4>GujaratTilesInfo</h4>
                </a>
                <p class="mt-10">
                  For more than Two years, the "Gujarat Tiles Info" label has
                  been promoting the image and content of the Indian ceramic
                  industry – tiles, sanitaryware and Others – in domestic
                  markets. It stands for tradition, quality, innovation and
                  creativity as well as for a guarantee of Made in India
                  production.
                </p> -->

                <h3 class="mb-25">Follow Us</h3>
                <div class="social-list footer-social-list footer_social_icon">
                  <a
                    href="{{links.facebook}}"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                  <a
                    href="{{links.linkedin}}"
                    target="_blank"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                  <a href="{{links.twitter}}" target="_blank"
                    ><i class="fa fa-twitter"></i
                  ></a>
                  <a
                    href="{{links.instagram}}"
                    target="_blank"
                    ><i class="fa fa-instagram"></i
                  ></a>
                  <a
                    href="{{links.youtube}}"
                    target="_blank"
                    ><i class="fa fa-youtube"></i
                  ></a>
                </div>
                <div class="d-flex align-items-center">
                  <a class="str-img"
                  href="https://play.google.com/store/apps/details?id=com.essence.gujarattilesinfo"
                  ><img src="assets/img/play_store_image.png" class="img-fluid"
                  /></a>
                  <a
                    class="str-img"
                  href="https://apps.apple.com/in/app/gujarat-tiles-info/id915485358"
                  ><img src="assets/img/app.png" class="img-fluid"
                  /></a>
                </div>

                <!-- --------new design ------------ -->
                <!-- <div class=" footer-social-list footer_social_icon">
                  <ul>
                    <li> <a
                      href="{{links.facebook}}"
                      target="_blank"
                      ><i class="fa fa-facebook"></i
                    > Facebook</a></li>
                    <li>
                      <a
                      href="{{links.linkedin}}"
                      target="_blank"
                      ><i class="fa fa-linkedin"></i
                      >Linked In</a>
                    </li>
                    <li>
                      <a href="{{links.twitter}}" target="_blank"
                      ><i class="fa fa-twitter"></i
                      >Twitter</a>
                    </li>
                    <li>   
                      <a href="{{links.instagram}}"
                        target="_blank"
                        ><i class="fa fa-instagram"></i
                      >Instagram</a>
                    </li>
                    <li>
                      <a href="{{links.youtube}}"
                      target="_blank"
                      ><i class="fa fa-youtube"></i
                      >Youtube</a>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-6 justify-content-md-center d-flex">
            <div class="widget footer-sidebar">
              <h3 class="">Our Services</h3>
              <ul class="ps-0">
                <li>
                  <a routerLink="/contact-us"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i>
                    Contact Us</a
                  >
                </li>
                <li>
                  <a routerLink="/page/about-us"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i> About
                    GTI
                  </a>
                </li>
                <!-- <li><a routerLink="blogs"><i class="fa fa-arrow-right" aria-hidden="true"></i> Blog & News</a></li> -->
                <li>
                  <a
                    href="{{links.rate_review}}"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i> Rating
                    & Review
                  </a>
                </li>
                <li>
                  <a routerLink="/feedback"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i>
                    Feedback & Complain
                  </a>
                </li>
                <li>
                  <a routerLink="/page/term-condition"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i> Terms
                    & Condition
                  </a>
                </li>
                <li>
                  <a routerLink="/page/privacy-policy"
                    ><i class="fa fa-arrow-right" aria-hidden="true"></i>
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-xl-3 col-lg-3 col-sm-6">
						<div class="widget footer-sidebar quick_link">
							<h3 class="mb-25">Quick Links</h3>
							<ul>
								<li><a routerLink="membership"><i class="fa fa-arrow-right" aria-hidden="true"></i> Subscribe Membership Plan</a></li>
								<li><a routerLink="/jobs/list"><i class="fa fa-arrow-right" aria-hidden="true"></i> Job Junction</a></li>
								<li><a routerLink="directory"><i class="fa fa-arrow-right" aria-hidden="true"></i> Directory</a></li>
							</ul>
						</div>
					</div> -->
          <div class="col-xl-4 col-lg-4 col-sm-6">
            <div class="widget footer-sidebar app-side quick_link">
              <h3 class="mb-25">Newsletter</h3>
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="search-box1 quick_link_inpt">
                  <!-- <label class="quick_link_lebel d-block"
                    >Enter Your Email</label
                  > -->
                  <input
                    type="text"
                    formControlName="email"
                    class="search-input"
                    placeholder="Enter Your Email Address"
                  />
                  <button type="submit" name="" class="quick_link_inpt_val">
                    Subscribe
                  </button>
                  <div
                    *ngIf="
                      form.get('email').touched && form.get('email').invalid
                    "
                  >
                    <label
                      class="invalid-feedback font-medium is-invalid text-white"
                      *ngIf="
                        form.get('email').errors &&
                        form.get('email').hasError('required')
                      "
                      >Please enter email</label
                    >
                    <label
                      class="invalid-feedback font-medium is-invalid text-white"
                      *ngIf="
                        form.get('email').errors &&
                        form.get('email').hasError('email')
                      "
                      >Invalid email</label
                    >
                  </div>
                  <!-- <input type="submit" name="" class="quick_link_inpt" value="Subscribe"> -->
                </div>
              </form>
              <div class="quick_link_contact mt-3">
                <p class="">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span> +91 90990 89071</span>
                </p>
                <a href="mailto:gti.directory@gmail.com">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i
                  ><span>{{' gti.directory@gmail.com'}}</span>
                </a>
              </div>
			  
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bootom">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-xs-12">
            <div class="copyright-content">
              <p>2025 © GTI All Rights Reserved.</p>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-xs-12 text-right">
            <!-- <a href="https://api.whatsapp.com/send?phone=919099089071&text=Hello%20gujarattiles%2C%20%0AI%20want%20to%20ask%20you%20a%20question%20about."><img src="assets/img/help-button.jpg" class="img-fluid gti-wtap-button">	</a> -->
            <div (click)="openModal()">
              <img
                src="assets/img/gti-help-logo.png"
                alt=""
                class="img-fluid inquirybottom"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
