import { Component, Input, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    standalone: false
})
export class GalleryComponent implements OnInit {
  @Input() images : any[] = []

  galleryItems: GalleryItem[];
  

  ngOnInit() {
    // Set items array
    
  }

  patchImages():void {
    console.log(this.images)
  }

}
