<main>
    <!-- <app-breadcrumb [pageName]="'My Sale Post'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h4>Best Saller</h4>
                        <h1>My Sale Post</h1>
                        <!-- <img src="assets/img/line.svg" width="120"> -->
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8  col-md-12">
                    <div class="single-buy" *ngFor="let post of list; let i = index">
                        <div class="row align-center" style="width: 100%;">
                            <div class="col-xl-3 col-md-3">
                                <div style="text-align: center;">
                                    <img [src]="post.logo" alt="Company Logo">
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-3">
                                <div class="tiles-info">
                                    <h5>{{post.name}}</h5>
                                    <h5 class="profile-post">{{post.company_role}}</h5>
                                    <p>{{post.city}}</p>
                                    <p *ngIf="post?.is_approved == 0" class="color-yellow">Pending</p>
                                    <p *ngIf="post?.is_approved == 1" class="color-green">Approved</p>
                                    <p *ngIf="post?.is_approved == 2" class="color-red">Rejected</p>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-3">
                                <div class="tiles-info">
                                    <p>{{post.grade}}</p>
                                    <p>{{post.product}}</p>
                                    <p>{{post.requirement_type}}</p>
                                    <a routerLink="/buyer-posts" [state]="{filter: post?.product_id, product_type: post?.product_type}"><h5 class="profile-post">Related Buyer</h5></a>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-3">
                                <div class="buy-btn">
                                    <h5 class="tiles-post">{{post.rate}}</h5>
                                    <a><button routerLink="/seller-post/edit" [state]="{sellerEditId: post.id}" class="edit-btn-post">Edit</button> </a>
                                    <p>{{post.date}}</p>
									<p>{{post.time}}</p>
                                </div>
                            </div>
                            <div class="col-xl-12 col-md-12 mt-2 text-center" *ngIf="post?.is_approved == 2">
                                <button class="edit-btn-post">Note: {{post?.reject_note}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new" style="cursor: pointer;" (click)="morePosts()"> View More</a>
				</div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>