import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'slug',
    standalone: false
})
export class SlugPipe implements PipeTransform {

  transform(value: string): string {
    return value.toLowerCase().trim().replace(/[^A-Z0-9]/ig, "-");
  }

}
