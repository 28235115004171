import { DigitalCardComponent } from "./modules/digital-card/digital-card.component";
import { HelpSupportComponent } from "./modules/help-support/help-support.component";
import { DefaulterFormComponent } from "./modules/defaulter-form/defaulter-form.component";
import { FollowingsComponent } from "./modules/company/followings/followings.component";
import { FollowersComponent } from "./modules/company/followers/followers.component";
import { SearchComponent } from "./modules/search/search.component";
import { TermConditionComponent } from "./modules/term-condition/term-condition.component";
import { PrivacyPolicyComponent } from "./modules/privacy-policy/privacy-policy.component";
import { ProfileEditComponent } from "./modules/profile-edit/profile-edit.component";
import { ProfileComponent } from "./modules/profile/profile.component";
import { TransporterComponent } from "./modules/company/transporter/transporter.component";
import { ServiceProviderComponent } from "./modules/company/service-provider/service-provider.component";
import { SupplierComponent } from "./modules/company/supplier/supplier.component";
import { CompanyAboutComponent } from "./modules/company/company-about/company-about.component";
import { CompanyDetailsComponent } from "./modules/company/company-details/company-details.component";
import { FindReferenceComponent } from "./modules/find-reference/find-reference.component";
import { FindIdentityComponent } from "./modules/find-identity/find-identity.component";
import { DefaulterDetailsComponent } from "./modules/defaulter-details/defaulter-details.component";
import { DefaulterComponent } from "./modules/defaulter/defaulter.component";
import { TilesCalculatorComponent } from "./modules/tiles-calculator/tiles-calculator.component";
import { PackingDetailsComponent } from "./modules/packing-details/packing-details.component";
import { SpecificationComponent } from "./modules/specification/specification.component";
import { MyBuyPostComponent } from "./modules/my-buy-post/my-buy-post.component";
import { MySalePostComponent } from "./modules/my-sale-post/my-sale-post.component";
import { ChatComponent } from "./modules/chat/chat.component";
import { InboxComponent } from "./modules/inbox/inbox.component";
import { BuyerPostFormComponent } from "./modules/buyer-post-form/buyer-post-form.component";
import { SellerPostFormComponent } from "./modules/seller-post-form/seller-post-form.component";
import { CompanyPhotosComponent } from "./modules/company/company-photos/company-photos.component";
import { ProductGalleryComponent } from "./modules/company/product-gallery/product-gallery.component";
import { TilesManufactureListComponent } from "./modules/company/tiles-manufacture-list/tiles-manufacture-list.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BuyerPostDetailComponent } from "./modules/buyer/detail/buyer-post-detail.component";
import { BuyerPostsListComponent } from "./modules/buyer/list/buyer-posts-list.component";
import { NotificationComponent } from "./modules/common/notification/notification.component";
import { AdminComponent } from "./modules/company/admin/admin.component";
import { CabComponent } from "./modules/company/cab/cab.component";
import { CompanyDetailComponent } from "./modules/company/company-detail/company-detail.component";
import { DealerComponent } from "./modules/company/dealer/dealer.component";
import { HotelComponent } from "./modules/company/hotel/hotel.component";
import { RawMaterialComponent } from "./modules/company/raw-material/raw-material.component";
import { SanitaryComponent } from "./modules/company/sanitary/sanitary.component";
import { TradersComponent } from "./modules/company/traders/traders.component";
import { VariousComponent } from "./modules/company/various/various.component";
import { AboutusComponent } from "./modules/components/aboutus/aboutus.component";
import { AnalyticsComponent } from "./modules/components/analytics/analytics.component";
import { DirectoryComponent } from "./modules/components/directory/directory.component";
import { HomeComponent } from "./modules/components/home/home.component";
import { JobsListComponent } from "./modules/components/jobs/list/jobs-list.component";
import { PostComponent } from "./modules/components/post/post.component";
// import { PostComponent } from './modules/components/app-post.component';
import { SignupFormComponent } from "./modules/components/signup-form/signup-form.component";
import { SellerPostDetailComponent } from "./modules/seller/detail/seller-post-detail.component";
import { SellerPostsListComponent } from "./modules/seller/list/seller-posts-list.component";
import { BlogDetailComponent } from "./modules/blog-detail/blog-detail.component";

import { AuthGuard } from "./guard/auth.guard";
import { AccessRoleGuard } from "./guard/access-role.guard";
import { BlogComponent } from "./modules/blog/blog.component";
import { ForgotPasswordComponent } from "./modules/components/forgot-password/forgot-password.component";
import { VerifyOtpComponent } from "./modules/components/verify-otp/verify-otp.component";
import { ResetPasswordComponent } from "./modules/components/reset-password/reset-password.component";

import { ContactComponent } from "./modules/contact/contact.component";
import { MembershipComponent } from "./modules/membership/membership.component";

import { FeedbackComponent } from "./modules/feedback/feedback.component";

import { VariousMoreComponent } from "./modules/company/variousmore/variousmore.component";
import { PagesComponent } from "./modules/pages/pages.component";
import { NotFoundComponent } from "./modules/common/not-found/not-found.component";
import { CompanyListComponent } from "./modules/company/company-list/company-list.component";
import { ExhibitionComponent } from "./modules/exhibition/exhibition.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "verify-otp", component: VerifyOtpComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: "profile/edit",
    component: ProfileEditComponent,
    canActivate: [AuthGuard],
  },
  { path: "home", component: HomeComponent },
  { path: "register", component: SignupFormComponent },
  { path: "login", component: SignupFormComponent },
  { path: "contact-us", component: ContactComponent },
  {
    path: "defaulter/add",
    component: DefaulterFormComponent,
    canActivate: [AuthGuard, AccessRoleGuard],
    data: { access_type: "defaulter", access_key: "add" },
  },
  {
    path: "defaulter",
    component: DefaulterComponent,
  },
  {
    path: "notifications",
    component: NotificationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "analytics",
    component: AnalyticsComponent,
    canActivate: [AuthGuard, AccessRoleGuard],
    data: { access_type: "analytics" },
  },

  { path: "feedback", component: FeedbackComponent },
  { path: "post", component: PostComponent },
  {
    path: "help-support",
    component: HelpSupportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "digital-card",
    component: DigitalCardComponent,
    canActivate: [AuthGuard, AccessRoleGuard],
    data: { access_type: "digital_visiting_card" },
  },
  {
    path: "identity-find",
    component: FindIdentityComponent,
  },
  {
    path: "reference-find",
    component: FindReferenceComponent,
  },
  {
    path: "exhibition",
    component: ExhibitionComponent,
  },
  { path: "blogs", component: BlogComponent },
  { path: "directory", component: DirectoryComponent },
 

  {
    path: "defaulter/show",
    component: DefaulterDetailsComponent,
  },
  {
    path: "jobs/list",
    component: JobsListComponent,
  },
  {
    path: "tool/tiles-calculator",
    component: TilesCalculatorComponent,
  },
  { path: "tiles/:slug", component: CompanyListComponent },
  {
    path: "post/:id",
    component: PostComponent,
  },
  { path: "page/:slug", component: PagesComponent },
  { path: "company/:name/:id", component: CompanyDetailsComponent }, // important
  { path: "company/:name/:id/followers", component: FollowersComponent },
  { path: "company/:name/:id/followings", component: FollowingsComponent },
  { path: "company/:name/:id/gallery", component: ProductGalleryComponent },
  { path: "company/:name/:id/photos", component: CompanyPhotosComponent },
  { path: "company/:name/:id/about", component: CompanyAboutComponent },
  { path: "company/:name/:id/tiles", component: TilesManufactureListComponent },
  { path: "blogs/:id/:name", component: BlogDetailComponent },
  { path: "tiles/:slug/:category_slug", component: CompanyListComponent },
  {
    path: "manufacturers/:tilescategoryname/:tilescategoryname",
    component: TilesManufactureListComponent,
  },

  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
