import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-trader-dealer-category',
    templateUrl: './trader-dealer-category.component.html',
    styleUrls: ['./trader-dealer-category.component.css'],
    standalone: false
})
export class TraderDealerCategoryComponent implements OnInit {
	@Input('categories') categories = [];
	@Input('description') description = '';
	constructor() { }

	ngOnInit(): void {
		console.log(this.categories)
	}

}
