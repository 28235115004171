import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserService } from '../../../services/user.service';
import { DealerService } from '../../../services/dealer.service';
import { InquiryService } from '../../../services/inquiry.service';
import { AnalyticsService } from '../../../services/analytics.service';



import { TilesManufactureService } from "../../../services/tiles-manufacture.service";
import { SanitaryService } from "../../../services/sanitary.service";
import { VariousService } from "../../../services/various.service";
import { SupplierService } from "../../../services/supplier.service";
import { ServiceProviderService } from "../../../services/service-provider.service";
import { CabService } from "../../../services/cab.service";
import { TransporterService } from "../../../services/transporter.service";
import { HotelService } from "../../../services/hotel.service";
import { TraderService } from "../../../services/trader.service";
import { FollowService } from "../../../services/follow.service";

@Component({
    selector: 'app-dealer',
    templateUrl: './dealer.component.html',
    styleUrls: ['./dealer.component.css'],
    standalone: false
})
export class DealerComponent implements OnInit {

	premium: any = [];
	statndard: any = [];
	classic: any = [];
	general: any = [];

	type: number = 0;

	states: any = [];
	districts: any = [];

	selectedState: any = '';
	selectedDistrict: any = '';

	totalPages: number = 0;
	nextPage: number = 0;
	is_buttons_enable:boolean = false;
	is_buttons_enable_other:boolean = false;
	dealerIdList = [];

	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		autoplay: true,
		autoplayTimeout: 2000,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		autoplaySpeed: 1500,
		navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 1
			},
			740: {
				items: 1
			},
			940: {
				items: 1
			}
		},
		nav: true
	}
	constructor(
		private router: Router,
		private dealerService: DealerService,
		private userService: UserService,
		private inquiryService: InquiryService,
		private analyticsService: AnalyticsService,
		private tilesManufactureService: TilesManufactureService,
		private sanitaryManufactureService: SanitaryService,
		private variousManufactureService: VariousService,
		private supplierService: SupplierService,
		private serviceProviderService: ServiceProviderService,
		private cabService: CabService,
		private transporterService: TransporterService,
		private hotelService: HotelService,
		private traderService: TraderService,
		private followService: FollowService,
	) { }

	ngOnInit(): void {
		this.setAccessRole();
		this.userService.accessRoleListener.subscribe((response) => {
			this.setAccessRole();
		});
		this.dealerService.getList({list:[]}).subscribe(response => {
			this.repsonse(response);
		})
	}
	setAccessRole():void{
		let temp_access_role = this.userService.getAccessRole();
		if(temp_access_role?.is_access_role_available == '1'){
			temp_access_role = JSON.parse(temp_access_role.access_role);
			this.is_buttons_enable = temp_access_role?.dealer_india ? true : false;
			this.is_buttons_enable_other = temp_access_role?.dealer_other ? true : false;
		}
	}
	repsonse(response, flag: boolean = true): void {
		if (response.success) {

			
			this.premium = response.data.premium;
			this.statndard = response.data.statndard;
			this.classic = response.data.classic;
			this.general = response.data.general;


			if (flag) {
			//	this.states = response.extra.states;
			if( this.userService.getUser().company_id) {
				//console.log(this.userService.getUser())


			this.type = this.userService.getUser().company_type;

		if(this.type == 1){
			this.tilesManufactureService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 2){
			this.sanitaryManufactureService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 3){
			this.variousManufactureService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 4){
			this.traderService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 5){
			this.dealerService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 6){
			this.transporterService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 7){
			this.supplierService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 8){
			this.hotelService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 9){
			this.serviceProviderService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}else if(this.type == 10){
			this.cabService.getDetails({ comapny_id: this.userService.getUser().company_id }).subscribe(res => {
				if(res.data.package==0){
					this.states = response.extra.statesindia;
					
				} else 
				{ this.states = response.extra.states; }
			})
		}



			

			} else {
				this.states = response.extra.statesindia;
			}
				
				//console.log(this.states);
			}
			this.totalPages = response.extra.total_pages;
			this.nextPage = response.extra.next_page;
		} else {
			this.userService.errorMessage(response.message);
		}
	}
	onChangeState(id): void {
		this.selectedState = id;
		if(id != ''){
			const product = this.states.find((p) => {
				return p.id == id;
			});
			this.districts = product.districts;
		}else{
			this.districts = [];
			this.selectedDistrict = '';
		}
	}
	onChangeDistrict(id): void {
		this.selectedDistrict = id;
	}
	filterOption(): void {
		let param = {};
		if (this.selectedState != '') {
			param = { ...param, ...{ state_id: this.selectedState } }
		}
		if (this.selectedDistrict != '') {
			param = { ...param, ...{ district_id: this.selectedDistrict } }
		}
		param = {...param,list:[]};
		this.dealerService.getList(param).subscribe(response => {
			this.repsonse(response, false);
		})
	}
	moreCompanies(): void {
		let param = { next_page: this.nextPage };
		if (this.selectedState != '') {
			param = { ...param, ...{ state_id: this.selectedState } }
		}
		if (this.selectedDistrict != '') {
			param = { ...param, ...{ district_id: this.selectedDistrict } }
		}
		this.dealerIdList = [];
		this.general.forEach(element=>{
			this.dealerIdList.push(element.id);
		});
		param = {...param, ...{ list:this.dealerIdList}};
		this.dealerService.getMore(param).subscribe(response => {
			if (response.success) {
				this.totalPages = response.extra.total_pages;
				this.nextPage = response.extra.next_page;
				response.data.forEach(element => {
					this.general.push(element)
				});
			} else {
				this.userService.errorMessage(response.message);
			}
		})
	}
	Inquiry(id):void{
		if(this.is_buttons_enable){
			let user = this.userService.getUser();
			let company_id = user.company_id ? user.company_id : 0; 
			this.inquiryService.save({from_company_id: company_id, to_company_id: id}).subscribe(res => {
				if(res.success){
					this.userService.sucessMessage(res.message);
				}else{
					this.userService.errorMessage(res.message);
				}
			})
		}
	}
	analytics(id, type):void{
		this.analyticsService.saveCount({id: id, type : type}).subscribe();
	}
}
