<main>
  <section class="design-area" id="service">
    <div class="container">
      <div class="row justify-content-center">
        <div
          [ngClass]="
            isLogin
              ? 'col-xl-5 col-md-5 col-sm-12 col-xs-12'
              : 'col-xl-7 col-md-7 col-sm-7 col-xs-12'
          "
        >
          <div class="form-box login_main_form_ban">
            <form
              [formGroup]="loginForm"
              (ngSubmit)="onSubmit()"
              *ngIf="isLogin"
              class="login_main_form_regis_ban"
            >
              <h2>{{ isLogin ? "Login" : "Register" }}</h2>
              <div class="login_main_form_div">
                <input
                  type="text"
                  name=""
                  formControlName="email"
                  placeholder="Mobile"
                />
                <label
                  [ngClass]="{
                    'is-invalid':
                      loginForm.get('email').touched &&
                      loginForm.get('email').invalid
                  }"
                  class="invalid-feedback font-medium"
                  >Please enter email</label
                >
              </div>
              <div class="login_main_form_div">
                <input
                  type="password"
                  name=""
                  formControlName="password"
                  placeholder="Password"
                />
                <label
                  [ngClass]="{
                    'is-invalid':
                      loginForm.get('password').touched &&
                      loginForm.get('password').invalid
                  }"
                  class="invalid-feedback font-medium"
                  >Please enter password</label
                >
              </div>
              <div class="text-left login_main_form_div">
                <a
                  type="button"
                  routerLink="/forgot-password"
                  title="Forgot Password"
                  >Forgot password</a
                >
              </div>
              <div class="text-center login_main_form_button">
                <input type="submit" name="" value="Login" class="btn_submit" />
              </div>

              <div class="text-left login_main_form_reg">
                <p class="">
                  Not a member?
                  <a type="button" routerLink="/register" title=""
                    >Register Now</a
                  >
                </p>
              </div>
            </form>

            <form
              [formGroup]="registerForm"
              (ngSubmit)="onSubmitRegister()"
              *ngIf="!isLogin"
              class="login_main_form_regis_ban"
            >
              <select formControlName="role">
                <option value="">Select Roll</option>
                <option *ngFor="let role of roles" [value]="role?.id">
                  {{ role?.role }}
                </option>
              </select>
              <label
                [ngClass]="{
                  'is-invalid':
                    registerForm.get('role').touched &&
                    registerForm.get('role').invalid
                }"
                class="invalid-feedback font-medium"
                >Please select role</label
              >

              <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <input
                    type="text"
                    formControlName="firm_name"
                    name="firm_name"
                    placeholder="Firm Name"
                  />
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('firm_name').touched &&
                        registerForm.get('firm_name').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please enter firm name</label
                  >
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <input
                    type="tel"
                    formControlName="whats_app"
                    name="whats_app"
                    placeholder="Whatsapp No."
                  />
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('whats_app').touched &&
                        registerForm.get('whats_app').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please enter whatsapp no.</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <input
                    type="tel"
                    formControlName="mobile"
                    name=""
                    placeholder="Cell No."
                  />
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('mobile').touched &&
                        registerForm.get('mobile').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please enter cell no.</label
                  >
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <input
                    type="email"
                    formControlName="email"
                    name=""
                    placeholder="Email"
                  />
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('email').touched &&
                        registerForm.get('email').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please enter email</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <select
                    formControlName="country"
                    (change)="onChangeCountry($event.target.value)"
                  >
                    <option value="" disabled hidden>Select Country</option>
                    <option
                      *ngFor="let country of countries"
                      [value]="country?.id"
                    >
                      {{ country?.country }}
                    </option>
                  </select>
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('country').touched &&
                        registerForm.get('country').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please select country</label
                  >
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <select
                    formControlName="state"
                    (change)="onChangeState($event.target.value)"
                  >
                    <option value="" disabled hidden>Select State</option>
                    <option *ngFor="let state of states" [value]="state?.id">
                      {{ state?.state }}
                    </option>
                  </select>
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('state').touched &&
                        registerForm.get('state').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please select state</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <select formControlName="district">
                    <option value="" disabled hidden>Select District</option>
                    <option
                      *ngFor="let district of districts"
                      [value]="district?.id"
                    >
                      {{ district?.district }}
                    </option>
                  </select>
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('district').touched &&
                        registerForm.get('district').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please select district</label
                  >
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <input
                    type="text"
                    formControlName="city"
                    name=""
                    placeholder="City Name"
                  />
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('city').touched &&
                        registerForm.get('city').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please enter city</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="file"
                    accept="image/*"
                    name=""
                    placeholder="Upload Visiting Card"
                    #card
                    id="post"
                    (change)="onSelectImage(registerForm, card.files, 'file')"
                  />
                  <label
                    [ngClass]="{
                      'is-invalid':
                        registerForm.get('file').touched &&
                        registerForm.get('file').invalid
                    }"
                    class="invalid-feedback font-medium"
                    >Please upload visting card</label
                  >
                </div>
              </div>
              <div style="text-align: center">
                <input
                  type="submit"
                  name=""
                  value="Submit"
                  class="btn_submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="otp-section" *ngIf="verifyOtp">
    <i
      class="fa fa-times close-otp"
      (click)="closeOtpWindow()"
      aria-hidden="true"
    ></i>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="otp-div form-box text-center">
            <h2 class="text-center">OTP</h2>
            <hr />
            <h4 class="text-center">
              Enter OTP Sended to your register email id
            </h4>
            <div class="passcode-wrapper mt-4">
              <input
                id="codeBox1"
                [(ngModel)]="otpIndex1"
                type="number"
                maxlength="1"
                onkeyup="onKeyUpEvent(1, event)"
                onfocus="onFocusEvent(1)"
              />
              <input
                id="codeBox2"
                [(ngModel)]="otpIndex2"
                type="number"
                maxlength="1"
                onkeyup="onKeyUpEvent(2, event)"
                onfocus="onFocusEvent(2)"
              />
              <input
                id="codeBox3"
                [(ngModel)]="otpIndex3"
                type="number"
                maxlength="1"
                onkeyup="onKeyUpEvent(3, event)"
                onfocus="onFocusEvent(3)"
              />
              <input
                id="codeBox4"
                [(ngModel)]="otpIndex4"
                type="number"
                maxlength="1"
                onkeyup="onKeyUpEvent(4, event)"
                onfocus="onFocusEvent(4)"
              />
            </div>
            <input
              type="submit"
              (click)="onClickverifyOtp()"
              class="mt-5"
              name=""
              value="Verify"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
