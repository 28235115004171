<section class="trending-brand mb-4 mb-md-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="trending-categories-sub ">
                <div class="trending-categories rounded-lg py-3 py-md-4 px-2 px-md-5">
                        <h3 class="text-center mb-4">Trending Brand</h3>
                        <!-- <div class="row"> -->
                            <!-- <div class="col-5" *ngFor="let brand of brands">
                                <div class="trending trending_brand_bann">
                                    <div class=" qrax d-flex align-items-center justify-content-center">
                                        <img src="{{brand}}" class="img-fluid">
                                    </div>
                                </div>
                            </div> -->
                            <div class="live__scroll">
                                <div class="row text-center">
                                    <div class="col-5 d-inline-block" (click)="onClick(brand)" *ngFor="let brand of brands">
                                        <div class="trending trending_brand_bann">
                                            <div class=" qrax d-flex align-items-center justify-content-center">
                                                <img src="{{brand.company.logo}}" class="img-fluid">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    