<section class="design-area">
  <div class="container">
    <!-- Button trigger modal -->

    <form [formGroup]="filterForm" (submit)="filterChange()" class="select-box post_sec_filter">
      <select formControlName="post_type" (change)="filterChange()">
        <option value="" [selected]="true">Filter By Post Type</option>
        <option *ngFor="let type of postTypes" [value]="type.id.toString()">
          {{ type.type }}
        </option>
      </select>
      <select class="ms-3" formControlName="product_id" (change)="filterChange()">
        <option value="" [selected]="true">Filter By Product Type</option>
        <option *ngFor="let product of Products" [value]="product.id.toString()">
          {{ product.product }}
        </option>
      </select>
      <!-- <select class="ml-3">
                <option value="">Filter by Product</option>
                <option value="">Wall Tiles</option>
                <option value="">Floor Tiles</option>
                <option value="">Slab Tiles</option>
            </select> -->
      <!-- <label class="invalid-feedback font-medium">Please select size</label> -->
    </form>
  </div>
  <!-- <a href="whatsapp://send?text=https://www.youtube.com/watch?v=v7dh8KMaxtw">Share this</a> -->
  <div class="post_sec_bann mt-5" id="posts-wrapper">
    <!-- (mouseenter)="activePost(item)" -->
    <div class="post_sec_scroll" [id]="item.slug" *ngFor="let item of PostDetails">
      <div class="post_sec_scroll_div" [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }">
        <div class="post_sec_head">
          <h5>Post</h5>
          <button type="button" class="post_sec_head_model" data-toggle="modal" data-target="#add_post">
            <img src="assets/img/reels_camera.png" alt="" class="img-fluid" />
          </button>
          <!-- <label><input type="file" style="display: none"><img src="assets/img/reels_camera.png" alt="" class="img-fluid"></label> -->
        </div>
        <!-- <video  autoplay><source src="{{post_url}}" type="video/mp4"></video> -->
        <!-- <img src="{{post_url}}" alt=""> -->
        <div class="post_sec_foot">
          <div class="post_sec_foot_title">
            <div class="post_sec_foot_sec">
              <img src="{{ item.company.logo }}" alt="" class="img-fluid" />
              <div class="post_sec_foot_sec_tit">
                <h4>{{ item.company.name }}</h4>
                <h5>{{ item.company.brand ?? item.company.role }}</h5>
              </div>
              <button type="button" value="">Follow</button>
            </div>
            <div class="post_sec_foot_sec_text">
              <p>{{ item.description }}</p>
            </div>
          </div>
          <div class="poast_sec_foot_icons">
            <button class="" type="button">
              <img src="assets/img/eye.png" alt="" class="img-fluid" /><span>{{
                item.views
                }}</span>
            </button>
            <button class="" type="button" (click)="manageLike(item)">
              <img src="{{
                  item.is_liked
                    ? 'assets/img/post_heart1.png'
                    : 'assets/img/post_heart.png'
                }}" alt="" class="img-fluid" />
              <span>{{ item.likes }}</span>
            </button>
            <button class="poast_sec_foot_icons_button" type="button" data-toggle="modal" data-target="#share_post">
              <img src="assets/img/post_share.png" alt="" class="img-fluid" />{{
              item.shares
              }}
            </button>
            <!-- <button class="" type="button"><img src="assets/img/post_whatsapp.png" alt="" class="img-fluid"></button> -->
            <p class="text-center">
              <a (click)="manageWhatsapp(item)" href="https://api.whatsapp.com/send?text={{
                  getPostUrl(item.slug)
                }}&preview=true" data-action="share/whatsapp/share" target="_blank"><img
                  src="assets/img/post_whatsapp.png" alt="" class="img-fluid" /></a>
            </p>
            <!-- <button class="" type="button"><img src="assets/img/post_menu.png" alt="" class="img-fluid"></button>     -->
            <!-- <button type="button"><img src="assets/img/post_menu.png" alt="" class="img-fluid"></button> -->

            <!-- Modal -->
          </div>
        </div>
      </div>
      <div class="modal fade" id="share_post" tabindex="-1" role="dialog" aria-labelledby="Add_post_title"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title header_title_text" id="exampleModalLongTitle">
                Share To....
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <ul class="social_model_form_banner">
                <li>
                  <a (click)="manageShare(item)" href="https://www.facebook.com/share.php?u={{
                      getPostUrl(item.slug)
                    }}" target="_blank"><i class="fa fa-facebook"></i> facebook</a>
                </li>
                <li>
                  <a (click)="manageShare(item)" href="https://www.linkedin.com/sharing/share-offsite/?url={{
                      getPostUrl(item.slug)
                    }}" target="_blank">
                    <!-- https://www.linkedin.com/shareArticle?mini=true&url -->
                    <i class="fa fa-linkedin"></i> LinkedIn</a>
                </li>
                <li>
                  <a (click)="manageShare(item)" href="http://x.com/share?url={{ getPostUrl(item.slug) }}"
                    target="_blank">
                    <i class="fa fa-twitter"></i> Twitter</a>
                </li>
                <li>
                  <button (click)="copyText(getPostUrl(item.slug))" class="btn p-0 text-left" href="https://www.instagram.com/?url={{
                      getPostUrl(item.slug)
                    }}">
                    <i [class]="'fa ' + isCopied"></i>Copy To Clipboard
                  </button>
                </li>
                <!-- <li><a href="https://www.youtube.com/share.php?u={{post_url}}" target="_blank"><i
                                            class="fa fa-youtube"></i> Youtube</a></li> -->
                <!-- <li> <button type="button" (click)="socialShare()">Others</button></li> -->

                <!-- <li><a href="https://www.youtube.com/share.php?u=https://www.gujarattilesinfo.com/" target="_blank"><i class="fa fa-envelope-o" aria-hidden="true"></i> Email</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="add_post" tabindex="-1" role="dialog" aria-labelledby="Add_post_title" aria-hidden="true">
    <form [formGroup]="addPostForm" (ngSubmit)="onSubmit()"
      class="modal-dialog modal-dialog-centered add_post_model_form_banner" role="document">
      <div class="modal-content add_post_model_banner">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            Add Business Post
          </h5>
          <button (click)="initAddPostForm()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="add_post_form_ban">
            <div class="form-group add_post_first_ban justify-content-center">
              <input *ngIf="!addPostForm.value.post_url" accept="image/*" type="file"
                class="form-control-file border-0 my-auto w-auto" #post id="post"
                (change)="onSelectImage(addPostForm, post.files, 'post')" [ngClass]="{
                  'is-invalid': submited && addPostForm.controls.post.errors
                }" />
              <div class="" *ngIf="submited && addPostForm.controls.post.errors">
                <p *ngIf="submited && addPostForm.controls.post.errors.required">
                  Please Select image
                </p>
              </div>
              <img [src]="addPostForm.value.post_url" *ngIf="addPostForm.value.post_url" width="70px" height="70px" />
              <!-- <label style="color: #fff;" *ngIf="post && post.invalid && post.touched">Select File</label> -->
            </div>
            <div class="form-group mx-2 my-4">
              <textarea class="form-control" id="description" rows="3" placeholder="Description"
                formControlName="description" [ngClass]="{
                  'is-invalid':
                    submited && addPostForm.controls.description.errors
                }"></textarea>
              <div class="" *ngIf="submited && addPostForm.controls.description.errors">
                <p *ngIf="
                    submited && addPostForm.controls.description.errors.required
                  ">
                  Please Add Description
                </p>
              </div>
              <!-- <label style="color: #fff;" *ngIf="description && description.invalid && description.touched">Please Fill Description</label> -->
            </div>
            <div class="">
              <div class="form-group mx-2 my-4">
                <!-- <label for="">Product</label> -->
                <div class="select-box">
                  <select id="post_type" formControlName="post_type" [ngClass]="{
                      'is-invalid':
                        submited && addPostForm.controls.post_type.errors
                    }">
                    <option value="" [selected]="true">Select Post Type</option>
                    <option *ngFor="let type of postTypes" [value]="type.id">
                      {{ type.type }}
                    </option>
                  </select>
                  <div class="" *ngIf="submited && addPostForm.controls.post_type.errors">
                    <p *ngIf="
                        submited &&
                        addPostForm.controls.post_type.errors.required
                      ">
                      Select post type
                    </p>
                  </div>
                </div>
                <div class="select-box">
                  <select id="product_id" formControlName="product_id" (change)="onProductChange($event)" [ngClass]="{
                      'is-invalid':
                        submited && addPostForm.controls.product.errors
                    }">
                    <option value="" [selected]="true">
                      Select Product Type
                    </option>
                    <option *ngFor="let product of Products" [value]="product.id">
                      {{ product.product }}
                    </option>
                  </select>
                  <div class="" *ngIf="submited && addPostForm.controls.product.errors">
                    <p *ngIf="
                        submited && addPostForm.controls.product.errors.required
                      ">
                      Select Prodduct Type
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="initAddPostForm()" class="btn" data-dismiss="modal">
            Close
          </button>
          <!-- <button type="submit" class="btn btn-primary">Post</button> -->
          <button type="submit" class="btn">Add Post</button>
        </div>
      </div>
    </form>
  </div>
</section>