<header class="header-area" id="header-sticky">
  <!-- <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Navbar</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown link
            </a>
            <ul class="dropdown-menu" >
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->

  <!-- <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Navbar</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" aria-disabled="true">Disabled</a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->



  <nav class="navbar navbar-expand-lg site-logo-bann p-0">
    <div class="container">
      <a class="navbar-brand" routerLink="home"> <img src="assets/img/logo.png" /></a>
      <button *ngIf="isLoggedIn" class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="menu_container">
        <div class="btn_container" (click)="showSidebar()">
          <label *ngIf="newNotification" class="notification-dot-header"></label>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="over_lay"></div>
        <app-sidebar></app-sidebar>
      </div>
      <div class="collapse navbar-collapse smart-main-menu" id="navbarNav">
        <ul id="menu" class="navbar-nav" style="margin-bottom: 0">
          <li class="nav-item"><a class="nav-link active" aria-current="page" routerLink="home">Home</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="directory">Directory</a></li>
          <li class="nav-item"><a class="nav-link" routerLink="post">Business Post</a></li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Tools
            </a>

            <ul class="nav-item dropdown head_menu_drop_li pl-0" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" routerLink="page/specification">Specification</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="page/packing-details-domestic">Packing Details (Domestic)</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="page/packing-details-export">Packing Details (Export)</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="tool/tiles-calculator">Tiles Calculator</a>
              </li>
            </ul>
          </li>
          <li class="nav-item"><a class="nav-link" routerLink="exhibition">Exhibition</a></li>
        </ul>
      </div>
      <div class="head_nav_serch_w">
        <div class="head_drop_serc_ban {{
            search.value || isBoxOpen ? 'head_drop_serc_ban-active' : ''
          }} {{
            isBoxOpen && companies.length ? 'head_drop_serc_ban-auto' : ''
          }}">
          <input type="text" placeholder="Search Company......" [formControl]="search" (input)="onSearchCompany()"
            (focus)="openBox()" />
          <div *ngIf="!isLoading" class="head_drop_serc_oncli_ban" (click)="clearSearch()">
            <i class="fa fa-{{
                isBoxOpen && companies.length ? 'times' : 'search'
              }}" aria-hidden="true"></i>
          </div>
          <div *ngIf="isLoading" class="head_drop_serc_oncli_ban" style="cursor: progress">
            <div class="spinner-border" style="height: 1.5rem; width: 1.5rem" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div *ngIf="isBoxOpen && companies.length" class="d-flex flex-column list-container">
          <div class="list-container-wapper">
            <div *ngFor="let company of companies">
              <p (click)="onCompanyClick(company)" class="">
                {{ company.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="df-head-menu-banner">
        <div class="top-right" *ngIf="!user?.id">
          <a routerLink="login">SIGN IN</a>
          <a routerLink="register">REGISTER</a>
        </div>
        <div class="top-right-loggedin" *ngIf="user?.id">
          <img src="{{ user?.profile }}" alt="user" class="user-image-header" />
          <a>{{ user?.name }}</a>
          <ul id="submenu" class="ps-0">
            <li><a routerLink="profile">My Profile</a></li>
            <li><a routerLink="inbox">Inquiry <span class="sidebar-count" *ngIf="inboxCount">{{inboxCount}}</span> </a>
            </li>
            <li><a routerLink="notifications">Notification <span class="sidebar-count"
                  *ngIf="notificationCount">{{notificationCount}}</span></a></li>
            <li><a routerLink="analytics">Analytics</a></li>
            <li><a routerLink="digital-card">Digital Visiting Card</a></li>
            <li><a routerLink="/help-support"> Help &amp; Support </a></li>
            <li (click)="logout()"><a> Logout </a></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>