import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiConstants } from '../constants/api-constants';
import { APIResponse } from '../Modal/apiresponse';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }
  
  getCompanyDetail(params={},headers={}): Observable<APIResponse> {
    return this.http.get(apiConstants.company.detail,{'params':params, 'headers':headers}).pipe(catchError(this.handleError));
  }
  searchCompanies(params={}): Observable<APIResponse> {
    return this.http.get(apiConstants.company.search,{
      'params':params,
      'headers':{
        'X-Skip-Interceptor' : 'true',
        'X-Skip-Response' : 'true',
      } 
    }).pipe(catchError(this.handleError));
  }


  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
