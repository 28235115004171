<main>
	<!-- <app-breadcrumb [pageName]="'JOB LIST VIEW'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Job List</h4>
						<h1>Job Junction View</h1>
						<!-- <img src="assets/img/line.svg" width="120"> -->
					</div>
				</div>
			</div>

			<div class="tab job_list_bann">
				<button class="tablinks" [ngClass]="{'active' : first}" (click)="onTabChange('company')">Company Job
					List</button>
				<button class="tablinks" [ngClass]="{'active' : second}" (click)="onTabChange('candidate')">Candidate
					List</button>
				<button *ngIf="isLoggedIn" class="tablinks" [ngClass]="{'active' : third}" (click)="onTabChange('postform')">Add
					Post</button>
			</div>

			<div id="company" class="tabcontent" style="display: block;" *ngIf="currentTab == 'company'">
				<div class="row justify-content-center">
					<div class="col-xl-10">
						<ul class="single-job  job_list_mai_bann job_list_mai_ban_line" *ngFor="let job of jobList">
							<li>
								<img [src]="job.company.logo" alt="Job Image" class="job-image-job-junction"  />								
							</li>
							<li class="all-data job_list_mai_dec_bann">
								<div class="author-det ">
									<h4>{{job.job_title}}</h4>
									<h5>{{job.company.name}}</h5>
									<p>{{job.education}}, {{job.experience}} Experience</p>									
									<!-- <p>{{job.description}}</p> -->
									<!-- <div class="job_list_mai_dec_bann_2">
										<p>Mobile: <span>{{job.mobile}}</span></p>
										<p>City: <span>{{job.city}}</span></p>	
									</div> -->
								</div>
								
							</li>
							<div class="job_list_mai_btnn_bann job_list_mai_btnn_bann1">								
								<a (click)="showContact(job.mobile)"><button class="view-btn view-new ">Apply Now</button></a>
								<!-- <a (click)="showContact(job.mobile)"><button class="view-btn view-new">Apply Now <i class="fa fa-hand-pointer-o"></i></button></a> -->
							</div>
							<div class="job_list_mai_btnn_bann job_list_mai_btnn_bann2">								
								<a (click)="showContact(job.mobile)"><button class="view-btn view-new "><i class="fa fa-whatsapp" aria-hidden="true"></i>
								</button></a>
								<!-- <a (click)="showContact(job.mobile)"><button class="view-btn view-new">Apply Now <i class="fa fa-hand-pointer-o"></i></button></a> -->
							</div>
							
						</ul>
					</div>
					<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="jobNextPage < jobTotalpage">
						<a class="view-btn view-new comp-info-more-btn" style="cursor: pointer;" (click)="onGetJobsViewMore()"> View More</a>
					</div>
				</div>
			</div>
			<div id="candidate" class="tabcontent" style="display: block;" *ngIf="currentTab == 'candidate'">
				<div class="row justify-content-center">
					<div class="col-xl-10">
						<ul class="single-job job_list_mai_bann" *ngFor="let candidate of candidateList">
							<li>
								<img alt="" class="job-image-job-junction" [src]="candidate.image">
							</li>
							<li class="all-data">
								<div class="author-det">
									<h4>{{candidate.job_title}}</h4>
									<h5>{{candidate.name}}</h5>
									<!-- <h6></h6> -->
									<h6>{{candidate.dob}}</h6>
									<p>{{candidate.education}}, {{candidate.experience}} Experience </p>
								</div>
							</li>
							<li class="jobsesc-job-junction">

							</li>
							<li class="text-right job_list_mai_wsp_btn_main" >
								<!-- <a href="tel:{{candidate.mobile}}" class="view-btn view-new">{{candidate.mobile}}</a> -->
								<a href="tel:{{candidate.mobile}}" class="view-btn view-new job_list_mai_wsp_btn"><img _ngcontent-ylw-c67="" src="assets/img/whatsapp.png" width="30"></a>
								<a href="{{candidate.resume}}" target="_blank" download class="view-btn view-new job_list_mai_resm_btn">Resume</a>
							</li>
						</ul>
					</div>
					<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center"
						*ngIf="candidateNextPage < candidateTotalpage">
						<a class="view-btn view-new" style="cursor: pointer;" (click)="onCandidatesViewMore()"> View More</a>
					</div>
				</div>
			</div>
			<div id="candidate" class="tabcontent" style="display: block;" *ngIf="currentTab == 'postform'">
				<div class="row justify-content-center">
					<div class="col-md-10">
						<div class="form-box job_list_mai_form_box">
							<div class="row my-3 text-center">
								<div class="col-md-6 job_list_mai_form_inpt">
									<input type="radio" class="" name="type" value="1" (change)="typeChange('1')"
										checked>Company Job
								</div>
								<div class="col-md-6 job_list_mai_form_inpt">
									<input type="radio"class="" name="type" value="2" (change)="typeChange('2')">
									Candidate
								</div>
							</div>
							<form [formGroup]="companyForm" (ngSubmit)="onSubmitCompany()" *ngIf="formType == 1">
								<div class="row mt-5">
									<div class="col-md-12 mt-3 ">
										<!-- <label for="image" class="label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company logo</label> -->
										<input type="file" name="image" id="image" class="mt-0 file-input" accept="image/*" #image (change)="onSelectImage(image.files)" />
										<label
											[ngClass]="{'is-invalid':companyForm.get('image').touched && companyForm.get('image').invalid}"
											class="invalid-feedback font-medium">Please select image</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="company_name" formControlName="company_name"
											placeholder="Company Name">
										<label
											[ngClass]="{'is-invalid':companyForm.get('company_name').touched && companyForm.get('company_name').invalid}"
											class="invalid-feedback font-medium">Please enter company name</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="city" formControlName="city" placeholder="City">
										<label
											[ngClass]="{'is-invalid':companyForm.get('city').touched && companyForm.get('city').invalid}"
											class="invalid-feedback font-medium">Please enter city name</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="mobile" formControlName="mobile" placeholder="Cell no">
										<label
											[ngClass]="{'is-invalid':companyForm.get('mobile').touched && companyForm.get('mobile').invalid}"
											class="invalid-feedback font-medium">Please enter cell no.</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="job_title" formControlName="job_title"
											placeholder="Job Title">
										<label
											[ngClass]="{'is-invalid':companyForm.get('job_title').touched && companyForm.get('job_title').invalid}"
											class="invalid-feedback font-medium">Please enter job title</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="education" formControlName="education"
											placeholder="Education">
										<label
											[ngClass]="{'is-invalid':companyForm.get('education').touched && companyForm.get('education').invalid}"
											class="invalid-feedback font-medium">Please enter education</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="experience" formControlName="experience"
											placeholder="Experience">
										<label
											[ngClass]="{'is-invalid':companyForm.get('experience').touched && companyForm.get('experience').invalid}"
											class="invalid-feedback font-medium">Please enter experience</label>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
										<textarea type="text" name="description" formControlName="description"
											placeholder="Description"></textarea>
										<label
											[ngClass]="{'is-invalid':companyForm.get('description').touched && companyForm.get('description').invalid}"
											class="invalid-feedback font-medium">Please enter description</label>
									</div>
								</div>
								<div style="text-align: center;">
									<input type="submit" name="" value="Submit" class="job_list_mai_form_button">
								</div>
							</form>
							<form [formGroup]="candidateForm" (ngSubmit)="onSubmitCandidate()" *ngIf="formType == 2">
								<div class="row">
									<div class="col-md-12 mt-3">
										<label for="image" class="label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Image</label>
										<input type="file" name="image" id="image" class="mt-0 file-input" accept="image/*" #image (change)="onSelectImage(image.files)" />
										<label
											[ngClass]="{'is-invalid':candidateForm.get('image').touched && candidateForm.get('image').invalid}"
											class="invalid-feedback font-medium">Please select image</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="name" formControlName="name"
											placeholder="Full Name">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('name').touched && candidateForm.get('name').invalid}"
											class="invalid-feedback font-medium">Please enter fullname</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="city" formControlName="city" placeholder="City">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('city').touched && candidateForm.get('city').invalid}"
											class="invalid-feedback font-medium">Please enter city name</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="mobile" formControlName="mobile" placeholder="Cell no">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('mobile').touched && candidateForm.get('mobile').invalid}"
											class="invalid-feedback font-medium">Please enter cell no.</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="date" name="dob" formControlName="dob"
											placeholder="Date of birth">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('dob').touched && candidateForm.get('dob').invalid}"
											class="invalid-feedback font-medium">Please select date of birth</label>
									</div>
								</div>
								<div class="row ">
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="education" formControlName="education"
											placeholder="Education">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('education').touched && candidateForm.get('education').invalid}"
											class="invalid-feedback font-medium">Please enter education</label>
									</div>
									<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
										<input type="text" name="experience" formControlName="experience"
											placeholder="Experience">
										<label
											[ngClass]="{'is-invalid':candidateForm.get('experience').touched && candidateForm.get('experience').invalid}"
											class="invalid-feedback font-medium">Please enter experience</label>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 mt-3">
										<label for="image" class="label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resume</label>
										<input type="file" name="image" id="image" class="mt-0 file-input" #resume (change)="onSelectResume(resume.files)" />
										<label
											[ngClass]="{'is-invalid':candidateForm.get('resume').touched && candidateForm.get('resume').invalid}"
											class="invalid-feedback font-medium">Please select resume</label>
									</div>
								</div>
								<div style="text-align: center;">
									<input type="submit" name="" class="job_list_mai_form_button" value="Submit">
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>