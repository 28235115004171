<style type="text/css">
    .digital-cards .theme-bg {
        background: #548761;
    }

    .digital-cards .theme-color {
        color: #548761;
    }

    .digital-cards .page-container {
        background: transparent;
        margin: 0 auto;
        padding: 0px;
        position: relative;
    }

    .digital-cards .header {
        height: 100%;
        padding: 0;
    }

    .digital-cards .company-section {
        height: 200px;
        width: 100%;
        border-radius: 30px;
        text-align: center;
        padding-top: 26px;
    }

    .digital-cards .company-name {
        font-size: 20px;
        color: #363B3C;
        text-transform: uppercase;
        font-weight: 500;
    }

    .digital-cards .profile {
        background: #fff;
        text-align: center;
        width: 130px;
        height: 130px;
        border-radius: 100px;
        margin: 0 auto;
        margin-top: -20px;
        border: 5px solid #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: 170px;
        z-index: 1;
    }

    .digital-cards .contact-info p {
        border-radius: 7px;
        padding: 10px;
        display: inline-block;
        font-size: 14px;
        color: #3A4D5E;
        font-weight: 600;

    }

    .digital-cards .contact-info p span {
        padding: 11px;
        height: 55px;
        width: 55px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50px;
        background: #3A4D5E;
        box-shadow: 0px 0px 10px 0px rgba(130, 180, 181, 0.63);
        vertical-align: middle;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .digital-cards .contact-info p span i {
        margin-right: 5px;
    }

    .digital-cards .address-section {
        background: #353b37;
        padding: 0px 15px;
    }

    .digital-cards .address-section-inner p {
        background: transparent;
        font-weight: 600;
        text-align: center;
        color: #282828;
        margin-bottom: 2px;
        font-size: 16px;
    }

    .name_tit_bann_item_sub {
        color: #6A849C !important;
        font-size: 14px !important;
        padding: 0rem 2rem;
    }

    .name_tit_text {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: #282828;
        text-transform: uppercase;
        margin-bottom: 2px;
    }

    .name_tit_text_dec {
        color: #6A849C;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
    }

    .name_tit_bann_item {
        border-radius: 30px;
        padding: 5px;
        margin-top: 20px;
    }

    .name_tit_text_button[type="submit"] {
        font-size: 14px;
        color: #fff;
        border-radius: 50px;
        background: linear-gradient(180deg, #57748D 0%, #3A4D5E 100%);
        box-shadow: 0px 2px 5px 0px #B0B2BD;
        margin-top: 1rem;
    }

    .contact-info h5 {
        color: #6A849C;
        font-size: 14px;
        padding-top: 10px;
    }

    .contact-info-soc {
        border-top: solid 1px #88BBC9;
        margin: 0px 15px;
    }

    .contact-info-soc a {
        color: #9BDCE4;
    }

    .digital-cards .contact-info-soc p {
        padding-bottom: 5px;
    }

    .contact-info-soc a i {
        margin-right: 0px !important;
    }

    .cont_info_soc_link input {
        margin-left: auto;
        margin-right: auto;
        display: grid;
        margin-bottom: 1.5rem;
        border-radius: 2px;
        background: #3A4D5E;
        box-shadow: 0px 1px 6px 0px #92BABB;
        padding: 8px 25px;
    }

    .gti_digi_share_ban p {
        font-weight: 600;
        color: #6A849C;
        margin-bottom: 5px;
        margin-top: 2rem;
    }

    .gti_digi_share_ban ul li {
        display: inline-block;
    }

    .gti_digi_share_ban ul li a {
        background-color: #3a4d5e;
        margin: 10px;
        font-size: 24px;
        height: 50px;
        width: 50px;
        display: grid;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
        color: #f9f9f9;
    }
</style>
<main>
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h4>Digital Visiting Card</h4>
                        <h1>Digital Card View</h1>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center digital-cards" *ngIf="!cardUrl">
                <div class="col-xl-4  col-md-12 mt-5" *ngFor="let color of colors">
                    <div class="" style="padding: 0px;">
                        <div class="page-container">
                            <div class="header">
                                <div class="company-section" style="background-color: {{color}}">
                                    <img src="../../../assets/img/logo.png" alt="User image" class="img-fluid"
                                        style="margin-left: auto;margin-right: auto;">
                                    <p class="text-center company-name">Company Name</p>
                                </div>
                                <div class="profile">
                                    <div class="row">
                                        <div class="col-12">
                                            <img src="../../../assets/img/aut-img.png" alt="User image"
                                                class="img-fluid" style="border-radius: 100px;">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="name_tit_bann_item" style="background-color: {{color}}">
                                        <div style="margin-top: 70px;">
                                            <p class="text-center m-0" class="name_tit_text">Contact Person</p>
                                            <p class="text-center" class="name_tit_text_dec">Designation</p>
                                        </div>
                                        <div class="address-section-inner">
                                            <!-- <i class="fa fa-map-marker" style="color: #{{color}}; font-size: 25px; margin-right: 5px;" aria-hidden="true"></i>  -->
                                            <p>Address</p>
                                            <p class="name_tit_bann_item_sub">8-A, National Highway, At, Gokulnagar,
                                                Makansar, Morbi</p>
                                        </div>
                                        <div class="text-center contact-info">
                                            <p class=" "><span><img src="../../../assets/img/digital_whatsapp.png"
                                                        alt="" class="img-fluid"></span>Whatsapp</p>
                                            <p class="  "><span><img src="../../../assets/img/digital_call.png" alt=""
                                                        class="img-fluid"></span>Call</p>
                                            <p class=""><span><img src="../../../assets/img/digital_email.png" alt=""
                                                        class="img-fluid"></span>Email</p>
                                            <p class=""><span><img src="../../../assets/img/digital_website.png" alt=""
                                                        class="img-fluid"></span>Website</p>
                                        </div>
                                        <div class="text-center contact-info contact-info-soc">
                                            <h5>Share in Social Media</h5>
                                            <p class=" "><span><a [href]="whatsapp" target="_blank"
                                                        data-action="share/whatsapp/share"><i
                                                            class="fa fa-whatsapp fa-2x"
                                                            aria-hidden="true"></i></a></span></p>
                                            <p class="  "><span><a [href]="facebook" target="_blank"><i
                                                            class="fa fa-facebook fa-2x"
                                                            aria-hidden="true"></i></a></span></p>
                                            <p class=""><span><a [href]="twitter" target="_blank"><i
                                                            class="fa fa-twitter fa-2x"
                                                            aria-hidden="true"></i></a></span></p>
                                        </div>
                                        <!-- <a [href]="cardUrl" target="_blank" class="cont_info_soc_link" download><input type="submit" value="Cataloue"></a>  -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-12" style="margin-top: 70px;">
                                    <p class="text-center m-0" style="color:#000; font-size: 20px; font-weight: 700;">Contact Person</p>
                                    <p class="text-center" style="font-weight: 500; color: #{{color}}">Designation</p>
                                </div>                                
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <p class="m-0  contact-info" style="background-color: #{{color}}"><span><i class="fa fa-phone mr-1" aria-hidden="true"></i>Call</span></p>
                                </div>
                                <div class="col-6">
                                    <p class="m-0 contact-info" style="background-color: #{{color}}"><span><i class="fa fa-whatsapp mr-1" aria-hidden="true"></i>Whatsapp</span></p>
                                </div>
                                <div class="col-12">
                                    <p class="m-0 mt-2 contact-info" style="background-color: #{{color}}"><span><i class="fa fa-envelope mr-1" aria-hidden="true"></i>Email</span></p>
                                </div>
                                <div class="col-12">
                                    <p class="m-0 mt-2 contact-info" style="background-color: #{{color}}"><span><i class="fa fa-globe mr-1" aria-hidden="true"></i>Website</span></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mt-5">
                                    <div class="address-section">
                                        <div class="address-section-inner">
                                            <i class="fa fa-map-marker" style="color: #{{color}}; font-size: 25px; margin-right: 5px;" aria-hidden="true"></i> 
                                            <b>Address</b>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-12 text-center">
                                    <input type="submit" (click)="chooseCard(color)" value="Select"
                                        class="name_tit_text_button">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row  justify-content-center">
                <div class="col-md-6">
                    <div class="" *ngIf="cardUrl">
                        <img [src]="cardUrl" alt="Visitor Card" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="cardUrl">
                <div class="col-12 text-center mt-3">
                    <div class="gti_digi_share_ban">
                        <p>Share in Social Media</p>
                        <ul>
                            <li><a [href]="whatsapp" target="_blank" data-action="share/whatsapp/share"><i
                                        class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                            <li><a [href]="facebook" target="_blank"><i class="fa fa-facebook"
                                        aria-hidden="true"></i></a></li>
                            <li><a [href]="twitter" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li><a [href]="youtube" target="_blank"><i class="fa fa-youtube-play"
                                        aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                    <input type="submit" (click)="changeCard()" value="Change" class="mr-3" style="background:#3a4d5e">
                    <a [href]="cardUrl" target="_blank" download>
                        <input type="submit" value="Download" style="background:#3a4d5e">
                    </a>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>