import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-cab-service',
    templateUrl: './cab-service.component.html',
    styleUrls: ['./cab-service.component.css'],
    standalone: false
})
export class CabServiceComponent implements OnInit {
	@Input('services') services = [];
	@Input('cars') cars = '';
	constructor() { }

	ngOnInit(): void {
	}

}
