import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { Router } from "@angular/router";
import { FormValidatorServiceService } from "src/app/services/form-validator-service.service";
import { UserService } from "src/app/services/user.service";

@Component({
    selector: "app-login-modal",
    templateUrl: "./login-modal.component.html",
    styleUrls: ["./login-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoginModalComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<LoginModalComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  loginForm: FormGroup;

  onNoClick(): void {
    this.dialogRef.close();
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private formValidationService: FormValidatorServiceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
	this.router.events.subscribe(()=>{
		this.onNoClick()
	})
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      let fcmToken = this.userService.getFcmToken();
      let logindata = this.loginForm.value;
      logindata = { ...logindata, ...{ fcm_token_web: fcmToken } };
      this.userService.login(logindata).subscribe((response) => {
        if (response.success) {
          this.userService.setAuthData(
            response.data.id,
            response.data.token,
            response.data.name,
            response.data.profile,
            response.data.company,
            response.data.designation,
            response.data.is_access_role_available,
            response.data.access_role,
            response.data.digital_card ? response.data.digital_card : ""
          );
          this.userService.setAuthStatusListener();
          this.onNoClick();
        } else {
          this.userService.errorMessage(response.message);
        }
      });
    } else {
      this.formValidationService.validateAllFormFields(this.loginForm);
      let firstElementWithError = document.querySelector(
        ".ng-invalid[formControlName]"
      );
      firstElementWithError.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
}
