import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { UserService } from "../../../services/user.service";
import { TilesManufactureService } from "../../../services/tiles-manufacture.service";
import { InquiryService } from "../../../services/inquiry.service";
import { AnalyticsService } from "../../../services/analytics.service";
import { ActivatedRoute } from "@angular/router";

import { Title, Meta } from "@angular/platform-browser";
import { DirectoryService } from "src/app/services/directory.service";

@Component({
    selector: "app-tiles-manufacture-list",
    templateUrl: "./tiles-manufacture-list.component.html",
    styleUrls: ["./tiles-manufacture-list.component.css"],
    standalone: false
})
export class TilesManufactureListComponent implements OnInit {
  categoryId: number = 0;
  name: string = "";

  premium: any = [];
  statndard: any = [];
  classic: any = [];
  general: any = [];

  product: any = [];
  size: any = [];
  zone: any = [];
  other: any = [];

  selectedProduct: any = "";
  selectedSize: any = "";
  selectedZone: any = "";

  totalPages: number = 0;
  nextPage: number = 0;

  is_buttons_enable: boolean = false;
  tilesManufactureIdList = [];

  tilescategoryid: number = 0;
  tilescategoryname: string = "";

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    autoplay: true,
    autoplayTimeout: 2000,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplaySpeed: 1500,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private directoryService: DirectoryService,
    private tilesManufactureService: TilesManufactureService,
    private userService: UserService,
    private inquiryService: InquiryService,
    private analyticsService: AnalyticsService,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    this.setAccessRole();
    this.userService.accessRoleListener.subscribe((response) => {
      this.setAccessRole();
    });

    /* recently added */
    let params: any = this.route.snapshot.params;

    if (params.tilescategoryid) {
      history.state.id = params.tilescategoryid;
      this.tilescategoryid = params.tilescategoryid;
      this.tilescategoryname = params.tilescategoryname;
    }
    /* recently added */

    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        history.state.id = params.id;
      }
    });

    if (history.state.id) {
      this.categoryId = history.state.id;
      this.name = history.state.name;

      this.directoryService.getDirectoryCompanies({});

      // this.tilesManufactureService.getList({category_id: this.categoryId,list:[]}).subscribe(response => {
      // 	this.repsonse(response);
      // })
    } else {
      this.router.navigate(["/directory"]);
    }
  }
  setAccessRole(): void {
    let temp_access_role = this.userService.getAccessRole();
    if (temp_access_role?.is_access_role_available == "1") {
      temp_access_role = JSON.parse(temp_access_role.access_role);
      this.is_buttons_enable = temp_access_role?.tiles_manucature
        ? true
        : false;
    }
  }
  repsonse(response, flag: boolean = true): void {
    if (response.success) {
      //console.log(response);
      this.premium = response.data.premium;
      this.statndard = response.data.statndard;
      this.classic = response.data.classic;
      this.general = response.data.general.data;
      if (flag) {
        this.product = response.extra.subcategories;
        this.zone = response.extra.zones;
      }
      this.totalPages = response.data.general.last_page;
      this.nextPage = response.data.general.current_page;
      this.other = response.extra.other;
      if (this.other[0].category) {
        this.name = this.other[0].category;

        if (this.other[0].metatitle != null) {
          this.setTitle(this.other[0].metatitle);
          setTimeout(() => {
            this.metaService.removeTag('name = "description"');
            this.metaService.addTags([
              { name: "description", content: this.other[0].metadescription },
            ]);
            this.setTitle(this.other[0].metatitle);
          }, 1000);
        } else {
          this.setTitle(this.name);
          this.metaService.addTags([
            { name: "description", content: this.name },
          ]);
        }
      }
    } else {
      this.userService.errorMessage(response.message);
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  onChangeProduct(id): void {
    this.selectedProduct = id;
    if (id != "") {
      const product = this.product.find((p) => {
        return p.id == id;
      });
      this.size = product.sizes;
    } else {
      this.size = [];
      this.selectedSize = "";
    }
  }
  onChangeSize(id): void {
    this.selectedSize = id;
  }
  onChangeZone(id): void {
    this.selectedZone = id;
  }
  filterOption(): void {
    let param = { category_id: this.categoryId };
    if (this.selectedProduct != "") {
      param = { ...param, ...{ product_id: this.selectedProduct } };
    }
    if (this.selectedSize != "") {
      param = { ...param, ...{ size_id: this.selectedSize } };
    }
    if (this.selectedZone != "") {
      param = { ...param, ...{ zone_id: this.selectedZone } };
    }
    param = { ...param, ...{ list: [] } };
    this.tilesManufactureService.getList(param).subscribe((response) => {
      this.repsonse(response, false);
    });
  }
  moreCompanies(): void {
    let param = { category_id: this.categoryId, next_page: this.nextPage };
    if (this.selectedProduct != "") {
      param = { ...param, ...{ product_id: this.selectedProduct } };
    }
    if (this.selectedSize != "") {
      param = { ...param, ...{ size_id: this.selectedSize } };
    }
    if (this.selectedZone != "") {
      param = { ...param, ...{ zone_id: this.selectedZone } };
    }
    this.tilesManufactureIdList = [];
    this.general.forEach((element) => {
      this.tilesManufactureIdList.push(element.id);
    });
    param = { ...param, ...{ list: this.tilesManufactureIdList } };
    this.tilesManufactureService.getMore(param).subscribe((response) => {
      if (response.success) {
        this.totalPages = response.extra.total_pages ?? 0;
        this.nextPage = response.extra.next_page ?? 0;
        response.data.forEach((element) => {
          this.general.push(element);
        });
      } else {
        this.userService.errorMessage(response.message);
      }
    });
  }
  Inquiry(id): void {
    if (this.is_buttons_enable) {
      let user = this.userService.getUser();
      let company_id = user.company_id ? user.company_id : 0;
      this.inquiryService
        .save({ from_company_id: company_id, to_company_id: id })
        .subscribe((res) => {
          if (res.success) {
            this.userService.sucessMessage(res.message);
          } else {
            this.userService.errorMessage(res.message);
          }
        });
    }
  }
  analytics(id, type): void {
    this.analyticsService.saveCount({ id: id, type: type }).subscribe();
  }
}
