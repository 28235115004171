<main>
	<!-- <app-breadcrumb [pageName]="variousmanufacturecategory.replace('-',' ')" ></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Company List</h4>
						<h1>{{variousmanufacturecategory.replace('-',' ')}}</h1>
						<!-- <img src="assets/img/line.svg" width="120" alt=""> -->
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="search-bx buylist-form">
						<form>
							<div class="search-box5">
								<select (change)="onChangeProduct($event.target.value)">
									<option value="">Select Product</option>
									<option *ngFor="let p of product" [value]="p.id">{{p.product}}</option>
								</select>
							</div>
							<div class="search-box3">
								<select (change)="onChangeZone($event.target.value)">
									<option value="">Select Zone</option>
									<option *ngFor="let z of zone" [value]="z.id">{{z.zone}}</option>
								</select>
								<button class="ser-btn" (click)="filterOption()"><i class="fa fa-search"></i></button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel cro-side-icn" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let pre of premium">
								<div class="single-buy">
									<div class="row align-center">
										<!-- <img src="assets/img/level1.png" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{pre.id}}/3/{{pre.name | slug}}" alt="Company Logo" [state]="{id: pre.id, type:3}" class="level1"> -->
										<div class="col-xl-3 col-md-3" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{pre.id}}/3/{{pre.name | slug}}" [state]="{id: pre.id, type:3}">
											<div class="profile-img comp-pro-img">
												<img [src]="pre.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{pre.id}}/3/{{pre.name | slug}}" [state]="{id: pre.id, type:3}">{{pre.name}}</h4>
												
												<h6 routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{pre.id}}/3/{{pre.name | slug}}" [state]="{id: pre.id, type:3}">{{pre.brand}} | {{pre.city}}</h6>
												<div class="address" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{pre.id}}/3/{{pre.name | slug}}" [state]="{id: pre.id, type:3}">
													<h4> {{pre.address}} </h4>
												</div>
												<div class="buy-btn">
													<a>
														<button class="view-btn" (click)="Inquiry(pre.id)" [disabled]="!is_buttons_enable">Inquiry Now</button>
													</a>
												</div>
											</div>
										</div>
										<div class="col-xl-3 col-md-4">
											<div class="all-btn social_media_btn" *ngIf="is_buttons_enable">
												<a (click)="analytics(pre.id, 1)" href="tel:{{pre.contact}}"><button><img src="assets/img/callcalling.png" width="30"></button> </a>
												<a (click)="analytics(pre.id, 2)" href="https://api.whatsapp.com/send?phone={{pre.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button><img src="assets/img/whatsapp.png" width="30"></button> </a>
												<a (click)="analytics(pre.id, 4)" href="mailto:{{pre.email}}"><button><img src="assets/img/mail.png" width="30"></button> </a>
												<a (click)="analytics(pre.id, 10)" href="{{pre.website}}" target="_blank"><button><img src="assets/img/wesite.png" width="30"></button> </a>
												<a (click)="analytics(pre.id, 3)" href="{{pre.loc_uri}}" target="_blank"><button><img src="assets/img/location.png" width="30"></button> </a>
											</div>
											<div class="all-btn social_media_btn" *ngIf="!is_buttons_enable">
												<a ><button [attr.disabled]="true"><img src="assets/img/callcalling.png" width="30"></button> </a>
												<a ><button [attr.disabled]="true"><img src="assets/img/whatsapp.png" width="30"></button> </a>
												<a ><button [attr.disabled]="true"><img src="assets/img/mail.png" width="30"></button> </a>
												<a target="_blank"><button [attr.disabled]="true"><img src="assets/img/wesite.png" width="30"></button> </a>
												<a target="_blank"><button [attr.disabled]="true"><img src="assets/img/location.png" width="30"></button> </a>
											</div>
											
										</div>
										<!-- <div class="col-xl-2 col-md-2" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{pre.id}}/3/{{pre.name | slug}}" [state]="{id: pre.id, type:3}">
											<div style="text-align: center;">
												<img [src]="pre.banner" alt="Company Banner">
											</div>
										</div> -->
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel cro-side-icn" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let stan of statndard">
								<div class="single-buy">
									<div class="row align-center">
										<!-- <img src="assets/img/standard.png" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{stan.id}}/3/{{stan.name | slug}}" class="level1" alt="Company Logo" [state]="{id: stan.id, type:3}"> -->
										<div class="col-xl-3 col-md-3" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{stan.id}}/3/{{stan.name | slug}}" [state]="{id: stan.id, type:3}">
											<div class="profile-img comp-pro-img comp-pro-img-e">
												<img [src]="stan.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{stan.id}}/3/{{stan.name | slug}}" [state]="{id: stan.id, type:3}">{{stan.name}}</h4>
												
												<h6 routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{stan.id}}/3/{{stan.name | slug}}" [state]="{id: stan.id, type:3}">{{stan.main_product}}</h6>
												<div class="address" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{stan.id}}/3/{{stan.name | slug}}" [state]="{id: stan.id, type:3}">
													<h4> {{stan.zone}} </h4>
												</div>
												<div class="buy-btn">
													<a>
														<button class="view-btn" (click)="Inquiry(stan.id)" [disabled]="!is_buttons_enable">Inquiry Now</button>
													</a>
												</div>
											</div>
										</div>
										<div class="col-xl-3 col-md-4">
											<div class="all-btn social_media_btn" *ngIf="is_buttons_enable">
												<a (click)="analytics(stan.id, 1)" href="tel:{{stan.contact}}"><button><img src="assets/img/callcalling.png" width="30"></button> </a>
												<a (click)="analytics(stan.id, 2)" href="https://api.whatsapp.com/send?phone={{stan.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button><img src="assets/img/whatsapp.png" width="30"></button> </a>
												<a (click)="analytics(stan.id, 4)" href="mailto:{{stan.email}}"><button><img src="assets/img/mail.png" width="30"></button> </a>
												<a (click)="analytics(stan.id, 10)" href="{{stan.website}}" target="_blank"><button><img src="assets/img/wesite.png" width="30"></button> </a>
												<a (click)="analytics(stan.id, 3)" href="{{stan.loc_uri}}" target="_blank"><button><img src="assets/img/location.png" width="30"></button> </a>
											</div>
											<div class="all-btn social_media_btn" *ngIf="!is_buttons_enable">
												<a ><button [attr.disabled]="true"><img src="assets/img/callcalling.png" width="30"></button> </a>
												<a ><button [attr.disabled]="true"><img src="assets/img/whatsapp.png" width="30"></button> </a>
												<a ><button [attr.disabled]="true"><img src="assets/img/mail.png" width="30"></button> </a>
												<a target="_blank"><button [attr.disabled]="true"><img src="assets/img/wesite.png" width="30"></button> </a>
												<a target="_blank"><button [attr.disabled]="true"><img src="assets/img/location.png" width="30"></button> </a>
											</div>
											
										</div>
										<!-- <div class="col-xl-2 col-md-2" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{stan.id}}/3/{{stan.name | slug}}" [state]="{id: stan.id, type:3}">
											<div style="text-align: center;">
												<img [src]="stan.banner" alt="Company Banner">
											</div>
										</div> -->
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-12">
					<div class="dire-item owl-carousel cro-side-icn" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-template carouselSlide *ngFor="let clas of classic">
								<div class="single-buy">
									<div class="row align-center">
										<!-- <img src="assets/img/classic.png" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{clas.id}}/3/{{clas.name | slug}}" class="level1" alt="Company Logo" [state]="{id: clas.id, type:3}"> -->
										<div class="col-xl-3 col-md-3" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{clas.id}}/3/{{clas.name | slug}}" [state]="{id: clas.id, type:3}">
											<div class="profile-img comp-pro-img comp-pro-img-c">
												<img [src]="clas.logo" alt="Company Logo">
											</div>
										</div>
										<div class="col-xl-6 col-md-5">
											<div class="tiles-info">
												<h4 routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{clas.id}}/3/{{clas.name | slug}}" [state]="{id: clas.id, type:3}">{{clas.name}}</h4>
												
												<h6 routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{clas.id}}/3/{{clas.name | slug}}" [state]="{id: clas.id, type:3}">{{clas.main_product}}</h6>
												<div class="address" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{clas.id}}/3/{{clas.name | slug}}" [state]="{id: clas.id, type:3}">
												   	<h4> {{clas.zone}} </h4> 
												</div>
												<div class="buy-btn">
													<a>
														<button class="view-btn" (click)="Inquiry(clas.id)" [disabled]="!is_buttons_enable">Inquiry Now </button>
													</a>
												</div>
											</div>
										</div>
										<div class="col-xl-3 col-md-4">
											<div class="all-btn social_media_btn" *ngIf="is_buttons_enable">
												<a (click)="analytics(clas.id, 1)" href="tel:{{clas.contact}}"><button><img src="assets/img/callcalling.png" width="30"></button> </a>
												<a (click)="analytics(clas.id, 2)" href="https://api.whatsapp.com/send?phone={{clas.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button><img src="assets/img/whatsapp.png" width="30"></button> </a>
												<a (click)="analytics(clas.id, 4)" href="mailto:{{clas.email}}"><button><img src="assets/img/mail.png" width="30"></button> </a>
												<a (click)="analytics(clas.id, 10)" href="{{clas.website}}" target="_blank"><button><img src="assets/img/wesite.png" width="30"></button> </a>
												<a (click)="analytics(clas.id, 3)" href="{{clas.loc_uri}}" target="_blank"><button><img src="assets/img/location.png" width="30"></button> </a>
											</div>
											<div class="all-btn social_media_btn" *ngIf="!is_buttons_enable">
												<a ><button [attr.disabled]="true"><img src="assets/img/callcalling.png" width="30"></button> </a>
												<a ><button [attr.disabled]="true"><img src="assets/img/whatsapp.png" width="30"></button> </a>
												<a ><button [attr.disabled]="true"><img src="assets/img/mail.png" width="30"></button> </a>
												<a target="_blank"><button [attr.disabled]="true"><img src="assets/img/wesite.png" width="30"></button> </a>
												<a target="_blank"><button [attr.disabled]="true"><img src="assets/img/location.png" width="30"></button> </a>
											</div>
											
										</div>
										<!-- <div class="col-xl-2 col-md-2" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{clas.id}}/3/{{clas.name | slug}}" [state]="{id: clas.id, type:3}">
											<div style="text-align: center;">
												<img [src]="clas.banner" alt="Company Banner">
											</div>
										</div> -->
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6" *ngFor="let gen of general" routerLink="/other-manufacturer/{{variousmanufacturecategory}}/{{gen.id}}/3/{{gen.name | slug}}" [state]="{id: gen.id, type:3}">
					<div class="sta-box tile-info-box">
						<div class="profile-img">
							<img [src]="gen.logo" alt="Company Logo">
						</div>
						<h6>{{gen.name}}</h6>
						<p>{{gen.main_product}}</p>
						<p class="inf-ect-pera">{{gen.zone}}</p>
						<div class="buy-btn">
							<a><button (click)="Inquiry(gen.id)" [disabled]="!is_buttons_enable" class="view-btn">Inquiry Now</button></a>
						</div>
					</div>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new comp-info-more-btn" style="cursor: pointer;" (click)="moreCompanies()"> View More</a>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->
</main>