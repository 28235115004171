import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from './../../../services/user.service';
import { ChatService } from './../../../services/chat.service';
import { CompanyService } from 'src/app/services/company.service';
import { FormControl, NgModel } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';

declare let $: any;

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    standalone: false
})
export class NavigationComponent implements OnInit {
	search = new FormControl()
	companies: any = []
	user:any = {};
	isLoggedIn:boolean=false
	isBoxOpen:boolean=false
	isLoading:boolean=false

	newNotification:boolean = false;
	private authStatusSubs: Subscription
	
	constructor(
		private router: Router,
		private route: ActivatedRoute, 
		private userService : UserService, 
		private chatService: ChatService,
		private companyService: CompanyService,
		
	) { }

	

	ngOnInit(): void {
		this.userService.sidebarCountListener.subscribe((response) => {
			let res:any = response;
			if(res.type == "inquiry" && res.curruntUrl != '/inbox' && res.count != 0){
				console.log('in',res)
				this.playSound();
			}
			if(res.type == "chat" && res.curruntUrl != '/chat' && res.count != 0){
				this.playSound();
			}
			if(res.type == "notification" && res.curruntUrl != '/notifications' && res.count != 0){
				this.playSound();
			}
		});
		this.user = this.userService.getUser();
		this.isLoggedIn = this.userService.isLoggedIn();
		this.authStatusSubs = this.userService.getAuthStatusListener()
			.subscribe((response) => {
				if (response) {
					this.user = this.userService.getUser();
				} else {
					this.user = {};
				}
			});
		this.router.events.subscribe(()=>{
			this.clearSearch()
		})

		  
	}
	playSound():void{
		let audio = new Audio();
		audio.src = "../../../../assets/notification.mp3";
		audio.load();
		audio.play();
		this.newNotification = true;
	}
	showSidebar() {
		this.newNotification = false;
		$(".over_lay").css({
			width: "100%"
		});
		$(".list_items_container").css({
			left: "0px"
		});
	}

	handleClick(action: string) {
		this.router.navigate([action], { queryParams: { isLogin: action == 'login' ? 1 : 0 } })
	}

	logout():void{
		this.user = {};
		this.userService.logout();
		this.chatService.setLogoutListener();
		this.router.navigate(['/login']);
	}

	loadCompanyOptions(search=''):void{

		this.companyService.searchCompanies({
			'search':search,
		  })
		  .subscribe(response=>{
			if(response.success){
			   this.companies = response.data
			   this.isLoading = false
			}
		  })
	}
	
	onSearchCompany(){
		const { value } = this.search
		this.isLoading = true
		if (value.length < 3) {
			if(this.companies.length){
				this.companies = this.companies.filter(c=>c.name.includes(value))
			}
			this.isLoading = false
			return
		}
		this.loadCompanyOptions(value)
	}

	clearSearch(){
		this.search.reset()
		this.isBoxOpen = false
	}

	openBox(){
		this.isBoxOpen = true
	}

	onCompanyClick(company){
		this.router.navigate([`/company/${this.slug(company.name)}/${company.id}`],{state:{id: company.id, type: company.type}})
		this.clearSearch()
	}

	slug(value){
		return value.toLowerCase().trim().replace(/[^A-Z0-9]/ig, "-");
	}
}
