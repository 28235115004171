import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
// import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

declare let $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  standalone: false,
})
export class SidebarComponent implements OnInit {
  constructor(private userService: UserService) {}

  inboxCount: number = 0;
  notificationCount: number = 0;
  chatCount: number = 0;

  separateDialCode = false;
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  // PhoneNumberFormat = PhoneNumberFormat;
  // preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });

  ngOnInit(): void {
    this.userService.sidebarCountListener.subscribe((response) => {
      let res: any = response;
      if (res.type == "inquiry") {
        this.inboxCount = res.count;
      }
      if (res.type == "chat") {
        this.chatCount = res.count;
      }
      if (res.type == "notification") {
        this.notificationCount = res.count;
      }
    });
  }

  hideSidebar() {
    $(".over_lay").css({
      width: "0px",
    });
    $(".list_items_container").css({
      left: "-300px",
    });
  }
  // changePreferredCountries() {
  // 	this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  // }
  sendMessage(): void {
    if (this.phoneForm.value.phone) {
      let url =
        "https://api.whatsapp.com/send?phone=" +
        this.phoneForm.value.phone +
        "&text=Hi";
      window.open(url, "_blank");
    } else {
      this.userService.errorMessage("Please enter number!");
    }
  }
}
