<main>
    <!-- <app-breadcrumb [pageName]="'Inbox'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h4>Inquiry List</h4>
                        <h1>Your Inquiry</h1>
                        <!-- <img src="assets/img/line.svg" width="120"> -->
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-11  col-md-12">
                    <div class="container-fluid text-center" *ngIf="data.length == 0">
                        <div><h4>No inquiry found!</h4></div>
                    </div>
                    <!-- <div class="single-buy" [ngClass]="{'bg-lime':company.is_read==0,'bg-orange':company.is_read==1}" *ngFor="let company of data"> -->
                    <div class="single-buy mt-3 inbo_social_media_btn" *ngFor="let company of data">
                        <div class="row align-center" *ngIf="company.cp_id != 0 && company.cp_id != null">
                            <div class="col-xl-2 col-md-2 col-3" routerLink="/company/{{company.cp_name|slug}}/{{company.cp_id}}" [state]="{id: company?.company_id, type: company?.company_type,inquiry_id:company.inquiry_id}">
                                <div style="text-align: center;">
                                    <img class="inbo_social_media_img" style="width: 100px;" [src]="company?.logo">
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-6" routerLink="/company/{{company.cp_name|slug}}/{{company.cp_id}}" [state]="{id: company?.company_id, type: company?.company_type,inquiry_id:company.inquiry_id}">
                                <div class="tiles-info inbo_main_add_ban">
                                    <h4>{{company?.name}}</h4>
                                    <h5>{{company?.city}}</h5>
                                    <p class="generel-inquiry" *ngIf="company?.remark != null && company?.remark != ''">General Inquiry Remark : {{company?.remark}}</p>
                                    <p>{{company?.date}} <span>{{company?.time}}</span></p>                                    
                                    <p><b>{{company?.category}}</b></p>
                                    <p><b>{{company?.size}}</b></p>
                                </div>
                            </div>
                            <!-- <div class="col-xl-4 col-md-4" routerLink="/company/{{company.cp_name|slug}}/{{company.cp_id}}" [state]="{id: company?.company_id, type: company?.company_type,inquiry_id:company.inquiry_id}">
                                <div class="tiles-info">
                                    <p>{{company?.date}}</p>
                                    <p>{{company?.time}}</p>
                                    <p><b>{{company?.category}}</b></p>
                                    <p><b>{{company?.size}}</b></p>
                                </div>
                            </div> -->
                            <div class="col-xl-4 col-md-4 col-3">
                                <div class="buy-btn inbo_social_media_btn text-right">
                                    <a href="tel:{{company?.contact}}"><button ><img src="assets/img/callcalling.png" width="30"></button> </a>
                                    <a href="https://api.whatsapp.com/send?phone={{company?.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button ><img src="assets/img/whatsapp.png" width="30"></button></a>
                                    <!-- <a routerLink="/chat" [state]="{cIdChatid: company.company_id, name: company?.name, image: company?.logo}"><button class="chat-btn">Chat</button> </a> -->
                                </div>
                            </div>
                        </div>
                        <div class="row align-center" *ngIf="company.cp_id == 0 || company.cp_id == null">
                            <div class="col-xl-2 col-md-2" routerLink="/company/{{company.cp_name|slug}}/{{company.cp_id}}" [state]="{id: company?.company_id, type: company?.company_type,inquiry_id:company.inquiry_id}">
                                <div style="text-align: center;">
                                    <img style="width: 100px;" src="../../../assets/img/aut-img.png" />
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4" routerLink="/company/{{company.cp_name|slug}}/{{company.cp_id}}" [state]="{id: company?.company_id, type: company?.company_type,inquiry_id:company.inquiry_id}">
                                <div class="tiles-info">
                                    <h4>{{company?.cp_name}}</h4>
                                    <h5>{{company?.cp_city}}</h5>
                                    <p class="generel-inquiry" *ngIf="company?.remark != null && company?.remark != ''">General Inquiry Remark : {{company?.remark}}</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4" routerLink="/company/{{company.cp_name|slug}}/{{company.cp_id}}" [state]="{id: company?.company_id, type: company?.company_type,inquiry_id:company.inquiry_id}">
                                <div class="tiles-info">
                                    <p>{{company?.date}}</p>
                                    <p>{{company?.time}}</p>
                                </div>
                            </div>
                            <div class="col-xl-2 col-md-2">
                                <div class="buy-btn">
                                    <a href="tel:{{company?.cp_contact}}"><button class="call-btn">Call</button> </a>
                                    <a href="https://api.whatsapp.com/send?phone={{company?.cp_contact}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank"><button class="whatsapp-btn">Whatsapp</button> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>