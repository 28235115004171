import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from "../../../services/analytics.service";
import { UserService } from "../../../services/user.service";
import { ChartOptions, ChartType } from 'chart.js';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.css'],
    standalone: false
})
export class AnalyticsComponent implements OnInit {
	pieChartOptions: ChartOptions<'pie'> = {
		responsive: true,
		plugins: {
		  legend: {
			position: 'right',
		  },
		  tooltip: {
			enabled: true,
			callbacks: {
			  label: function (context) {
				return `${context.label}: ${context.raw}`;
			  }
			}
		  }
		}
	  };
	pieChartLabels = [
		'Inquiries',
		'Call',
		'Whatsapp',
		'Location',
		'Mail',
		'Social Media',
		'Product Gallery',
		'Company Photos',
		'Catalogue',
		'Profile View',
		'Website Click',
	];
	pieChartData = {
		labels: this.pieChartLabels,
		datasets: [
			{
			  data: [], // Initialize empty; will be updated dynamically
			  backgroundColor: [
				'rgb(58 77 94)', 'rgb(166 199 108)', 'rgb(124 141 254)',
				'rgb(4 227 138)', 'rgb(157 255 132)', 'rgb(243 229 132)',
				'rgb(128 98 254)', 'rgb(55 81 254)', 'rgb(104 134 161)',
				'rgba(87, 143, 171, 0.3)', 'rgb(175 142 142)'
			  ]
			}
		  ]
	};
	  
	pieChartType: ChartType = 'pie';
	pieChartLegend = true;
	pieChartPlugins = [];
	pieChartColors = [
		{
			backgroundColor: [
				'rgb(58 77 94)',
				'rgb(166 199 108)',
				'rgb(124 141 254)',
				'rgb(4 227 138)',
				'rgb(157 255 132)',
				'rgb(243 229 132)',
				'rgb(128 98 254)',
				'rgb(55 81 254)',
				'rgb(104 134 161)',
				'rgba(87, 143, 171, 0.3)',
				'rgb(175 142 142)',
			],
		},
	];
	data: any = {};
	constructor(
		private analyticsService: AnalyticsService,
		private userService: UserService,
	) { }

	ngOnInit(): void {
		this.analyticsService.getCount().subscribe(res => {
			if (res.success) {
				this.pieChartData.datasets[0].data = [
					res.data.inquiry,
					res.data.call_click,
					res.data.whatsapp_click,
					res.data.location_click,
					res.data.mail_click,
					res.data.social_media_click,
					res.data.product_gallery_click,
					res.data.company_photos_click,
					res.data.catalogue_click,
					res.data.profile_view,
					res.data.website_click,
				];
				this.data = res.data;
			} else {
				this.userService.errorMessage(res.message);
			}
		})
	}

}
