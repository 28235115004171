import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SellerPostService } from '../../services/seller-post.service';
import { UserService } from '../../services/user.service';
import { FormValidatorServiceService } from '../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-seller-post-form',
    templateUrl: './seller-post-form.component.html',
    styleUrls: ['./seller-post-form.component.css'],
    standalone: false
})
export class SellerPostFormComponent implements OnInit {
	postForm:FormGroup;
	type: number = 1;
	formData:any = [];

	isEdit:boolean = false;
	editId:number = 0; 

	rateUnit:any = [];
	products:any = [];
	productSize:any = [];
	productGrade:any = [];
	productSaleType:any = [];
	qtyUnit:any = [];
	tax:any = [];
	feature:any = []; 

	selectedFeatures:Array<number> = [];
	selectedImages:any = [];
	existingImages:any = [];

	@ViewChild('image') image: HTMLElement;
	fileTypes: Array<string> = ['jpg', 'jpeg', 'png'];

	constructor(
		private sellerPostService : SellerPostService,
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder,
		private router : Router,
	) { }

	ngOnInit(): void {
		this.initForm();
		this.sellerPostService.getFields().subscribe(response =>{
			this.formData = response.data;
			this.changeData(1);
			if(history.state.sellerEditId){
				this.editId = history.state.sellerEditId;
				this.isEdit = true;
				this.sellerPostService.getPost({id : this.editId}).subscribe(res => {
					if(res.success){					
						this.typeChnage(res.data.type);
						this.onChangeSaleType(res.data.requirement_type);
						this.selectedFeatures = res.data.product_feature_id;
						this.existingImages = res.data.post_images;
						const product = this.products.find((p) => {
							return p.id == res.data.product_id;
						});
						this.productSize = res.data.type == 1 ? product.sizes : [];
						this.postForm.patchValue({
										'rate' 					: res.data.rate,
										'rate_unit' 			: res.data.rate_unit,
										'tax' 					: res.data.tax,
										'product_id' 			: res.data.product_id,
										'size_id' 				: res.data.size_id,
										'product_type' 			: product.type,
										'grade' 				: res.data.grade,
										'requirement_type' 		: res.data.requirement_type,
										'quantity' 				: res.data.quantity,
										'quantity_unit' 		: res.data.quantity_unit,
										'requirement_in_day' 	: res.data.requirement_in_day,
										'product_feature_id' 	: res.data.product_feature_id,
										'product_description' 	: res.data.product_description,
									});
					}else{
						this.userService.errorMessage(res.message)
					}
				})
			}else{
				this.isEdit = false;
			}
		})
	}
	initForm():void{
		this.postForm = this.formBuilder.group({
			type				: [1, [Validators.required]],
			rate 				: ['', [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]],
			rate_unit 			: ['', [Validators.required]],
			tax 				: ['', [Validators.required]],
			product_id 			: ['', [Validators.required]],
			size_id 			: ['', [Validators.required]],
			product_type 		: ['', [Validators.required]],
			grade 				: ['', [Validators.required]],
			requirement_type 	: ['', [Validators.required]],
			quantity 			: ['', [Validators.required, Validators.pattern('[0-9]+')]],
			quantity_unit 		: ['', [Validators.required]],
			requirement_in_day 	: ['', [Validators.required, Validators.pattern('[0-9]+')]],
			product_feature_id 	: [[], [Validators.required]],
			product_description : ['', [Validators.required]],
			images 				: [[], [Validators.required]],
		});
	}
	changeData(type):void{
		if(type == 1){
			this.rateUnit = this.formData?.tiles.rate_unit;
			this.products = this.formData?.tiles.products;
			this.productGrade = this.formData?.tiles.grade;
			this.productSaleType = this.formData?.tiles.requirement_type;
			this.qtyUnit = this.formData?.tiles.quantity_unit;
			this.tax = this.formData?.tiles.tax;
			this.feature = this.formData?.tiles.product_feature;
		}else{
			this.rateUnit = this.formData?.snv.rate_unit;
			this.products = this.formData?.snv.products;
			this.productGrade = this.formData?.snv.grade;
			this.productSaleType = this.formData?.snv.requirement_type;
			this.qtyUnit = this.formData?.snv.quantity_unit;
			this.tax = this.formData?.snv.tax;
			this.feature = this.formData?.snv.product_feature;
		}
	}
	onChangeProduct(id):void{
		if(id != ''){
			const product = this.products.find((p) => {
				return p.id == id;
			});
			this.productSize = product.sizes;
			this.postForm.patchValue({'size_id' : ''});
			this.postForm.patchValue({'product_type' : product.type});
		}else{
			this.postForm.patchValue({'size_id' : 0});
			this.productSize = [];
		}
	}
	onChangeSaleType(id):void{
		if(id == 'Regular'){
			this.postForm.patchValue({'quantity' : 0});
			this.postForm.patchValue({'quantity_unit' : ''});
			this.postForm.get('quantity').setValidators(null);
			this.postForm.get('quantity_unit').setValidators(null);
		}else{
			this.postForm.patchValue({'quantity' : ''});
			this.postForm.get('quantity').setValidators([Validators.required, Validators.pattern('[0-9]+')]);
			this.postForm.get('quantity_unit').setValidators([Validators.required]);
		}
		this.postForm.get('quantity').updateValueAndValidity();
		this.postForm.get('quantity_unit').updateValueAndValidity();
	}
	typeChnage(type): void {
		this.changeData(type);
		this.type = parseInt(type)
		this.postForm.patchValue({'size_id' : 0});
		this.initForm();
		if(type == 2){
			this.postForm.get('size_id').setValidators(null);
			this.postForm.get('size_id').updateValueAndValidity();
		}
		this.postForm.patchValue({'type' : this.type});
	}
	onSelectImage(files){
		if (files.length === 0) {
		  return;
		}
		const extension = files[0].name.split('.').pop().toLowerCase();
		const isSuccess = this.fileTypes.indexOf(extension) > -1;
		if (isSuccess) {
		  	var reader = new FileReader();
		  	reader.onload = (event:any) => {
				this.selectedImages.push({imgUri:event.target.result, image:files[0]})
		  	}
		  	reader.readAsDataURL(files[0]);
		} 
	}
	removeSelectedImage(index):void{
		this.selectedImages.splice(index, 1);
	}
	removeExistingImage(index):void{
		this.existingImages.splice(index, 1);
	}
	onFeatureChange(event, id):void{
		if(event.currentTarget.checked){
			this.selectedFeatures.push(id);
		}else{
			const index: number = this.selectedFeatures.indexOf(id);
			if (index !== -1) {
				this.selectedFeatures.splice(index, 1);
			}
		}
	}
	onSubmit():void{
		if(this.isEdit){
			if(this.existingImages.length){
				this.postForm.get('images').setValidators(null);
			}else{
				this.postForm.get('images').setValidators([Validators.required]);
			}
			this.postForm.get('images').updateValueAndValidity();
		}

		this.postForm.patchValue({'product_feature_id' : this.selectedFeatures});
		let imgs = [];
		this.selectedImages.forEach(element => {
			imgs.push(element.image);
		});
		this.postForm.patchValue({'images' : imgs});
		if (this.postForm.valid) {
			const form = new FormData();
			for (let value of Object.keys(this.postForm.value)) {
				if(value == 'product_feature_id' || value == "images"){
					this.postForm.value[value].forEach((element,index) => {
						form.append(`${value}[${index}]`, element);
					});
				}else{
					form.append(value, this.postForm.value[value]);
				}
			}
			if(this.isEdit){
				this.existingImages.forEach((element, index) => {
					form.append(`existing_images[${index}]`, element.img);
				});
				form.append(`id`, this.editId.toString());
				this.sellerPostService.updatePost(form).subscribe(res => {
					if(res.success){
						this.router.navigate(['/my-sale-post'])
						this.userService.sucessMessage(res.message)
					}else{
						this.userService.errorMessage(res.message);
					}
				});
			}else{
				this.sellerPostService.savePost(form).subscribe(res => {
					if(res.success){
						this.router.navigate(['/my-sale-post'])
						this.userService.sucessMessage(res.message)
					}else{
						this.userService.errorMessage(res.message);
					}
				});
			}
		}else {
			this.formValidationService.validateAllFormFields(this.postForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
}
