import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError, Observable, Subject } from 'rxjs';
import { apiConstants } from '../constants/api-constants';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APIResponse } from '../Modal/apiresponse';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	constructor(private http: HttpClient) { }

	getCount(): Observable<APIResponse> {
		
		return this.http.get(apiConstants.analytics.count).pipe(catchError(this.handleError));
	}
	saveCount(form: any): Observable<APIResponse> {
		return this.http.post(apiConstants.analytics.save, form,{
			'headers':{
			  'X-Skip-Interceptor' : 'true',
			  'X-Skip-Response' : 'true',
			} 
		}).pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}
}
