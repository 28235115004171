<main>
    <!-- <app-breadcrumb [pageName]="'Followings'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8  col-md-12">
                    <div class="single-buy mt-3 follow_main_ban" *ngFor="let company of list">
                        <div class="container-fluid">
                            <div class="row align-center follow_main_text">
                                <div class="col-xl-2 col-md-2 col-3" routerLink="/company/{{company.name|slug}}/{{company.id}}" [state]="{id: company.id, type: company.type}">
                                    <div style="text-align: center;">
                                        <img [src]="company?.logo">
                                    </div>
                                </div>
                                <div class="col-xl-7 col-md-7 col-5" routerLink="/company/{{company.name|slug}}/{{company.id}}" [state]="{id: company.id, type: company.type}">
                                    <div class="tiles-info">
                                        <h4>{{company?.name}}</h4>
                                        <h5>{{company?.city}}</h5>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-3 col-4 text-right" *ngIf="!company?.is_me">
                                    <div class="buy-btn" *ngIf="is_buttons_enable">
                                        <button class="view-btn" *ngIf="!company?.is_following" (click)="followUnfollow(company?.id ,1)">Follow </button>
								        <button class="view-btn" *ngIf="company?.is_following" (click)="followUnfollow(company?.id ,0)">Unfollow </button>
                                    </div>
                                    <div class="buy-btn" *ngIf="!is_buttons_enable">
                                        <button class="view-btn" [attr.disabled]="true" *ngIf="!company?.is_following">Follow </button>
								        <button class="view-btn" [attr.disabled]="true" *ngIf="company?.is_following">Unfollow </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>