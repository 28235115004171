<main>
  <div class="container">
    <section class="design-area new-page-area" id="service">
      <div class="row">
        <div class="col-xl-12 text-center">
          <!-- <div class="profile-data" style="background:url({{data?.banner}});"> -->
          <div class="profile-data">
            <!-- <img src="assets/img/verified.png" class="img-fluid profile-verified"> -->
            <div *ngIf="data?.is_verified">
              <img
                title="Verified"
                src="assets/img/verified.png"
                class="img-fluid profile-verified"
                alt="verified"
              />
            </div>
            <div class="pro-data">
              <div class="profile-img profile-contact-ban">
                <img [src]="data?.logo" class="profile" alt="Company Logo" />

                <img
                  src="assets/img/royal.png"
                  class="level level-1"
                  alt="Package"
                  *ngIf="data?.package == 1"
                />
                <img
                  src="assets/img/exclusive.png"
                  class="level level-1"
                  alt="Package"
                  *ngIf="data?.package == 2"
                />
                <img
                  src="assets/img/classic.png"
                  class="level level-1"
                  alt="Package"
                  *ngIf="data?.package == 3"
                />
                <!-- <img src="assets/img/rank.png" class="rank">
								<span class="rank-text">{{data?.level}}</span> -->
              </div>
              <div class="profile-details">
                <h4>{{ data?.name }}</h4>
                <p *ngIf="type == 1 || type == 2 || type == 3 || type == 9">
                  {{ data?.brand }}
                </p>
                <p *ngIf="type == 7 || type == 4 || type == 5">
                  {{ data?.role }}
                </p>
              </div>
              <ul class="profile-ul profile-contact">
                <li
                  routerLink="/company/{{data?.id}}/{{data?.name|slug}}/followers"
				  [state]="{
                    follow_cp_id: data?.id,
                    button: true
                  }"
                  
                >
                  {{ data?.followers ?? "0" }} Followers
                </li>
                <li
				routerLink="/company/{{data?.id}}/{{data?.name|slug}}/followings"
				[state]="{
				  follow_cp_id: data?.id,
				  button: true
				}"
                >
                  {{ data?.followings ?? "0" }} Following
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="row box-h mb-5 manue_cont_add_ban_padi">
      <div class="col-xl-6 col-md-12 col-sm-12 col-xs-12 col-h">
        <div class="details manue_cont_add">
          <!-- <h2>{{data?.name}}</h2> -->
          <h5 class="manue_cont_add_title">
            <img src="assets/img/map.png" class="img-fluid" alt="" />
            <span>Contact With Us</span>
          </h5>
          <div class="address mt-5">
            <h4>Address</h4>
            <p>{{ data?.address }}</p>
            <div class="">
              <ul class="add-data ps-0 mb-0">
                <li>
                  <h4>City</h4>
                  <p>{{ data?.city }}</p>
                </li>
                <li>
                  <h4>State</h4>
                  <p>{{ data?.state?.state }}</p>
                </li>
                <li>
                  <h4>District</h4>
                  <p>{{ data?.district?.district }}</p>
                </li>
                <li>
                  <h4>Country</h4>
                  <p>{{ data?.country?.country }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="all-btn manue_cont_add_social">
            <h4 class="mt-3">Contact</h4>
            <!-- <a (click)="analytics(2)" href="https://api.whatsapp.com/send?phone={{data?.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website."
							target="_blank"><button ><img src="assets/img/contact_whatsapp.png" class="img-fluid" alt=""> <span class="">Whatsapp</span></button> </a> -->
            <button (click)="onActionBtnClick('view_catelouge')">
              <img src="assets/img/gen_catalogoue.png" class="img-fluid" alt=""> <span class="">Categouge</span>
            </button>
            <button (click)="onActionBtnClick('whatsapp')">
              <img
                src="assets/img/contact_whatsapp.png"
                class="img-fluid"
                alt=""
              /><span class="">Whatsapp</span>
            </button>
            <button (click)="onActionBtnClick('call')">
              <button>
                <img
                  src="assets/img/contact_call.png"
                  class="img-fluid"
                  alt=""
                />
                <span class="">Call</span>
              </button>
              <button (click)="onActionBtnClick('email')">
                <img
                  src="assets/img/contact_mail.png"
                  class="img-fluid"
                  alt=""
                />
                <span class="">Mail</span>
              </button>
            </button>
            <button (click)="onActionBtnClick('web')">
              <img
                src="assets/img/contact_website.png"
                class="img-fluid"
                alt=""
              />
              <span class="">Website</span>
            </button>
            <!-- <a href="{{data?.catalog_url}}"target="_blank"><button ><img src="assets/img/gen_catalogoue.png" class="img-fluid" alt=""> <span class="">Categouge</span></button> </a>
						<a (click)="analytics(2)" href="https://api.whatsapp.com/send?phone={{data?.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website."
							target="_blank"><button ><img src="assets/img/contact_whatsapp.png" class="img-fluid" alt=""> <span class="">Whatsapp</span></button> </a>
						<a (click)="analytics(1)" href="tel:{{data?.contact}}" ><button ><img src="assets/img/contact_call.png" class="img-fluid" alt=""> <span class="">Call</span></button> </a>
						<a (click)="analytics(4)" href="mailto:{{data?.email}}"><button ><img src="assets/img/contact_mail.png" class="img-fluid" alt=""> <span class="">mail</span></button> </a>
						<a (click)="analytics(10)" href="{{data?.website}}" target="_blank"><button><img src="assets/img/contact_website.png" class="img-fluid" alt=""> <span class="">Website</span></button> </a> -->
          </div>
          <div class="social manue_cont_add_social_media">
            <h4>Social Media</h4>
            <div class="social-list footer-social-list">
              <a (click)="onActionBtnClick('social_media', data.facebook)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_646_1315)">
                    <path
                      d="M12.6113 1.37329H10.6113C9.72726 1.37329 8.87942 1.72448 8.2543 2.3496C7.62917 2.97472 7.27798 3.82257 7.27798 4.70662V6.70662H5.27798V9.37329H7.27798V14.7066H9.94465V9.37329H11.9447L12.6113 6.70662H9.94465V4.70662C9.94465 4.52981 10.0149 4.36024 10.1399 4.23522C10.2649 4.1102 10.4345 4.03996 10.6113 4.03996H12.6113V1.37329Z"
                      stroke="#3A4D5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_646_1315">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.611328 0.0400391)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a (click)="onActionBtnClick('social_media', data.twitter)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_646_1319)">
                    <path
                      d="M15.9447 2.04015C15.3063 2.49047 14.5994 2.83489 13.8513 3.06015C13.4498 2.59849 12.9162 2.27128 12.3227 2.12277C11.7292 1.97426 11.1043 2.01161 10.5327 2.22979C9.96113 2.44796 9.47031 2.83642 9.12667 3.34263C8.78303 3.84884 8.60315 4.44837 8.61135 5.06015V5.72682C7.43977 5.7572 6.27886 5.49736 5.23202 4.97045C4.18518 4.44354 3.28489 3.66591 2.61135 2.70682C2.61135 2.70682 -0.0553182 8.70682 5.94468 11.3735C4.5717 12.3055 2.93612 12.7728 1.27802 12.7068C7.27802 16.0402 14.6113 12.7068 14.6113 5.04015C14.6107 4.85445 14.5929 4.66921 14.558 4.48682C15.2384 3.81581 15.7186 2.96863 15.9447 2.04015Z"
                      stroke="#3A4D5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_646_1319">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.611328 0.0400391)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a (click)="onActionBtnClick('social_media', data.instagram)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_646_1323)">
                    <path
                      d="M11.9446 1.37329H5.27797C3.43703 1.37329 1.94464 2.86568 1.94464 4.70662V11.3733C1.94464 13.2142 3.43703 14.7066 5.27797 14.7066H11.9446C13.7856 14.7066 15.278 13.2142 15.278 11.3733V4.70662C15.278 2.86568 13.7856 1.37329 11.9446 1.37329Z"
                      stroke="#3A4D5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.278 7.62C11.3603 8.17483 11.2655 8.74147 11.0072 9.23934C10.7488 9.7372 10.3401 10.1409 9.83909 10.3931C9.33807 10.6453 8.7703 10.7331 8.21653 10.644C7.66275 10.5548 7.15118 10.2934 6.75456 9.89677C6.35795 9.50016 6.09649 8.98858 6.00738 8.4348C5.91827 7.88103 6.00605 7.31326 6.25822 6.81225C6.51039 6.31123 6.91413 5.90249 7.41199 5.64416C7.90986 5.38582 8.47651 5.29105 9.03134 5.37333C9.59728 5.45725 10.1212 5.72097 10.5258 6.12553C10.9304 6.5301 11.1941 7.05405 11.278 7.62Z"
                      stroke="#3A4D5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.278 4.37329H12.2847"
                      stroke="#3A4D5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_646_1323">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.611328 0.0400391)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a (click)="onActionBtnClick('social_media', data.youtube)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_646_1329)">
                    <path
                      d="M15.638 4.32012C15.5588 4.00373 15.3975 3.71384 15.1704 3.47972C14.9433 3.24561 14.6585 3.07558 14.3446 2.98679C13.198 2.70679 8.6113 2.70679 8.6113 2.70679C8.6113 2.70679 4.02464 2.70679 2.87797 3.01345C2.56413 3.10224 2.27929 3.27228 2.0522 3.50639C1.82511 3.7405 1.66383 4.03039 1.58464 4.34679C1.37478 5.51049 1.27213 6.69099 1.27797 7.87345C1.27049 9.06482 1.37315 10.2543 1.58464 11.4268C1.67194 11.7334 1.83684 12.0122 2.0634 12.2364C2.28996 12.4607 2.57052 12.6227 2.87797 12.7068C4.02464 13.0135 8.6113 13.0135 8.6113 13.0135C8.6113 13.0135 13.198 13.0135 14.3446 12.7068C14.6585 12.618 14.9433 12.448 15.1704 12.2138C15.3975 11.9797 15.5588 11.6898 15.638 11.3735C15.8462 10.2185 15.9489 9.04701 15.9446 7.87345C15.9521 6.68209 15.8495 5.49259 15.638 4.32012Z"
                      stroke="#3A4D5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.11133 10.0534L10.9447 7.87336L7.11133 5.69336V10.0534Z"
                      stroke="#3A4D5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_646_1329">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.611328 0.0400391)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <div class="year1">
            <h4>Established Year : {{ data?.year }}</h4>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-md-12 col-sm-12 col-xs-12 top-50">
        <app-tiles-product-size
          *ngIf="data.type == 1"
          [categories]="data?.categories"
          [description]="data?.product_description"
        ></app-tiles-product-size>
        <app-sanitary-product
         
          *ngIf="data.type == 2"
          [mainProducts]="data?.main_products"
          [description]="data?.product_description"
        ></app-sanitary-product>
        <app-various-product
         
          *ngIf="data.type == 3"
          [products]="data?.primary_product_with_name"
          [description]="data?.product_description"
        ></app-various-product>
        <app-trader-dealer-category
         
          *ngIf="data.type == 4"
          [categories]="data?.traders_products_with_name"
          [description]="data?.product_description"
        ></app-trader-dealer-category>
        <app-trader-dealer-category
         
          *ngIf="data.type == 5"
          [categories]="data?.dealers_products_with_name"
          [description]="data?.product_description"
        ></app-trader-dealer-category>
        <app-supplier-category
         
          *ngIf="data.type == 7"
          [products]="data?.products"
          [description]="data?.product_description"
        ></app-supplier-category>
        <app-service-provider-service
         
          *ngIf="data.type == 9"
          [services]="data?.services"
          [description]="data?.product_description"
        ></app-service-provider-service>
        <app-cab-service
         
          *ngIf="data.type == 10"
          [services]="data?.services"
          [cars]="data?.cars"
        ></app-cab-service>
        <app-transport-area-service
         
          *ngIf="data.type == 6"
          [type]="data?.type_of_transport"
          [area]="data?.service_area"
          [description]="data?.product_description"
        ></app-transport-area-service>
        <app-hotel-room-amenities
         
          *ngIf="data.type == 8"
          [rooms]="data?.rooms"
          [description]="data?.product_description"
          [amenities]="data?.amenities"
        ></app-hotel-room-amenities>
      </div>

      <div
        class="col-xl-6 col-md-6 col-sm-6 col-xs-6 d-flex"
        *ngIf="data.hasOwnProperty('group_of_companies')"
      >
        <div class="details con-person manue_cont_app_grp_comp flex-grow-1">
          <h5
            *ngIf="[4, 5, 7, 9].includes(type)"
            class="manue_cont_app_pro_size_title"
          >
            <img src="assets/img/group_company.png" class="img-fluid" alt="" />
            <span>Company Deal</span>
          </h5>
          <h5
            *ngIf="![4, 5, 7, 9].includes(type)"
            class="manue_cont_app_pro_size_title"
          >
            <img src="assets/img/group_company.png" class="img-fluid" alt="" />
            <span>Company Group</span>
          </h5>

          <div class="comp_ban_detail" *ngIf="data?.group_of_companies.length">
            <div
              *ngFor="let goc of data.group_of_companies"
              class="comp_ban_detail_abo"
              (click)="clickCompany(goc.id, goc.name, goc.type)"
            >
              <div class="group-data text-center">
                <img [src]="goc.logo" alt="Company Logo" />
                <p>{{ goc.name }}</p>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="!data?.goc.length">
            <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
              <div class="group-data text-center pt-5">No Companies found</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-xl-6 col-md-6 col-sm-6 col-xs-6 manue_cont_app_cont_perso_specing d-flex"
        *ngIf="data?.users"
      >
        <div class="details con-person manue_cont_app_cont_perso flex-grow-1">
          <!-- <h2>Contact Person</h2> -->
          <h5 class="manue_cont_add_title">
            <img
              src="assets/img/contact_person.png"
              class="img-fluid"
              alt="Contact Person"
            />
            <span>Contact Person{{ data.users.length > 1 ? "s" : "" }}</span>
          </h5>
          <div class="mt-5">
            <div class="" *ngFor="let u of data?.users; index as idx">
              <div
                class="contact-data manue_cont_app_cont_details {{
                  data.users.length != idx + 1
                    ? 'manue_cont_app_cont_details-divider'
                    : ''
                }}"
              >
                <div class="manue_cont_app_cont_det_ord">
                  <img [src]="u.profile" alt="User Profile" />
                </div>
                <div class="manue_cont_app_cont_det_ord">
                  <p>{{ u.designation_name }}</p>
                  <h4>{{ u.name }}</h4>
                </div>
                <div class="bottom-con manue_cont_app_cont_det_ord">
                  <ul>
                    <li>
                      <a
                        (click)="
                          onActionBtnClick(
                            u.designation_name?.toLowerCase() == 'purchase'
                              ? 'personal_call_purchase'
                              : 'personal_call_sale',
                            'tel:' + u?.mobile
                          )
                        "
                        ><img
                          src="assets/img/contact_person_call.png"
                          class="img-fluid"
                          alt=""
                      /></a>
                    </li>
                    <li>
                      <a
                        (click)="
                          onActionBtnClick(
                            u.designation_name?.toLowerCase() == 'purchase'
                              ? 'personal_whatsapp_purchase'
                              : 'personal_whatsapp_sale',
                            'https://api.whatsapp.com/send?phone=' +
                              u?.whats_app +
                              '&text=Hi,%20Got%20reference%20from%20GTI%20website.'
                          )
                        "
                        ><img
                          src="assets/img/contact_person_whatsaap.png"
                          class="img-fluid"
                          alt=""
                      /></a>
                    </li>
                    <!-- <li><a (click)="analytics(1)" href="tel:{{u?.contact}}" class="call-btn"> <img src="assets/img/contact_person_call.png" class="img-fluid" alt=""></a></li>
										<li><a (click)="analytics(2)" href="https://api.whatsapp.com/send?phone={{u?.whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." class="whatsapp-btn" target="_blank">  <img src="assets/img/contact_person_whatsaap.png" class="img-fluid" alt=""></a></li> -->
                    <!-- <li><a routerLink="/chat" [state]="{cIdChatid: data.id, name: data?.name,image: data.logo}" class="chat-btn">Chat</a></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 col-h d-flex">
        <div class="manue_cont_app_genr_info flex-grow-1">
          <h5 class="manue_cont_add_title">
            <img
              src="assets/img/genral_information.png"
              class="img-fluid"
              alt=""
            />
            <span>Genral Information</span>
          </h5>
          <div
            class="bottom-link text-center"
            *ngIf="![10, 6, 8].includes(type)"
          >
            <ul class="mt-5">
              <li>
                <a (click)="onActionBtnClick('company_photo')" class="view-btn"
                  >Company Photo
                  <span
                    ><img
                      src="assets/img/gen_comp_photo.png"
                      class="img-fluid h-auto"
                      alt="" /></span
                ></a>
              </li>
              <li>
                <a (click)="onActionBtnClick('inquiry')" class="view-btn"
                  > <ng-template *ngTemplateOutlet="inqueryLoader"></ng-template>Inquiry
                  <span
                    ><img
                      src="assets/img/genral_inquiry.png"
                      class="img-fluid h-auto"
                      alt="" /></span
                ></a>
              </li>
              <li>
                <a (click)="onActionBtnClick('about_company')" class="view-btn"
                  >About Company
                  <span
                    ><img
                      src="assets/img/gen_about.png"
                      class="img-fluid h-auto"
                      alt="" /></span
                ></a>
              </li>
              <li>
                <a
                  (click)="onActionBtnClick('product_gellery')"
                  class="view-btn"
                  >Product Gallery
                  <span
                    ><img
                      src="assets/img/gen_comp_gellery.png"
                      class="img-fluid h-auto"
                      alt="" /></span
                ></a>
              </li>
            </ul>
          </div>
          <div class="bottom-link text-center" *ngIf="type == 10">
            <ul>
              <li>
                <a (click)="onActionBtnClick('inquiry')" class="view-btn">
                  <ng-template *ngTemplateOutlet="inqueryLoader"></ng-template> Inquiry <i class="fa fa-search"></i
                ></a>
              </li>
              <li>
                <a (click)="onActionBtnClick('company_photo')" class="view-btn"
                  >Cab Photo <i class="fa fa-file-image-o"></i
                ></a>
              </li>
              <li>
                <a (click)="onActionBtnClick('about_company')" class="view-btn"
                  >About Company
                  <span
                    ><img
                      src="assets/img/gen_about.png"
                      class="img-fluid h-auto"
                      alt="" /></span
                ></a>
              </li>
            </ul>
          </div>
          <div class="bottom-link text-center" *ngIf="[6, 8].includes(type)">
            <ul>
              <li>
                <a (click)="onActionBtnClick('inquiry')" class="view-btn"
                  ><ng-template *ngTemplateOutlet="inqueryLoader"></ng-template> Inquiry <i class="fa fa-search"></i
                ></a>
              </li>
              <li>
                <a (click)="onActionBtnClick('company_photo')" class="view-btn"
                  >Company Photo <i class="fa fa-file-image-o"></i
                ></a>
              </li>
              <li>
                <a (click)="onActionBtnClick('about_company')" class="view-btn"
                  >About Company
                  <span
                    ><img
                      src="assets/img/gen_about.png"
                      class="img-fluid h-auto"
                      alt="" /></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<ng-template #inqueryLoader>
  <div
    *ngIf="is_inquiry_loading"
    class="head_drop_serc_oncli_ban"
    style="cursor: progress; margin-right: 0.3rem"
  >
    <div class="spinner-border" style="height: 1rem; width: 1rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
