<main>
    <!-- <app-breadcrumb [pageName]="'Single COmpany'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area new-page-area" id="service">
       <div class="container">
          <div class="row">
             <div class="col-xl-12 text-center">
                <div class="profile-data" style="background:url(assets/img/bg-img.png);">
                   <div class="pro-data">
                      <div class="profile-img">
                         <img src="assets/img/profile.png" class="profile">
                         <img src="assets/img/level.png" class="level"> 
                         <img src="assets/img/rank.png" class="rank">
                      </div>
                      <div class="profile-details">
                         <h4>Arido Ceramic</h4>
                      </div>
                      <ul class="profile-ul">
                         <li> Manufacturer</li>
                         <li>50 Follwers</li>
                         <li>150 Following</li>
                      </ul>
                      <a href=""><button class="edit-btn"> Edit Profile <i class="fa fa-pencil"></i></button></a>

                   </div>
                </div>
             </div>
          </div>
          
          <div class="row box-h mt-30">
             <div class="col-xl-6 col-md-12 col-sm-12 col-xs-12 col-h">
                <div class="details">
                   <h2>Arido Ceramic</h2>
                   <div class="address">
                      <h4><i class="fa fa-map-marker"></i> Address</h4>
                      <p>Near Pavadiyari Bus Stand at : Sapar Pavadiyari-Sapar (Jetpar Road) Morbi, Morbi</p>
                      <div class="row">
                         <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="add-data">
                               <p><b>City:</b> Morbi </p>
                               <p><b>State :</b> Gujarat </p>
                            </div>
                         </div>
                         <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="add-data">
                               <p><b>District :</b> Morbi </p>
                               <p><b>Country  :</b>  India </p>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div class="all-btn">
                      <a href=""><button class="call-btn" >Call</button> </a>
                      <a href=""><button class="whatsapp-btn" >Whatsapp</button> </a>
                      <a href=""><button class="chat-btn" >mail</button> </a>
                      <a href=""><button class="call-btn" >Website</button> </a>
                      <a href=""><button class="loca-btn" >Location</button> </a>
                   </div>
                   <div class="social">
                      <h4><i class="fa fa-thumbs-up"></i> Social Media</h4>
                      <div class="social-list footer-social-list ">
                         <a href="#" class="fb" target="_blank"><i class="fa fa-facebook"></i></a>
                         <a href="#" class="twi" target="_blank"><i class="fa fa-twitter"></i></a>
                         <a href="#" class="ins" target="_blank"><i class="fa fa-instagram"></i></a>
                         <a href="#" class="yb" target="_blank"><i class="fa fa-youtube"></i></a>
                      </div>
                   </div>
                   <div class="year1">
                      <h4> Established Year : 2014</h4>
                   </div>

                </div>
             </div>
          <div class="col-xl-6 col-md-12 col-sm-12 col-xs-12 col-h top-50">
                <div class="details content-details">
                   <h2>product and size </h2>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>

                   <div style="overflow-x:auto;">
                      <table>
                         <tr>
                            <td>Wall Tiles</td>
                            <td>300x300 (12x12)</td>
                         </tr>
                         <tr>
                            <td></td>
                            <td>600x600 (24x24)</td>
                         </tr>
                         <tr>
                            <td>Parking tiles</td>
                            <td>300x300 (12x12)</td>
                         </tr>
                            <tr>
                            <td></td>
                            <td>600x600 (24x24)</td>
                         </tr>

                      </table>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
    <!-- design area end -->
    <!-- design area start -->
    <section class="conta-sec new-page-area">

       <div class="container">
          <div class="row">
             
          <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 ">
                <div class="details con-person">
                   <h2>Group of companies</h2>
                      <div class="row">
                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="group-data  text-center">
                               <img src="assets/img/solcon-img.png">
                               <h4>SOLCON INDUStries</h4>
                            </div>
                         </div>
                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="group-data  text-center">
                               <img src="assets/img/solcon-img.png">
                               <h4>SOLCON INDUStries</h4>
                            </div>
                         </div>
                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="group-data  text-center">
                               <img src="assets/img/solcon-img.png">
                               <h4>SOLCON INDUStries</h4>
                            </div>
                         </div>
                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="group-data  text-center">
                               <img src="assets/img/solcon-img.png">
                               <h4>SOLCON INDUStries</h4>
                            </div>
                         </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
  <section class="conta-sec ">

       <div class="container">
          <div class="row">
             
          <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 ">
                <div class="details con-person">
                   <h2>Contact Person</h2>
                      <div class="row">
                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="contact-data  text-center">
                               <img src="assets/img/aut-img.png">
                               <h4>COMPANY NAME</h4>
                               <p>Manager</p>
                               <div class="bottom-con text-center">
                                  <ul>
                                     <li><a href="# " class="call-btn"> Call</a></li>
                                     <li><a href="# " class="whatsapp-btn"> Whatsapp</a></li>
                                     <li><a href="# " class="chat-btn"> Chat</a></li>
                                  </ul>
                               </div>
                            </div>
                         </div>

                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="contact-data  text-center">
                               <img src="assets/img/aut-img.png">
                               <h4>COMPANY NAME</h4>
                               <p>Manager</p>
                               <div class="bottom-con text-center">
                                  <ul>
                                     <li><a href="# " class="call-btn"> Call</a></li>
                                     <li><a href="# " class="whatsapp-btn"> Whatsapp</a></li>
                                     <li><a href="# " class="chat-btn"> Chat</a></li>
                                  </ul>
                               </div>
                            </div>
                         </div>

                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="contact-data  text-center">
                               <img src="assets/img/aut-img.png">
                               <h4>COMPANY NAME</h4>
                               <p>Manager</p>
                               <div class="bottom-con text-center">
                                  <ul>
                                     <li><a href="# " class="call-btn"> Call</a></li>
                                     <li><a href="# " class="whatsapp-btn"> Whatsapp</a></li>
                                     <li><a href="# " class="chat-btn"> Chat</a></li>
                                  </ul>
                               </div>
                            </div>
                         </div>

                         <div class="col-xl-3 col-md-6 col-sm-6 col-xs-12">
                            <div class="contact-data  text-center">
                               <img src="assets/img/aut-img.png">
                               <h4>COMPANY NAME</h4>
                               <p>Manager</p>
                               <div class="bottom-con text-center">
                                  <ul>
                                     <li><a href="# " class="call-btn"> Call</a></li>
                                     <li><a href="# " class="whatsapp-btn"> Whatsapp</a></li>
                                     <li><a href="# " class="chat-btn"> Chat</a></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>

    <section class="bottom-sec bg-grey">
       <div class="container">
          <div class="row">

             <div class="col-md-12">
                <div class="bottom-link text-center">
                   <ul>
                      
                      <li><a href="# " class="view-btn">Inquiry <i class="fa fa-search"></i></a></li>
                      <li><a href="# " class="view-btn">Product Gallery <i class="fa fa-picture-o"></i></a></li>
                      <li><a href="# " class="view-btn">Company Photo <i class="fa fa-file-image-o "></i></a></li>
                      <li><a href="# " class="view-btn">About Company <i class="fa fa-building-o "></i></a></li>
                      <li><a href="# " class="view-btn">download categouge <i class="fa fa-file-pdf-o"></i></a></li>
                   </ul>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>