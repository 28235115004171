import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { io } from 'socket.io-client';
import { UserService } from './services/user.service';
import { ChatService } from './services/chat.service';
import { MessagingService } from './services/messaging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
	private authStatusSubs: Subscription;
	title = 'GTI';
	user;
	socket;
	titleNoti = 'push-notification';
 	message;
	constructor(
		private userService: UserService,
		private chatService: ChatService,
		private router : Router,
		private messagingService: MessagingService
	) {
		// this.socket = io(environment.SOCKET_URL, {transports: ['websocket']});
		// this.messagingService.requestPermission()
		// this.messagingService.receiveMessage()
		// this.message = this.messagingService.currentMessage
	}

	ngOnInit() {
		this.user = this.userService.getUser();
		if(this.user.company_id){
			this.userService.accessRole({id: this.user.company_id}).subscribe(response => {
				if(response.success){
					localStorage.setItem('is_access_role_available', '1');
					localStorage.setItem('access_role', JSON.stringify(response.data.access_role));
					this.userService.setaccessRoleListener();
				}
			});
		}

		// this.chatService.getChatListener.subscribe(res => {
		// 	this.getChats();
		// })
		// this.chatService.getConversationListener.subscribe(res => {
		// 	this.getConversation(res);
		// })
		// this.chatService.sentMessageListener.subscribe(res => {
		// 	this.messageSent(res);
		// })
		// this.chatService.typingStatusListener.subscribe(res => {
		// 	this.socket.emit('setTypingStatus', res)
		// })
		// this.chatService.logoutListener.subscribe(res => {
		// 	this.socket.disconnect();
		// })
		
		// this.authStatusSubs = this.userService.getAuthStatusListener()
		// 	.subscribe((response) => {
		// 		this.user = this.userService.getUser();
		// 		this.loginUser();
		// 	});
		// this.socket.on('disconnect', data => {
		// 	this.socket = io(environment.SOCKET_URL, {transports: ['websocket']});
		// 	console.log('Disconnectd')
		// });
		// this.socket.on('connect', data => {
		// 	console.log('connect')
		// 	this.loginUser();
		// });
		// this.socket.on('chats', data => {
		// 	this.chatService.setChatListener(data);
		// });
		// this.socket.on('conversation', data => {
		// 	this.chatService.setConversationListener(data);
		// });
		// this.socket.on('messageRecieve', data => {
		// 	this.chatService.setMessageReciveListener(data);
		// });
		// this.socket.on('onlineStatus', data => {
		// 	this.chatService.setOnlineStatusListener(data);
		// });
		// this.socket.on('typingStatus', data => {
		// 	this.chatService.setOnChangeTypingStatusListenerListener(data);
		// });
		// this.socket.on('sidebarcount', data => {
		// 	const url: string = this.router.url;
		// 	data.curruntUrl = url;
		// 	this.userService.setSidebarCountListener(data);
		// });
		// setInterval(() => {
		// 	this.loginUser('no');
		// }, 5000);
	}

	// loginUser(getChat = ''):void{
	// 	if(this.user.id){
	// 		this.socket.emit('join', {id: this.user.id, getChat: getChat});
	// 	}
	// }
	
	// getChats():void{
	// 	if(this.user.company_id){
	// 		this.socket.emit('getChats', {id: this.user.company_id})
	// 	}
	// }
	// getConversation(data):void{
	// 	this.socket.emit('getConversation', data);
	// }
	// messageSent(data):void{
	// 	this.socket.emit('messageSent', data);
	// }

}
