import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { TilesManufactureService } from "../../../services/tiles-manufacture.service";
import { SanitaryService } from "../../../services/sanitary.service";
import { VariousService } from "../../../services/various.service";
import { SupplierService } from "../../../services/supplier.service";
import { ServiceProviderService } from "../../../services/service-provider.service";
import { CabService } from "../../../services/cab.service";
import { TransporterService } from "../../../services/transporter.service";
import { HotelService } from "../../../services/hotel.service";
import { TraderService } from "../../../services/trader.service";
import { DealerService } from "../../../services/dealer.service";
import { FollowService } from "../../../services/follow.service";
import { InquiryService } from "../../../services/inquiry.service";
import { AnalyticsService } from "../../../services/analytics.service";
import { ActivatedRoute } from "@angular/router";

import { Title, Meta } from "@angular/platform-browser";
import { CompanyService } from "src/app/services/company.service";
import { LoginModalComponent } from "../../common/login-modal/login-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { UpgradePackageComponent } from "../../common/upgrade-package/upgrade-package.component";
import { CatelougeModalComponent } from "../../common/catelouge-modal/catelouge-modal.component";
import { SellerFormComponent } from "../../seller/form/form.component";

@Component({
    selector: "app-company-details",
    templateUrl: "./company-details.component.html",
    styleUrls: ["./company-details.component.css"],
    standalone: false
})
export class CompanyDetailsComponent implements OnInit {
  comapanyId: number = 0;
  type: number = 0;
  data: any = [];
  is_buttons_enable = false;
  is_following_loading = false;
  is_inquiry_loading = false;

  title = {
    1 : "Manufacturer",
    2 : "Sanitaryware",
    4 : "Traders",
  }

  //action name : action type

  analytic_type = {
    call : 1,
    whatsapp : 2,
    location : 3,
    email : 4,
    social_media : 5,
    product_gallery : 6,
    company_photos : 7,
    catalogue : 8,
    profil : 9,
    web : 10,
    personal_call_sale : 1,
    personal_call_purchas : 1,
    personal_whatsapp_sale : 2,
    personal_whatsapp_purchase : 2,
  }

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private followService: FollowService,
    private inquiryService: InquiryService,
    private analyticsService: AnalyticsService,
    private titleService: Title,
    private metaService: Meta,
    private companyService: CompanyService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    let params: any = this.route.snapshot.params;
    //added to handle external parameters
    if (params.id) {
      history.state.id = params.id;
      this.route.url.subscribe((url) => {
        const id = url[2];
        this.fetchCompanyDetails(id);
      });
    } else {
      this.router.navigate(["/"]);
    }
    if (history.state.inquiry_id) {
      this.inquiryService
        .readInquiry({ inquiry_id: history.state.inquiry_id })
        .subscribe();
    }
  }

  fetchCompanyDetails(id,headers={},profile_view=true) {
    this.companyService.getCompanyDetail({ id },headers).subscribe((response) => {
      this.response(response);
      if(profile_view){
        this.analytics(9);
      }
      this.route.queryParams.subscribe((params) => {
        if (params.id) {
          history.state.id = params.id;
          history.state.type = params.type;
          this.comapanyId = history.state.id;
          this.type = history.state.type;
        }
      });
    });
  }
  followUnfollow(status): void {
    this.is_following_loading = true;
    this.followService
      .followUnfolllowCompany({ company_id: this.data.id, status: status })
      .subscribe((response) => {
        if (response.success) {
          this.userService.sucessMessage(response.message);
          this.data.is_follow = Boolean(status)
          this.data.followers = this.data.followers + (this.data.is_follow ? 1 : -1)
          this.fetchCompanyDetails(this.data.id,{
            'X-Skip-Interceptor' : 'true',
          },false);
        } else {
          this.userService.errorMessage(response.message);
        }
        this.is_following_loading = false;
      });
  }
  response(response): void {
    //	console.log(response);
    if (response.success) {
      this.data = response.data;
      this.setMetaData()

      if (this.type == 5) {
        let temp_access_role = this.userService.getAccessRole();
        if (temp_access_role?.is_access_role_available == "1") {
          temp_access_role = JSON.parse(temp_access_role.access_role);
          if (this.data.is_indian) {
            this.is_buttons_enable = temp_access_role?.dealer_india
              ? true
              : false;
          } else {
            this.is_buttons_enable = temp_access_role?.dealer_other
              ? true
              : false;
          }
        }
      }
    } else {
      this.userService.errorMessage(response.message);
    }
  }

  setMetaData() {
    let title = this.data.name
    if(this.data.type === 1){
      if (this.data.categories[0]?.subcategory?.product) {
        title = title + " | " +  this.data.categories[0]?.subcategory?.product
      }
      if (this.data.categories[0]?.category?.category) {
        title = title + " | " +  this.data.categories[0]?.category?.category
      }
      title = title + ' | ' + this.title[this.data.type]
    }else if(this.data.type === 3){
      if(this.data.more_category?.name){
        title = title + ' | ' + this.data.more_category.name
      }
    }else if(this.data.type === 5){
      if(this.data.country_id === 1){
        title = title + ' | ' + "Dealers"
      }else{
        title = title + ' | ' + "Importers"
      }
    }else{
      title = title + ' | ' + this.title[this.data.type] 
    }

    if (this.data.type != 1) {
      title = title + ' | ' + 'Firm'
    }

    this.setTitle(title);

    setTimeout(() => {
      //	this.metaService.addTags([{name: 'description', content: this.data.product_description}]);
      this.metaService.removeTag('name = "description"');
      this.metaService.addTags([
        { name: "description", content: this.data.product_description },
      ]);

      this.setTitle(title);
    }, 1000);
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  Inquiry(): void {
    let user = this.userService.getUser();
    let company_id = user.company_id ? user.company_id : 0;
    this.inquiryService
      .save({ from_company_id: company_id, to_company_id: this.comapanyId })
      .subscribe((res) => {
        if (res.success) {
          this.userService.sucessMessage(res.message);
        } else {
          this.userService.errorMessage(res.message);
        }
      });
  }

  open_link = (link) => {
    window.open(link,'_blank')
  }

  onActionBtnClick(action_type=null,value=null){

    const actions = {
      call : ()=>{
        this.open_link(`tel:${this.data.contact}`)
      },
      whatsapp : ()=>{
        this.open_link(`https://api.whatsapp.com/send?phone=${this.data.whatsapp}&text=Hi,%20Got%20reference%20from%20GTI%20website`)
      },
      email : ()=>{
        this.open_link(`mailto:${this.data.email}`)
      },
      web : ()=>{
        this.open_link(`${this.data.website}`)
      },
      social_media : ()=>{
        this.open_link(`${value}`)
      },
      personal_whatsapp_sale : ()=>{
        this.open_link(`${value}`)
      },
      personal_call_sale : ()=>{
        this.open_link(`${value}`)
      },
      personal_whatsapp_purchase : ()=>{
        this.open_link(`${value}`)
      },
      personal_call_purchas : ()=>{
        this.open_link(`${value}`)
      },
      product_gellery : () => {
        this.router.navigate(['gallery'],{relativeTo: this.route,state:{photos : this.data?.product_photos}})
      },
      company_photo : () => {
        this.router.navigate(['photos'],{relativeTo: this.route,state:{photos : this.data?.company_photos}})
      },
      about_company : () => {
        this.router.navigate(['about'],{relativeTo: this.route,state:{about  : this.data?.about}})
      },
      inquiry : () => {
        this.sendInquiry(this.data.id)
      },
      view_catelouge : () => {
        this.dialog.open(CatelougeModalComponent,{
          'data' : {
            'catalogs':this.data.catalogs
          }
        })
      },
    }


    if(action_type==='about_company'){
      this.analytics(this.analytic_type[action_type])
      actions[action_type]()
      return ;
    }

    if(!this.userService.isLoggedIn()){
      if(action_type === 'inquiry'){
        this.dialog.open(SellerFormComponent)
        return ;
      }
      this.dialog.open(LoginModalComponent)
      return ;
    }

    if(action_type==='follow'){
      this.followUnfollow(this.data.is_follow?0:1)
      return 
    }
    

    const access_roles = {
			1 : 'tile_manufacture',
			2 : 'sanitary_manufacture',
			3 : 'various_manufacture',
			4 : 'trader',
			5 :  this.data.country_id !== 1 ? 'dealer_india' : 'dealer_other',
			6 : 'transporter',
			7 : 'supplier',
			8 : 'hotel',
			9 : 'service_provider',
			10 : 'cab',
		}

    const access = this.userService.getAccessRoleByType(access_roles[this.data.type])
    const is_allowed = access[action_type] ?? false 

    if (!is_allowed) {
      this.dialog.open(UpgradePackageComponent)
      return ;
    }

    if(actions[action_type]){
      this.analytics(this.analytic_type[action_type])
      actions[action_type]()
      return ;
    }
    
  }

  analytics(type): void {
    this.analyticsService.saveCount({ id: this.data.id, type: type }).subscribe();
  }

  sendInquiry(id): void {
    this.is_inquiry_loading = true
    let user = this.userService.getUser();
    let company_id = user.company_id ? user.company_id : 0;
    this.inquiryService
      .save({ from_company_id: company_id, to_company_id: id })
      .subscribe((res) => {
        if (res.success) {
          this.userService.sucessMessage(res.message);
        } else {
          this.userService.errorMessage(res.message);
        }
        this.is_inquiry_loading = false
      });
  }

  clickCompany(id, name, type) {
    this.router.navigate([`/company/${this.slug(name)}/${id}`], {
      state: { id: id, type: type },
    });
  }

  slug(value) {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^A-Z0-9]/gi, "-");
  }
}
