<main>
    <!-- <app-breadcrumb [pageName]="'Reference Find'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h4>Reference</h4>
                        <h1>Reference Find</h1>
                        <!-- <img src="assets/img/line.svg" width="120"> -->
                    </div>
                </div>
            </div>

            <div class="row box-h">
                <div class="col-md-12 col-h text-center mb-5 justify-content-center">
                    <form class="identy_find_form_ban" (submit)="onSubmit()">
                        <ngx-material-intl-tel-input
                        class="search-input"
                        [fieldControl]="mobile"
                        [required]="true"
                        [autoIpLookup]="false"
                        [hidePhoneIcon]="true"
                        [iconMakeCall]="false"
                        autoSelectedCountry="in"
                      ></ngx-material-intl-tel-input>
                        <!-- <input type="submit" name=''value='Search' class="mt-3 identy_find_form_inpt" /> -->
                        <button type="submit" name=''value='Search' class=" identy_find_form_inpt" ><i class="fa fa-paper-plane"></i></button>
                    </form>
                </div>
            </div>
            <div class="row" *ngFor="let ref of refrence">
                <h4 class="ref-pro-full-ban">Reference Company</h4>
                <div class="col-md-2 col-6 mt-5"  *ngFor="let company of ref.company.group_of_company_name">
                    <div class="ref-pro-ban">
                        <div class="ex-pro-ban-img-main-ban">
                            <img [src]="company.logo" alt="" />
                        </div>
                        <h5>{{company.name}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->

</main>