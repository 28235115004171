import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DirectoryService } from 'src/app/services/directory.service';
import { InquiryService } from 'src/app/services/inquiry.service';
import { UserService } from 'src/app/services/user.service';
import { SignupFormComponent } from '../../components/signup-form/signup-form.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginModalComponent } from '../../common/login-modal/login-modal.component';
import { UpgradePackageComponent } from '../../common/upgrade-package/upgrade-package.component';
import { SellerFormComponent } from '../../seller/form/form.component';

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.css'],
    standalone: false
})
export class CompanyListComponent  implements OnInit  {

  type_of_directory = {
    'manufacturers' : 1 ,
    'sanitaryware' : 2 ,
    'various-manufacturer' : 3 ,
    'traders' : 4 ,
    'dealers' : 5 ,
    'transpoters' : 6 ,
    'suppliers' : 7 ,
    'hotel' : 8 ,
    'service-provider' : 9 ,
    'cab-service' : 10,
  }

  paid_companies_buttons = {
    1   : 'whatsapp',
    2   : 'whatsapp',
    3   : 'whatsapp',
    4   : 'whatsapp',
    5   : 'whatsapp',
    6   : 'call',
    7   : 'call',
    8   : 'call',
    9   : 'call',
    10  : 'call',
  }

  free_companies_buttons = {
    1   : 'inquiry',
    2   : 'inquiry',
    3   : 'inquiry',
    4   : 'whatsapp',
    5   : 'whatsapp',
    6   : 'whatsapp',
    7   : 'whatsapp',
    8   : 'whatsapp',
    9   : 'whatsapp',
    10  : 'whatsapp',
  }

  title = {
    1 : "Manufacturer"
  }

  type:any = null
  category:any = null
  page:number = 1
  slug:string = ''
  is_button_allowed:boolean = false
  is_inquiry_loading:boolean = false

  premium   :any = []
  standard  :any = []
  classic   :any = []
  general   :any = []

  totalPages:number = 0

  sizes:any = [];
	zones:any = [];
	cities:any = [];
	products:any = [];
	states:any = [];
	roles:any = [];
	services:any = [];
	coutries:any = [];
	areas:any = [];
	sub_products:any = [];
	sub_services:any = [];
	cars:any = [];
	prices:any = [];
  page_title:string='';

  isLoading:boolean=false
  isBoxOpen:boolean=false
	isSearchLoading:boolean=false
  search = new FormControl()
  companies: any = []


  customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		autoplay: false,
		autoplayTimeout: 2000,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		autoplaySpeed: 1500,
		navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 1
			},
			740: {
				items: 1
			},
			940: {
				items: 1
			}
		},
		nav: true
	} 

  filterForm:FormGroup

  analytic_type = {
    call : 1,
    whatsapp : 2,
  }

  constructor(
		private route: ActivatedRoute,
    private formBuilder: FormBuilder,
		private router: Router,
		private directoryService : DirectoryService,
		private userService : UserService,
		private inquiryService: InquiryService,
		private analyticsService: AnalyticsService,
		private titleService:Title,
		private metaService: Meta,
    public dialog: MatDialog,
	) { }

  ngOnInit(): void {

		
		const { params ,queryParams }: any = this.route.snapshot;
    const { slug , category_slug } = params
    const { c } = queryParams
    
    this.type = this.type_of_directory[slug]
    
    this.slug = this.type === this.type_of_directory['manufacturers'] ? category_slug : slug
    this.is_button_allowed = this.verify_access(this.type)

    if (slug) {
      history.state.slug = slug
    }

    if (category_slug) {
      history.state.category_slug = category_slug
    }

    if (c) {
      const category =  Number(atob(c))
      this.category = category > 0 ? category : 0
      history.state.c = c
    }

    // console.log({params,queryParams,slug,category},type_of_directory[slug])

    if(this.type === this.type_of_directory['manufacturers']&&!this.category){
      this.router.navigate(['/directory'])
    }

    this.route.queryParams
      .subscribe(params => {
        if (params.slug) {
          history.state.slug = slug
        }
    
        if (params.category_slug) {
          history.state.category_slug = category_slug
        }
    
        if (params.c) {
          history.state.c = c
        }
      }
    );

    this.initFilterForm();
    this.fetchCompanies();

	}

  verify_access(type):boolean{
    return this.userService.isLoggedIn()
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      zone_id : [0,[]],
      size_id : [0,[]],
      district_id : [0,[]],
      state_id : [0,[]],
      product_id : [0,[]],
      role : [0,[]],
      service_id : [0,[]],
      area_id : [0,[]],
      sub_category_id : [0,[]],
      sub_service_id : [0,[]],
      car_id : [0,[]],
      price_id : [0,[]],
    })
  }

  fetchCompanies(filter={},is_reset=false,headers={}) {
    const params = {
      ...filter,
      directory_type : this.type,
      directory_id :this.category ?? 0,
      page:this.page
    }
    this.directoryService.getDirectoryCompanies(params,headers).subscribe(response=>{
      this.repsonse(response,is_reset)
    })
  }

  repsonse(response,is_reset):void{
		if(response.success){
			this.premium = response.data.premium;
			this.standard = response.data.statndard;
			this.classic = response.data.classic;
			this.general = is_reset ? response.data.general.data : [...this.general,...response.data.general.data];
			
			this.totalPages = response.data.general.last_page;
			this.page = response.data.general.current_page;

      if(!is_reset){
        this.patchExtras(response.extra)
        this.page_title = response.extra?.meta_data.metatitle
        this.setMetaData(response.extra?.meta_data ?? {})
      }


		}else{
			this.userService.errorMessage(response.message);
		}
    this.isLoading = false
	}

  patchExtras(data){
    if(this.type === this.type_of_directory['manufacturers']){
      this.sizes = data.sizes
      this.zones = data.zones
    }else if(this.type === this.type_of_directory['sanitaryware']){
      this.cities = data.cities
      this.zones = []
    }else if(this.type === this.type_of_directory['various-manufacturer']){
      this.products = data.products
      this.zones = data.zones
    }else if(this.type === this.type_of_directory['traders']){
      this.roles = data.roles
      this.zones = data.zones
    }else if(this.type === this.type_of_directory['dealers']){
      this.states = data.states
      this.cities = []
    }else if(this.type === this.type_of_directory['transpoters']){
      this.services = data.services
      this.coutries = data.countries
      this.states = data.states
      this.areas = []
    }else if(this.type === this.type_of_directory['suppliers']){
      this.products = data.products
      this.sub_products = []
    }else if(this.type === this.type_of_directory['service-provider']){
      this.services = data.services
      this.sub_services = []
    }else if(this.type === this.type_of_directory['cab-service']){
      this.services = data.services
      this.cars = data.cars
    }else if(this.type === this.type_of_directory['hotel']){
      this.prices = data.pricing
      this.zones = data.zones
    }
  }

  setMetaData(data) {
    const title = (data?.metatitle ?? this.slug) + " | " + (this.title[this.type]??'Firms')
    this.titleService.setTitle(title);
    setTimeout(() => {

      this.titleService.setTitle(title);

      if(data?.metadescription){
        this.metaService.removeTag('name = "description"');
        this.metaService.addTags([
          { name: "description", content: data?.metadescription },
        ]);
      }

      if(data?.metakeywords){
        this.metaService.removeTag('name = "keywords"');
        this.metaService.addTags([
          { name: "keywords", content: data?.metakeywords },
        ]);
      }

      
    }, 1000);
  }

  onPageChange(){
    this.page = this.page + 1
    const filter = this.getAppliedFilers()
    this.isLoading = true
    this.fetchCompanies(filter,false,{'X-Skip-Interceptor' : 'true'})
    
  }

  onFilterChange(){
    const filter = this.getAppliedFilers()
    this.page = 1
    this.fetchCompanies(filter,true)
  }

  getAppliedFilers(){
    return Object.entries(this.filterForm.value).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
  }

  onCityChange(id){
    this.zones = this.cities.find(c=>c.id===id)?.zones ?? []
    this.filterForm.patchValue({
      'zone_id' : 0
    })
    this.onFilterChange()
  }
  onStateChange(id){
    this.cities = this.states.find(c=>c.id===id)?.districts ?? []
    this.filterForm.patchValue({
      'district_id' : 0
    })
    this.onFilterChange()
  }
  onServiceChange(id){
    const service = this.services.find(c=>c.id===id) ?? {}
    if(service.type==1){
      this.areas = this.coutries
    }else{
      this.areas = this.states
    }
    this.filterForm.patchValue({
      'area_id' : 0
    })
    this.onFilterChange()
    
  }
  onProductChange(id){
    this.sub_products = this.products.find(c=>c.id===id)?.sub_categories ?? []
    this.filterForm.patchValue({
      'sub_category_id' : 0
    })
    this.onFilterChange()
  }
  onProviderServiceChange(id){
    this.sub_services = this.services.find(c=>c.id===id)?.sub_services ?? []
    this.filterForm.patchValue({
      'sub_service_id' : 0
    })
    this.onFilterChange()
  }

  onActionBtnClick(company,action_type){
    if(!this.userService.isLoggedIn()){
      if(action_type === 'inquiry'){
        this.dialog.open(SellerFormComponent)
        return ;
      }
      this.dialog.open(LoginModalComponent)
      return ;
    }

    const access_roles = {
			1 : 'tile_manufacture',
			2 : 'sanitary_manufacture',
			3 : 'various_manufacture',
			4 : 'trader',
			5 :  company.country_id !== 1 ? 'dealer_india' : 'dealer_other',
			6 : 'transporter',
			7 : 'supplier',
			8 : 'hotel',
			9 : 'service_provider',
			10 : 'cab',
		}

    const actions = {
      call : `tel:${company.contact}`,
      whatsapp : `https://api.whatsapp.com/send?phone=${company.whatsapp}&text=Hi,%20Got%20reference%20from%20GTI%20website`,
    }

    const access = this.userService.getAccessRoleByType(access_roles[company.type])
    const is_allowed = access[action_type] ?? false 

    if (!is_allowed) {
      this.dialog.open(UpgradePackageComponent)
      return ;
    }

    this.analytics(company.id,this.analytic_type[action_type])
    if (['whatsapp','call'].includes(action_type)) {
      window.open(actions[action_type],'_blank')
    }

    if(['inquiry','whatsapp'].includes(action_type)){
      this.sendInquiry(company.id)
    }
  }

  sendInquiry(id): void {
    this.is_inquiry_loading = true
    let user = this.userService.getUser();
    let company_id = user.company_id ? user.company_id : 0;
    this.inquiryService
      .save({ from_company_id: company_id, to_company_id: id })
      .subscribe((res) => {
        if (res.success) {
          this.userService.sucessMessage(res.message);
        } else {
          this.userService.errorMessage(res.message);
        }
        this.is_inquiry_loading = false
      });
  }

  analytics(company,type): void {
    this.analyticsService.saveCount({ id: company.id, type: type }).subscribe();
  }

  loadCompanyOptions(search=''):void{

		this.directoryService.getDirectorySearch({
      'directory_type' : this.type,
      'directory_id' :this.category ?? 0,
			'search':search,
		  })
		  .subscribe(response=>{
			if(response.success){
			   this.companies = response.data
			   this.isSearchLoading = false
			}
		  })
	}
	
	onSearchCompany(){
		const { value } = this.search
		this.isSearchLoading = true
		if (value.length < 3) {
			if(this.companies.length){
				this.companies = this.companies.filter(c=>c.name.includes(value))
			}
			this.isSearchLoading = false
			return
		}
		this.loadCompanyOptions(value)
	}

	clearSearch(){
		this.search.reset()
		this.isBoxOpen = false
	}

	openBox(){
		this.isBoxOpen = true
	}

	onCompanyClick(company){
		this.router.navigate([`/company/${this._slug(company.name)}/${company.id}`],{state:{id: company.id, type: company.type}})
		this.clearSearch()
	}

  _slug(value){
		return value.toLowerCase().trim().replace(/[^A-Z0-9]/ig, "-");
	}

}
