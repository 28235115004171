import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
    selector: 'app-catelouge-modal',
    templateUrl: './catelouge-modal.component.html',
    styleUrls: ['./catelouge-modal.component.scss'],
    standalone: false
})
export class CatelougeModalComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<LoginModalComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);

  constructor(
    private router: Router,
  ) { 
    console.log(this.data) 
    this.router.events.subscribe(()=>{
			this.onNoClick()
		})
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
