<main>
    <!-- <app-breadcrumb [pageName]="'Job post for company'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
       <div class="container">
          <div class="row">
             <div class="col-xl-12 text-center">
                <div class="section-title ">
                   <h1>Company</h1>
                   <img src="assets/img/line.svg" width="120">
                </div>
             </div>
          </div>
          <div class="row justify-content-center">
             <div class="col-xl-8 col-md-8 col-sm-8 col-xs-12">
               <div class="form-box">
                <form>
                   <input type="text" name="" placeholder="Company Name">
                   <input type="text" name="" placeholder="City">
                   <input type="tel" name="" placeholder="Cell No.">
                   <input type="text" name="" placeholder="Job Title">
                   <input type="text" name="" placeholder="Education">
                   <input type="text" name="" placeholder="Experience">
                   <textarea rows="4" placeholder="Description"></textarea>
                   <div style="text-align: center;">
                      <input type="submit" name="" value="Submit"> 
                   </div>
                </form>
               </div>
             </div>
             
             
          </div>
       </div>
    </section>
    <!-- design area end -->
 </main>