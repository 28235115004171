import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-product-gallery',
    templateUrl: './product-gallery.component.html',
    styleUrls: ['./product-gallery.component.css'],
    standalone: false
})
export class ProductGalleryComponent implements OnInit {
	photos:any = [];
	constructor(private router: Router) { }

	ngOnInit(): void {
		if(history.state.photos){
			this.photos = history.state.photos;
		}else{
			this.router.navigate(['/'])
		}
	}

}
