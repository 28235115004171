<!-- <app-breadcrumb [pageName]="'Packing Details'"></app-breadcrumb> -->
<main>
	<!-- design area start -->
	<div class="container mt-5">
        <div class="row justify-content-center text-center">
            <div class="col-md-12">
                <ul class="nav nav-tabs memb_plane_tabl_bann_tabs" role="tablist">
                    <li class="nav-item"><a class="nav-link active" href="#bootstrap-home" aria-controls="home" role="tab" data-toggle="tab" value="1">Domestic</a></li>
                    <li class="nav-item"><a class="nav-link" href="#bootstrap-about" aria-controls="about" role="tab" data-toggle="tab">Export</a></li>
                </ul>
            </div>
            <div class="col-md-12">
                <div class="tab">
                    <div role="tablist" aria-labelledby="home-tab" class="tab-pane fade show active" id="bootstrap-home">
                        <div class="ck-content mt-5 table_responsive_css_addon" [innerHTML]="data1|safeHtml"></div>
                    </div>
                    <div role="tablist" aria-labelledby="home-tab" class="tab-pane fade" id="bootstrap-about">
                        <div class="ck-content mt-5 table_responsive_css_addon" [innerHTML]="data2|safeHtml"></div>
                    </div>
                </div>
            </div>
        </div>

	</div>
	<!-- design area end -->
</main>