import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { JobJunctionService } from '../../../../services/job-junction.service';
import { FormValidatorServiceService } from '../../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-jobs-list',
    templateUrl: './jobs-list.component.html',
    styleUrls: ['./jobs-list.component.css'],
    standalone: false
})
export class JobsListComponent implements OnInit {
	companyForm: FormGroup;
	candidateForm: FormGroup;


	currentTab = 'company';
	// currentTab = 'postform';
	first:boolean = true;
	second:boolean = false;
	third:boolean = false;

	formType:number = 1;

	jobList:any = [];
	candidateList:any = [];

	jobNextPage:number = 1;
	jobTotalpage:number = 1;

	candidateNextPage:number = 1;
	candidateTotalpage:number = 1;
	@ViewChild('image') image: HTMLElement;
	@ViewChild('resume') resume: HTMLElement;
	fileTypes: Array<string> = ['jpg', 'jpeg', 'png'];
	fileTypesResume: Array<string> = ['pdf', 'docx'];

	get isLoggedIn () : boolean {
		return this.userService.isLoggedIn()
	}

	constructor(
		private userService : UserService,
		private jobJunctionService : JobJunctionService,
		private formBuilder : FormBuilder,
		private formValidationService: FormValidatorServiceService
	) {
		this.getJobs();
		this.getCandidate();
	}

	ngOnInit(): void {
		this.companyForm = this.formBuilder.group({
			image			: ['', [Validators.required]],
			company      	: ['', [Validators.required]],
			city 			: ['', [Validators.required]],
			mobile 			: ['', [Validators.required]],
			job_title 		: ['', [Validators.required]],
			education 		: ['', [Validators.required]],
			experience 		: ['', [Validators.required]],
			description 	: ['', [Validators.required]],
		});
		this.candidateForm = this.formBuilder.group({
			name			: ['', [Validators.required]],
			image 			: ['', [Validators.required]],
			job_title 		: ['', [Validators.required]],
			city 			: ['', [Validators.required]],
			mobile 			: ['', [Validators.required]],
			education 		: ['', [Validators.required]],
			experience 		: ['', [Validators.required]],
			resume 			: ['', [Validators.required]],
			country_id 		: ['', [Validators.required]],
			state_id 		: ['', [Validators.required]],
			district_id 	: ['', [Validators.required]],
		});
	}
	getJobs():void{
		this.jobJunctionService.getJobList({page: this.jobNextPage}).subscribe(response => {
			if(response.success){
				console.log(response.data)
				response.data.data.forEach(element => {
					this.jobList.push(element)
				});
				this.jobNextPage = response.data.current_page;
				this.jobTotalpage = response.data.last_page;
			}else{
				this.userService.errorMessage(response.message);
			}
		});
	}
	getCandidate():void{
		this.jobJunctionService.getCandidateList({page: this.candidateNextPage}).subscribe(response => {
			if(response.success){
				response.data.data.forEach(element => {
					this.candidateList.push(element)
				});
				this.candidateNextPage = response.data.current_page;
				this.candidateTotalpage = response.data.last_page;
			}else{
				this.userService.errorMessage(response.message);
			}
		});
	}
	onTabChange(tabName: string) {
		this.currentTab = tabName;
		if(tabName == "company"){
			this.first = true;
			this.second = false;
			this.third = false;
		}
		if(tabName == "candidate"){
			this.first = false;
			this.second = true;
			this.third = false;
		}
		if(tabName == "postform"){
			this.first = false;
			this.second = false;
			this.third = true;
		}
	}
	typeChange(type):void{
		this.formType = Number(type)
	}
	onSelectImage(files){
		if (files.length === 0) {
		  	return;
		}
		const extension = files[0].name.split('.').pop().toLowerCase();
		const isSuccess = this.fileTypes.indexOf(extension) > -1;
		if (isSuccess) {
			if(this.formType == 1){
				this.companyForm.patchValue({'image' : files[0]});	
			}else{
				this.candidateForm.patchValue({'image' : files[0]});
			}
		} 
	}
	showContact(c):void{
		alert("Please Contact : " + c);
	}
	onSelectResume(files){
		if (files.length === 0) {
		  	return;
		}
		const extension = files[0].name.split('.').pop().toLowerCase();
		const isSuccess = this.fileTypesResume.indexOf(extension) > -1;
		if (isSuccess) {
			this.candidateForm.patchValue({'resume' : files[0]});
		} else{
			this.userService.errorMessage('Please upload pdf file');
		}
	}
	response(response):void{
		if(response.success){
			console.log(response.data)
			this.ngOnInit();
			if(this.formType == 1){
				this.currentTab = 'company';
				this.first = true;
				this.second = false;
				this.third = false;
				this.jobList = [];
				this.jobNextPage = 0;
				this.getJobs();
			}else{
				this.currentTab = 'candidate';
				this.first = false;
				this.second = true;
				this.third = false;
				this.candidateList = [];
				this.candidateNextPage = 0;
				this.getCandidate();
			}
			this.userService.sucessMessage(response.message);
		}else{
			this.userService.errorMessage(response.message);
		}
	}
	onSubmitCandidate():void{
		if (this.candidateForm.valid) {
			const formData = new FormData();
			for (let value of Object.keys(this.candidateForm.value)) {
				formData.append(value, this.candidateForm.value[value]);
			}
			this.jobJunctionService.saveCandidatePost(formData).subscribe(response => {
				this.response(response);
			});
		}
		else {
			this.formValidationService.validateAllFormFields(this.candidateForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
	onSubmitCompany():void{
		if (this.companyForm.valid) {
			const formData = new FormData();
			for (let value of Object.keys(this.companyForm.value)) {
				formData.append(value, this.companyForm.value[value]);
			}
			this.jobJunctionService.saveJobPost(formData).subscribe(response => {
				this.response(response);
			});
		}
		else {
			this.formValidationService.validateAllFormFields(this.companyForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	onCandidatesViewMore() : void {
		this.candidateNextPage =  this.candidateNextPage + 1
		this.getCandidate()
	}
	onGetJobsViewMore() : void {
		this.candidateNextPage =  this.candidateNextPage + 1
		this.getJobs()
	}
}
