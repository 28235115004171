<main>
    <!-- <app-breadcrumb [pageName]="'Defaulter'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <h1>Defaulter</h1>
                        <!-- <img src="assets/img/line.svg" width="120"> -->
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-8 col-md-8 col-sm-8 col-xs-12">
                    <div class="form-box tiles_calc_mai_form_box">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-6">
                                    <ng-select
                                        class=""
                                        placeholde="Select company"
                                        (search)="onSearchCompany($event)"
                                        formControlName="company"
                                        
                                    >
                                        <ng-option value="">Select Company</ng-option>
                                        <ng-option *ngFor="let company of companies" [value]="company.id">
                                            {{company.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="address" formControlName="address" placeholder="Address">
                                    <div *ngIf="form.get('address').touched && form.get('address').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('address').errors && form.get('address').hasError('required')">Please enter address</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <!-- <input type="text" name="city" formControlName="city" placeholder="City">
                                    <div *ngIf="form.get('city').touched && form.get('city').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('city').errors && form.get('city').hasError('required')">Please enter city</label>
                                    </div> -->
                                    <select formControlName="country"
                                    (change)="onCountryChange($event)">
                                        <option value="">Select Country</option>
                                        <option *ngFor="let country of countries" [value]="country.id">{{country.country}}</option>
                                        <!-- <option value="">Select Country</option>
                                        <option value="">Gujarat</option>
                                        <option value="">Dehli</option>
                                        <option value="">Kerla</option> -->
                                        <!-- <option *ngFor="let p of sanitary" [value]="p.id">{{p.category}}</option> -->
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <!-- <input type="text" name="district" formControlName="district" placeholder="District">
                                    <div *ngIf="form.get('district').touched && form.get('district').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('district').errors && form.get('district').hasError('required')">Please enter district</label>
                                    </div> -->
                                    <select formControlName="state" (change)="onStateChange($event)">
                                        <option value="">Select State</option>
                                        <!-- <option value="">Gujarat</option>
                                        <option value="">Dehli</option>
                                        <option value="">Kerla</option> -->
                                        <option *ngFor="let state of states" [value]="state.id">{{state.state}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <!-- <input type="text" name="state" formControlName="state" placeholder="District">
                                    <div *ngIf="form.get('state').touched && form.get('state').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('state').errors && form.get('state').hasError('required')">Please enter state</label>
                                    </div> -->
                                    <select  formControlName="district">
                                        <option value="">Select District</option>
                                        <option *ngFor="let district of districts" [value]="district.id">{{district.district}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <!-- <input type="text" name="country" formControlName="country" placeholder="Country">
                                    <div *ngIf="form.get('country').touched && form.get('country').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('country').errors && form.get('country').hasError('required')">Please enter country</label>
                                    </div> -->
                                    <input type="text" name="city" formControlName="city" placeholder="City">
                                    <div *ngIf="form.get('city').touched && form.get('city').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('city').errors && form.get('city').hasError('required')">Please enter city</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="mobile" formControlName="mobile" placeholder="Cell No.">
                                    <div *ngIf="form.get('mobile').touched && form.get('mobile').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('mobile').errors && form.get('mobile').hasError('required')">Please enter cell</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="whats_app" formControlName="whats_app" placeholder="Another cell no.">
                                    <div *ngIf="form.get('whats_app').touched && form.get('whats_app').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('whats_app').errors && form.get('whats_app').hasError('required')">Please enter cell</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="gst" formControlName="gst" placeholder="GSTIN">
                                    <div *ngIf="form.get('gst').touched && form.get('gst').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('gst').errors && form.get('gst').hasError('required')">Please enter GSTIN</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="amount_due" formControlName="amount_due" placeholder="Amount Due">
                                    <div *ngIf="form.get('amount_due').touched && form.get('amount_due').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('amount_due').errors && form.get('amount_due').hasError('required')">Please enter amount due</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="date" name="billing_date" formControlName="billing_date" placeholder="Built Date">
                                    <div *ngIf="form.get('billing_date').touched && form.get('billing_date').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('billing_date').errors && form.get('billing_date').hasError('required')">Please select built date</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="file" accept="image/*" #image (change)="onSelectImage(image.files)" />
                                    <div *ngIf="form.get('image').touched && form.get('image').invalid">
                                        <label class="invalid-feedback font-medium is-invalid"  *ngIf="form.get('image').errors && form.get('image').hasError('required')">Please select image</label>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <textarea name="description" formControlName="description" placeholder="Description"></textarea>
                                    <div *ngIf="form.get('description').touched && form.get('description').invalid">
                                        <label class="invalid-feedback font-medium is-invalid" *ngIf="form.get('description').errors && form.get('description').hasError('required')">Please enter description</label>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <input type="submit" value="Submit" class="tiles_calc_mai_form_button">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->
</main>