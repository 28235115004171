<main>
	<!-- <app-breadcrumb [pageName]="'Feedback'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Contact for Complaint</h4>
						<h1>Complaint And Feedback</h1>
						<!-- <img src="assets/img/line.svg" width="120"> -->
					</div>
				</div>
			</div>
            <div class="row">
                <div class="col-md-4">
                    <h2 class="job_list_mai_form_baan_tit">Contact Us</h2>
                    <div class="job_list_mai_cont_dec_ban">
                        <p class=""><i class="fa fa-envelope-o" aria-hidden="true"></i></p>
                        <p class="job_list_mai_cont_dec_ban_dec">Send your mail at <span>gti.directory&#64;gmail.com</span></p>
                    </div>
                    <div class="job_list_mai_cont_dec_ban mt-5">
                        <p class=""><i class="fa fa-phone" aria-hidden="true"></i></p>
                        <p class="job_list_mai_cont_dec_ban_dec">Have any question<span>+91 90990 89071</span></p>
                    </div>
                    <div class="job_list_mai_cont_dec_ban mt-5">
                        <p class=""><i class="fa fa-clock-o" aria-hidden="true"></i></p>
                        <p class="job_list_mai_cont_dec_ban_dec">Working Hours<span>Mon-Sat: 9.30am to 7.00pm</span></p>
                    </div>
                    <!-- <h2>Contact</h2>
                    <p>Email : gti.directory@gmail.com</p>
                    <p>Mobile : +91 909 908 907 1</p> -->
                </div>
                <div class="col-md-8">
                    <div class="form-box job_list_mai_form_baan_new">
                        <div class="row">
                            <div class="job_list_mai_form_baan_1">
                                <label for="image" class="label">Name</label>
                                <input placeholder="Your Name" [(ngModel)]="name" class="form-control" required/>
                            </div>                        
                            <div class="job_list_mai_form_baan_1">
                                <label for="image" class="label">Subject</label>
                                <input placeholder="Your Subject" [(ngModel)]="subject" class="form-control"  required/>
                            </div>                        
                            <div class="job_list_mai_form_baan_1">
                                <label for="image" class="label">Contact No</label>
                                <input placeholder="Your Contact" [(ngModel)]="mobile" class="form-control" type="number"  required/>
                            </div>                     
                            <div class="job_list_mai_form_baan_1">
                                <label for="image" class="label">Message</label>
                                <textarea placeholder="Complaint/Feedback" [(ngModel)]="message" class="form-control" rows="3" required> </textarea>
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <input type="submit" name="" class="job_list_mai_form_button" value="Submit" (click)="submit()">
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</section>
	<!-- design area end -->
</main>