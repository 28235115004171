<main>
  <div class="slider_top_banner">
    <app-top-slider
      [id]="'top-slidder'"
      [banners]="royalBanners"
	  [analytic]="12"
	  (onBannerClick)="clickCompany($event)"
    ></app-top-slider>
  </div>
  <app-trending-brand [brands]="trandingBrands" (onBannerClick)="clickCompany($event)"></app-trending-brand>

  <!-- Hero area start -->

  <section class="gti_home_paid_ban">
    <div class="gti_home_paid_sec_ban">
      <div class="gti_home_paid_sec_sub_ban">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="gti_home_paid_desc_ban">
              <h2>Our Latest Paid Partners</h2>
              <!-- <p>
                Tempora quas odio sunt architecto vitae nostrum minima, modi
                error nam neque? Vitae dolor expedita dolorem. Hic fugit minima
                commodi quis. Lorem ipsum, dolor sit amet consectetur
                adipisicing elit. Eligendi sequi eos laboriosam cum deserunt
                omnis illum incidunt, hic aut ab. Deleniti exercitationem, ipsa
                ratione explicabo aperiam mollitia tempora placeat magnam?
              </p> -->
            </div>
          </div>
          <!-- <div class="col-xl-4 col-lg-4">
            <div class="gti_home_paid_text_ban">
              <p class="text-center">
                Tempora quas odio sunt architecto this is the only Vitae dolor
                expedita dolorem. Hic fugit minima commodi quis.
              </p>
              <a routerLink="/page/membership-plans">View All Best Plans</a>
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <div class="gti_home_paid_slider_ban">
              <h1>
                <img src="assets/img/banner_icon.png" alt="" />Valuable
                <span>Exlusive</span> Companies
              </h1>
              <p>
                This space is reserved for valued <b style="color: #a9cc6e;">Exclusive Brand Companies</b>, who introduce their brand to a select audience. As a trusted partner, the member has invested in promoting their products and services here, ensuring maximum visibility and engagement. The banner below announces that they specialize in quality and excellence in their industry.
              </p>
              <div class="gti_home_paid_slider_line"></div>
              <app-top-slider
                [id]="'exclusive-slidder'"
                [banners]="exclusiveBanners"
				[analytic]="13"
				(onBannerClick)="clickCompany($event)"
              ></app-top-slider>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="gti_home_paid_slider_ban">
              <h1>
                <img src="assets/img/banner_icon.png" alt="" />Valuable
                <span>Classic</span> Companies
              </h1>
              <p>
                This space is reserved for <b style="color: #a9cc6e;">Classic companies</b> with high-value brands presenting their brand to a selected audience. As a trusted partner, the member has invested in promoting their products and services here, ensuring maximum visibility and engagement. The banner below announces that they specialize in quality and excellence in their sector.
              </p>
              <div class="gti_home_paid_slider_line"></div>
              <app-top-slider
                [id]="'classic-slidder'"
                [banners]="classicBanners"
				[analytic]="14"
				(onBannerClick)="clickCompany($event)"
              ></app-top-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Hero area end -->
  <section class="design-archi home_main_support_banner mt-5" id="service">
    <div class="home_support_banner text-center">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-12">
            <div class="home_main_support_ban_team">
              <div class="single-achi">
                  <a routerLink="/exhibition" class="text-decoration-none">
                  <div class="home_main_support_round">
                    <img src="assets/img/exhibition.png" />
                    <div class="side-txt">
                      <h5>Virtual Exhibition</h5>
                      <p>"360° Tiles Companies Showcase "</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="single-achi">
                  <a routerLink="/page/membership-plans" class="text-decoration-none">
                  <div class="home_main_support_round">
                    <img src="assets/img/membership_plan.png" />
                    <div class="side-txt">
                      <h5>Membership Plans</h5>
                      <p>Boost your Business & Facility</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="single-achi">
                <a routerLink="/jobs/list" class="text-decoration-none">
                <div class="home_main_support_round">
                  <img src="assets/img/job_junction.png" />
                  <div class="side-txt">
                    <h5>Job Junction</h5>
                    <p>Connect Talent and Opportunity!</p>
                  </div>
                </div>
                </a>
              </div>
              <div class="single-achi">
                <a routerLink="/analytics" class="text-decoration-none">
                <div class="home_main_support_round">
                  <img src="assets/img/analytics.png" />
                  <div class="side-txt">
                    <h5>Analytics</h5>
                    <p>Track Inquiries, Boost Insights!</p>
                  </div>
                </div>
                </a>
              </div>
            </div>

            <div class="">
              <div class="single-achi">
                <a routerLink="/identity-find" class="text-decoration-none">
                  <div class="single-achi single-achi-bord">
                    <div class="home_main_support_round">
                      <img src="assets/img/identity_find.png" />
                      <div class="side-txt">
                        <h5>Identy Find</h5>
                        <p>Verify Name & Role Instantly!</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="single-achi">
                <a routerLink="/reference-find" class="text-decoration-none">
                  <div class="home_main_support_round">
                    <img src="assets/img/reference_find.png" />
                    <div class="side-txt">
                      <h5>Refrence Find</h5>
                      <p>Trace Dealers Connections with which Companies</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="single-achi">
                <a routerLink="/digital-card" class="text-decoration-none">
                  <div class="single-achi single-achi-bord">
                    <div class="home_main_support_round">
                      <img src="assets/img/digital_visiting_card.png" />
                      <div class="side-txt">
                        <h5>Digital Visiting Card</h5>
                        <p>Create and Share Your Profile!</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="single-achi">
                <a routerLink="/defaulter" class="text-decoration-none">
                  <div class="single-achi single-achi-bord">
                    <div class="home_main_support_round">
                      <img src="assets/img/defaulter.png" />
                      <div class="side-txt">
                        <h5>Defaulter</h5>
                        <p>Track Ceramic Fraud Dealers list</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- <div class="col-xl-4 col-lg-4 col-6"> -->
            <!-- <div class="home_main_abt_ban"> -->
              <!-- <img src="assets/img/6.jpg" alt="" />
              <img
                src="assets/img/hm_dots_icon.png"
                alt=""
                class="home_main_abt_ban_img"
              />
              <div class="home_main_sub_abt_ban">
                <img src="assets/img/tiles_icon_abt.png" alt="" />
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum
                  assumenda exercitationem unde consequatur nulla! Soluta ab.
                </p>
                <button type="button" class="btn">
                  <img src="assets/img/right-arrow.png" alt="" />
                </button> -->
              <!-- </div> -->
            <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container">
      <app-seller-form ></app-seller-form>
    </div>
  </section>

  <!-- design area start -->
  <app-directory class="breadcrumb_hide_ban mt-50 d-block"></app-directory>
  <!-- design area end -->
  <!-- design area start -->
  <section class="design-area" id="service" *ngIf="data?.newarrival">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 text-center">
          <div class="section-title">
            <h4>Lettest Products</h4>
            <h1>New Arrivals</h1>
            <!-- <img src="assets/img/line.svg" width="120"> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="design-item owl-carousel" style="display: block">
            <owl-carousel-o [options]="customOptions">
              <ng-container *ngFor="let slide of data?.newarrival">
                <ng-template carouselSlide [id]="slide.id">
                  <div
                    class="single-new home_aval_ban"
                    (click)="onClickExhibition(slide.company_id, slide.type)"
                  >
                    <img
                      [src]="slide.image"
                      class="new-arrival-image"
                      alt="new arrival image"
                    />
                    <div class="row">
                      <div class="col-xl-8 col-md-8 col-sm-8 col-xs-12 col-8">
                        <h2>{{ slide.name }}</h2>
                        <p>{{ slide.size }}</p>
                      </div>
                      <div class="col-xl-4 col-md-4 col-sm-4 col-xs-12 col-4">
                        <p class="p-0 text-center">
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-60">
    <div class="container">
      <div class="row">
        <div class="col-md-6 align-items-center d-flex">
          <div class="gti_home_app_link_ban">
            <h1>Download our App and Get Our Best Features</h1>
            <p>
              Gujarat Tiles Info is ceramic industry directory. We provide
              community for Tiles Manufacturers, Tiles Dealers, Sanitary
              Manufacturers, Importer, Exporter, Raw Material Suppliers,
              Transporters, Ceramic industry related manufacturer all
              information at one place.
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.essence.gujarattilesinfo"
              ><img src="assets/img/play_store_image.png" class="img-fluid"
            /></a>
            <a
              href="https://apps.apple.com/in/app/gujarat-tiles-info/id915485358"
              ><img src="assets/img/app.png" class="img-fluid"
            /></a>
            <form style="visibility: hidden">
              <div class="search-box">
                <input
                  type="text"
                  class="search-input"
                  placeholder="Enter Phone No."
                />
                <input type="submit" name="" value="Get App" />
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="gti_home_app_link_img_ban">
            <img src="assets/img/playstore_download.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="home_main_blog_ban">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 text-center">
          <div class="section-title">
            <h5 class="mt-5">News</h5>
            <h1>Our Latest News</h1>
          </div>
        </div>
      </div>
    </div>
    <app-blog [limit]="6" [show_page]="false"></app-blog>
  </section>

  <!-- <app-exhibition></app-exhibition> -->
</main>
