import { Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { DirectoryService } from "../../../services/directory.service";
import { DirectoryMoreService } from "../../../services/directorymore.service";
import { UserService } from "../../../services/user.service";
import { Title, Meta } from "@angular/platform-browser";

@Component({
    selector: "app-directory",
    templateUrl: "./directory.component.html",
    styleUrls: ["./directory.component.css"],
    standalone: false
})
export class DirectoryComponent implements OnInit {
  // UserDetails=[
  // 	{img:'../../../../assets/img/directory/tiles.gif',title:"Wall Tiles"},
  // 	{img:'../../../../assets/img/directory/desk_floor_tiles.gif',title:"Floor Tiles"},
  // 	{img:'../../../../assets/img/directory/desk_slap_tiles.gif',title:"Slap Tiles"},
  // 	{img:'../../../../assets/img/directory/desk_pgvt_tiles.gif',title:"GVT - PGVT"},
  // 	{img:'../../../../assets/img/directory/desk_parking_tiles.gif',title:"Parking Tiles"},
  // 	{img:'../../../../assets/img/directory/desk_floor_tiles.gif',title:"Double Charge"},
  // 	{img:'../../../../assets/img/directory/desk_slap_tiles.gif',title:"Nano Vetrified"},
  // 	{img:'../../../../assets/img/directory/desk_pgvt_tiles.gif',title:"Full Body Vetrified"},
  //   ];

  @Input("isCompactView") isCompactView = false;
  @Input("list") list = [];
  @Input("listdirectorymore") listdirectorymore = [];

  constructor(
    private directoryService: DirectoryService,
    private userService: UserService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    if (!this.isCompactView) {
      this.directoryService.getDirectories().subscribe((response) => {
        if (response.success) {
          //console.log(response.data)
          this.list = response.data;
        } else {
          this.userService.errorMessage(response.message);
        }
      });
	  this.setMetaData()
    }
  }

  setMetaData() {
    setTimeout(() => {
      this.titleService.setTitle("Directory");
      this.metaService.removeTag('name = "description"');
      this.metaService.addTags([{ name: "description", content: "Directory" }]);
      this.metaService.removeTag('name = "keywords"');
      this.metaService.addTags([{ name: "keywords", content: "Directory" }]);
    }, 1000);
  }

  onClickDirectyory(id, type, slug): void {
    const routes = {
      1: `manufacturers/${slug}`,
      2: `sanitaryware`,
      3: `various-manufacturer`,
      4: `traders`,
      5: `dealers`,
      6: `transpoters`,
      7: `suppliers`,
      8: `hotel`,
      9: `service-provider`,
      10: `cab-service`,
    };
    let params = {};

    if ([1, 3, 5].includes(type) && id > 0) {
      params = { queryParams: { c: btoa(id) } };
    }

    this.router.navigate([ routes[type] ? 'tiles/'+routes[type] : "/"], params);
  }
}
