<main>
	<!-- <app-breadcrumb [pageName]="'Reset Password'"></app-breadcrumb>	 -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>{{'Reset Password'}}</h1>
						<img src="assets/img/line.svg" width="120" />
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-xl-6 col-md-6 col-sm-12 col-xs-12">
					<div class="form-box">
						<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
							<div class="row">
								<div class="col-md-12">
									<input type="password" name="password" id="password" formControlName="password" placeholder="Password" title="Password" />
									<label [ngClass]="{'is-invalid':resetPasswordForm.get('password').touched && resetPasswordForm.get('password').invalid}"
										class="invalid-feedback font-medium">Please enter password</label>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<input type="password" name="confirmPassword" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirm Password" title="Confirm Password" /> 
									<label [ngClass]="{'is-invalid':resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').invalid}"
										class="invalid-feedback font-medium">Please enter confirm password</label>
										<label *ngIf="((!resetPasswordForm.get('confirmPassword').hasError('required')) && resetPasswordForm.hasError('notSame'))"
										class="invalid-feedback is-invalid font-medium">Confirm password does not match to password</label>
								</div>
							</div>
							<div class="text-center">
								<input type="submit" name="submit" id="submit" value="Submit" />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>


