import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError, Observable, Subject } from 'rxjs';
import { apiConstants } from '../constants/api-constants';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APIResponse } from '../Modal/apiresponse';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DirectoryService {
	constructor(private http: HttpClient) { }

	getDirectories(): Observable<APIResponse> {
		return this.http.get(apiConstants.directory).pipe(catchError(this.handleError));
	}
	getDirectoryCompanies(params={},headers={}): Observable<APIResponse> {
		return this.http.get(apiConstants.company.list,{
			"params":params,
			"headers":headers
		}).pipe(catchError(this.handleError));
	}
	getDirectorySearch(params={}): Observable<APIResponse> {
		return this.http.get(apiConstants.company.directory_search,{
			"params":params,
			'headers':{
				'X-Skip-Interceptor' : 'true',
				'X-Skip-Response' : 'true',
			} 
		}).pipe(catchError(this.handleError));
	}

	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}

}
