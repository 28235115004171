import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-company-photos',
    templateUrl: './company-photos.component.html',
    styleUrls: ['./company-photos.component.css'],
    standalone: false
})
export class CompanyPhotosComponent implements OnInit {
	photos:any = [];
	constructor(private router: Router) { }

	ngOnInit(): void {
		if(history.state.photos){
			this.photos = history.state.photos;
		}else{
			this.router.navigate(['/'])
		}
	}
}
