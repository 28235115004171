import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaulterService } from '../../services/defaulter.service';
import { UserService } from '../../services/user.service';
import { FormValidatorServiceService } from '../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-defaulter-form',
    templateUrl: './defaulter-form.component.html',
    styleUrls: ['./defaulter-form.component.css'],
    standalone: false
})
export class DefaulterFormComponent implements OnInit {
	form:FormGroup;
	@ViewChild('image') image: HTMLElement;
	fileTypes: Array<string> = ['jpg', 'jpeg', 'png'];
	companies:any = [];
	countries:any = [];
	districts:any = [];
	states:any = [];
	constructor(
		private defaulterService : DefaulterService,
		private companyService : CompanyService,
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder,
		private router : Router,
	) {
	 }

	ngOnInit(): void {
		this.initCompany()
		this.loadCompanyOptions()
		this.loadOptions()
		let user = this.userService.getUser();
	}

	initCompany() :void {
		this.form = this.formBuilder.group({
			company : ['', [Validators.required]],
			address : ['', [Validators.required]],
			city : ['', [Validators.required]],
			district : ['', [Validators.required]],
			state : ['', [Validators.required]],
			country : ['', [Validators.required]],
			mobile : ['', [Validators.required]],
			whats_app : [''],
			gst : ['', [Validators.required]],
			amount_due : ['', [Validators.required]],
			billing_date : ['', [Validators.required]],
			image : ['', [Validators.required]],
			description : [''],
		});
	}

	loadOptions():void{
		this.defaulterService.getList({}).subscribe(res => {
			if(res.success){
				// console.log(res.data)
				// this.companies = res.extra.companies;
				// this.companies = res.extra.companies;
				this.countries = res.extra.countries;
				this.states = [];
				this.districts = [];
				// console.log(res.extra.companies)
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}

	onCountryChange(event):void{
		const value = event.target.value
		this.form.controls['state'].setValue('')
		this.form.controls['district'].setValue('')
		if(!value){
			this.states = []
			this.districts = []
			return 
		}
		this.states = this.countries.find(c=>c.id==value)?.states ?? []
		this.districts = []
		// console.log(this.form.value)
	}

	onStateChange(event):void{
		const value = event.target.value
		this.form.controls['district'].setValue('')
		if(!value){
			this.districts = []
			return 
		}
		this.districts = this.states.find(s=>s.id==value)?.districts ?? []
	}

	onSelectImage(files){
		if (files.length === 0) {
		  return;
		}
		const extension = files[0].name.split('.').pop().toLowerCase();
		const isSuccess = this.fileTypes.indexOf(extension) > -1;
		if (isSuccess) {
		  	var reader = new FileReader();
		  	reader.onload = (event:any) => {
				this.form.patchValue({'image': files[0]});
		  	}
		  	reader.readAsDataURL(files[0]);
		} 
	}

	loadCompanyOptions(search=''):void{

		this.companyService.searchCompanies({
			'search':search,
		  })
		  .subscribe(response=>{
			if(response.success){
			   this.companies = response.data
			}
		  })
	}
	
	onSearchCompany({term,items}){
		console.log(term)
		if (term.length < 3) {
			if(this.companies.length){
				this.companies = this.companies.filter(c=>c.name.includes(term))
				return
			}
		}
		this.loadCompanyOptions(term)
	}

	onSubmit():void{
		console.log(this.form.value)
		if (this.form.valid) {
			const form = new FormData();
			for (let value of Object.keys(this.form.value)) {
				form.append(value, this.form.value[value]);
			}
			this.defaulterService.save(form).subscribe(res => {
				if(res.success){
					console.log(res.data)
					this.userService.sucessMessage(res.message);
					this.router.navigate(['/defaulter'])
				}else{
					this.userService.errorMessage(res.message);
				}
			});
			
		}else {
			this.formValidationService.validateAllFormFields(this.form);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
}
