import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
  selector: 'app-bookvisit',
  templateUrl: './bookvisit.component.html',
  styleUrl: './bookvisit.component.scss',
  standalone: false
})
export class BookvisitComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<LoginModalComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  links:any[] = []

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.links = this.data.links
  }

  

  onNoClick(): void {
    this.dialogRef.close();
  }
}
