<main>
	<!-- <app-breadcrumb [pageName]="'Forgot Password'"></app-breadcrumb>	 -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>{{'Forgot Password'}}</h1>
						<img src="assets/img/line.svg" width="120" />
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-xl-6 col-md-6 col-sm-12 col-xs-12">
					<div class="form-box">
						<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
							<div class="row">
								<div class="col-md-12">
									<input type="email" name="" formControlName="email" placeholder="Email">
									<label [ngClass]="{'is-invalid':forgotPasswordForm.get('email').touched && forgotPasswordForm.get('email').invalid}"
										class="invalid-feedback font-medium">Please enter email</label>
								</div>
							</div>
							<div class="text-center">
								<input type="submit" name="submit" id="submit" value="Submit" />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</main> 