import { Component, OnInit } from '@angular/core';
import { InformativePagesService } from '../../../services/informative-pages.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: [
        './aboutus.component.css',
        '../../../../assets/css/ckeditor.css'
    ],
    standalone: false
})
export class AboutusComponent implements OnInit {
	// about:any;
	data:any = '';
	constructor(
		private pageService : InformativePagesService,
		private userService : UserService,
	) { }

	ngOnInit(): void {
		this.pageService.about().subscribe(res => {
			if(res.success){
				console.log(res)
				this.data = res.data; 
				// this.about = res.data;
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}
}
