// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	BASE_URL : 'https://staging.gujarattilesinfo.com/',
	// API_URL:'http://192.168.1.189:8000/api/', //Live
	// API_URL:'https://staging.gujarattilesinfo.com/backend/api/', //Live
	API_URL:'https://api.gujarattilesinfo.com/api/', //Live
	//API_URL:'https://bmartmall.com/gitapi/git-backend/api/', //local
	// API_URL: 'http://127.0.0.1:8000/api/', //Local
	SOCKET_URL: 'wss://gujarattilesinfo.com:2323/',
	// SOCKET_URL : 'http://localhost:2323',
	firebase: {
		apiKey: "AIzaSyCUGf1xMzRsb1IoIKyJ12koMOsgKZqE0Gw",
		authDomain: "gujarat-tiles.firebaseapp.com",
		projectId: "gujarat-tiles",
		storageBucket: "gujarat-tiles.appspot.com",
		messagingSenderId: "323321934029",
		appId: "1:323321934029:web:ff9912df70e4494c593984",
		measurementId: "G-5BHY40C4DK"
	}
};

/*
 * For easier debugging in development mode, you can import thnge following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
