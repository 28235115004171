import { Component, Input, OnInit } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css'],
    standalone: false
})
export class BlogComponent implements OnInit {
	@Input() limit:number = 9
	@Input() show_page:boolean = true

	data:any = '';
	blogList:any = '';
	currentPage = 1;
	totalPage = 0;
	itemPerPage = 0;
	constructor(
		private blogService: BlogService,
		private userService: UserService,
	) { }

	ngOnInit(): void {
		this.getBlogList();
	}

	getBlogList() {
		this.blogService.get({page:this.currentPage,limit:this.limit}).subscribe(res => {
			if (res.success) {
				this.data = res.data.blogs.data;
				this.blogList = res.data.blogs.data;
				
				this.totalPage=parseInt(res.data.blogs.last_page);
                this.itemPerPage=parseInt(res.data.blogs.per_page);
				// console.log(res.data.total_pages * res.data.blogs.length);
			} else {
				this.userService.errorMessage(res.message);
			}
		});
	}

	pageChanged(event: any): void {
		this.currentPage = event.page;
		this.getBlogList();
	}

	splitArray(array: any, size: number): Array<number> {
	    let newArray = [];
	    for (let i = 0; i < array.length; i += size) {
	      newArray.push(array.slice(i, i + size));
	    }
	    return newArray;
	}

	descriptionLimit(description){
		return description.length > 150 ? description.substr(0,150)+"..."  : description;
	}

	slugify (title) {
	  return title
		.toLowerCase()
		.replace(/[^\w ]+/g, '')
		.replace(/ +/g, '-');
	}
}
