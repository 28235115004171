import { Component, OnInit } from '@angular/core';
import { UserService } from  '../../services/user.service'

@Component({
    selector: 'app-digital-card',
    templateUrl: './digital-card.component.html',
    styleUrls: ['./digital-card.component.css'],
    standalone: false
})
export class DigitalCardComponent implements OnInit {
	cardUrl:string = '';
	whatsapp:string = '';
	facebook:string = '';
	twitter:string = '';
	// youtube:string = '';
	constructor(
		private userService : UserService
	) { }

	colors:any = ['#9bdce4', '#c9f0f5','#3a4d5e33', '#83b8c3','#82c7f161','#99c1e13b', '#cbd6db', '#e3d2e5'];

	ngOnInit(): void {
		let user = this.userService.getUser();
		if(user?.digital_card){
			this.cardUrl =  user.digital_card;
			this.whatsapp = "https://web.whatsapp.com/send?text=https://gujarattilesinfo.com/backend" + user.digital_card;
			this.facebook = "https://www.facebook.com/sharer/sharer.php?u=https://gujarattilesinfo.com/backend" + user.digital_card;
			this.twitter = "http://twitter.com/share?text=My Visiting Card&url=https://gujarattilesinfo.com/backend/" + user.digital_card;
			// this.youtube = "http://youtube.com/share?text=My Visiting Card&url=https://gujarattilesinfo.com/backend/" + user.digital_card;
		}
	}
	chooseCard(color):void{
		
		this.userService.digitalCardGenerate({color: color}).subscribe(res => {
			if(res.success){
				//console.log(res.data)
				localStorage.setItem('digital_card', res.data.image);
				this.cardUrl = res.data.image;
				
			this.whatsapp = "https://web.whatsapp.com/send?text=" + this.cardUrl;
			this.facebook = "https://www.facebook.com/sharer/sharer.php?u=" + this.cardUrl;
			this.twitter = "http://twitter.com/share?text=My Visiting Card&url=" + this.cardUrl;
			// this.youtube = "http://twitter.com/share?text=My Visiting Card&url=" + this.cardUrl;

				this.userService.sucessMessage(res.message);
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}
	changeCard():void{
		this.cardUrl = '';
	}
}
