import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
// import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { identityFind } from 'src/app/services/identity-find.service';
import { UserService } from 'src/app/services/user.service';
// import { Interpolation } from '@angular/compiler';
// import { utils } from 'protractor';

@Component({
    selector: 'app-find-identity',
    templateUrl: './find-identity.component.html',
    styleUrls: ['./find-identity.component.css'],
    standalone: false
})
export class FindIdentityComponent implements OnInit {
  identity: Array<any> = [];
  title = "intlInputNew"
  mobile:FormControl = new FormControl()
  constructor(
    private IdentityFind : identityFind,
		private userService : UserService,
		// private router: Router
  ) { }

  inboxCount:number = 0;
	notificationCount:number = 0;
	chatCount:number = 0;


  ngOnInit(): void {}

  onSubmit():void{

    const params = new HttpParams({'fromObject':{
      'mobile' : String(this.mobile.value).replace(/[\s]/g, '')
    }})

    this.IdentityFind.getList(params).subscribe(res => {
			if(res.success){
				console.log(res.data)
        this.userService.sucessMessage(res.message)
        this.identity = res.data
			}else{
				this.userService.errorMessage(res.message);
			}
		});
  }

}
