import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { APIResponse } from "../Modal/apiresponse";
import { apiConstants } from "../constants/api-constants";

@Injectable({
  providedIn: "root",
})
export class BusinessPostService {
  constructor(private http: HttpClient) {}

  get(data = {}, headers = {}): Observable<APIResponse> {
    return this.http
      .get(apiConstants.business_post.list, { params: data, headers: headers })
      .pipe(catchError(this.handleError));
  }
  manage_like(id: number): Observable<APIResponse> {
    return this.http
      .post(
        apiConstants.business_post.manage_like + id,
        {},
        {
          headers: {
            "X-Skip-Interceptor": "true",
            "X-Skip-Redirect": "true",
          },
        }
      )
      .pipe(catchError(this.handleError));
  }
  manage_shares(id: number): Observable<APIResponse> {
    return this.http
      .post(
        apiConstants.business_post.manage_shares + id,
        {},
        {
          headers: {
            "X-Skip-Interceptor": "true",
          },
        }
      )
      .pipe(catchError(this.handleError));
  }
  manage_whatsapp(id: number): Observable<APIResponse> {
    return this.http
      .post(
        apiConstants.business_post.manage_whatsapp + id,
        {},
        {
          headers: {
            "X-Skip-Interceptor": "true",
          },
        }
      )
      .pipe(catchError(this.handleError));
  }
  manage_view(id: number): Observable<APIResponse> {
    return this.http
      .post(
        apiConstants.business_post.manage_view + id,
        {},
        {
          headers: {
            "X-Skip-Interceptor": "true",
          },
        }
      )
      .pipe(catchError(this.handleError));
  }
  store(data: FormData): Observable<APIResponse> {
    return this.http
      .post(apiConstants.business_post.store, data)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error?.status}, ` + `body was: ${error?.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
