<div class="container design-area">
  <div class="row">
    <div class="col-md-12" *ngFor="let exhibition of company_exhibition">
      <div class="exhi-compo-main-ban">
        <div class="exhi-compo-img-ban">
          <img src="{{ exhibition.banner }}" alt="" />
        </div>
        <div class="exhi-compo-main-side mt-lg-3 mt-3">
          <div class="exhi-compo-link" (click)="exhibitionGalleryClick(exhibition)">
            <div class="exhi-compo-icon">
              <img src="assets/img/gallery.png" alt="" />
            </div>
            <h4>Gallery</h4>
          </div>
          <div (click)="exhibitionClick(exhibition)" class="exhi-compo-link">
            <div class="exhi-compo-icon">
              <img src="assets/img/fullview.png" alt="" />
            </div>
            <h4>360 View</h4>
          </div>
          <div (click)="exhibitionReviewClick(exhibition)" class="exhi-compo-link">
            <div class="exhi-compo-icon">
              <img src="assets/img/review.png" alt="" />
            </div>
            <h4>Review</h4>
          </div>
          <div (click)="exhibitionBookVisitClick(exhibition)" class="exhi-compo-link">
            <div class="exhi-compo-icon">
              <img src="assets/img/bookvisit.png" alt="" />
            </div>
            <h4>Book Visit</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-6 mt-5" *ngFor="let virtual of virtual_exhibition" (click)="virtualExhibitionClick(virtual)">
      <div class="ex-pro-ban">
        <div class="ex-pro-ban-img-main-ban">
          <img src="{{virtual.company.logo}}" alt="" />
        </div>
        <h5>{{virtual.company.name}}</h5>
        <button type="button" class="btn btn-default ex-pro-ban-btn" (click)="whatsappClick(virtual.company,$event)">
          Whatsapp
        </button>
        <button type="button" tabindex="1" (click)="clickCompany(virtual.company,$event)" class="btn btn-default ex-pro-ban-img">
          <img src="assets/img/ex-contact.png" alt="" />
        </button>
      </div>
    </div>
  </div>
</div>
