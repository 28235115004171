<main>
	<!-- <app-breadcrumb [pageName]="'DEFAULTER'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Defaulter</h4>
						<h1>Defaulter List</h1>
						<!-- <img src="assets/img/line.svg" width="120"> -->
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-xl-12 col-md-12 mb-3 text-end dflt_main_add_ban">
					<button routerLink="/defaulter/add"  type="button" class="defaulter-add-btn">Add Defaulter</button>
				</div>
			</div>
				<div class="row justify-content-center">
					<div class="col-xl-12  col-md-12">
					<div class="single-buy dflt_main_ban" (click)="onClickDefaulter(def.id)" *ngFor="let def of defaulters">
						<div class="row align-center" >
							<!-- <div class="col-xl-2 col-md-2">
								<div style="text-align: left;">
									<img [src]="def.image">
								</div>
							</div> -->
							<div class="col-xl-5 col-md-5 ">
								<div class="dflt_main_add_ban_res">
									<div class="dflt_main_tit">
										<img [src]="def.image">
									</div>
									<div class="tiles-info dflt_main_tit">
										<!-- <img [src]="def.image"> -->
										<h5>{{def.company_name ?? (def.company?.name ?? '-')}}</h5>
										<p>{{def.city}}, {{def.district?.district ?? (def.district ?? '-')}}</p>
										<!-- <p>{{def.city}}, {{def.district}}</p> -->
										<!-- <h5>{{def.cell}}</h5> -->
									</div>
								</div>
							</div>
							<div class="col-xl-7 col-md-7">
								<div class="dflt_main_tit_1">
									<p><span>Person Name</span> {{def.user?.name ?? '-'}}</p>
									<p><span>Mobile Number</span> {{def.mobile}}</p>
									<!-- <p>{{def.city}} | {{def.district}}</p> -->									
								</div>
								<div class="dflt_main_tit_1">
									<p><span>GST Number</span> {{def.gst}}</p>
									<p><span>Billing Date</span> {{def.built_date}}</p>
								</div>
							</div>
							<!-- </div> -->
							<!-- <div class="col-xl-3  col-md-3">
								<div class="users-info">
									<p>{{def.cell}}</p>
									<p>{{def.city}} | {{def.district}}</p>
									<p>{{def.built_date}}</p>
								</div>
							</div>
							<div class="col-xl-3  col-md-3">
								<div class="users-info">
									<p>GST No.</p>
									<p><b>{{def.gst}}</b></p>
								</div>
							</div> -->
						</div>
					</div>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="nextPage < totalPages">
					<a class="view-btn view-new" style="cursor: pointer;" (click)="more()"> View More</a>
				</div>
			</div>

		</div>
	</section>
	<!-- design area end -->
</main>