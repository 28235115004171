<div class="list_items_container">
  <p class="close_btn head_sidebar_menu_close" (click)="hideSidebar()">
    <i class="fa fa-times" aria-hidden="true"></i>
  </p>
  <div class="list_box">
    <a routerLink="blogs" (click)="hideSidebar()"
      ><img src="assets/img/sidebar_news.png" alt="" />Ceramic News</a
    >
    <a routerLink="page/membership-plans" (click)="hideSidebar()"
      ><img src="assets/img/mebership_plans.png" alt="" />Membership plan</a
    >
    <!-- <a routerLink="inbox" (click)="hideSidebar()"><img src="assets/img/sidebar_inquiry.png" alt=""> My Inbox <span class="sidebar-count" *ngIf="inboxCount">{{inboxCount}}</span> </a> -->
    <!-- <a routerLink="notifications" (click)="hideSidebar()"><img src="assets/img/sidebar_notification.png" alt=""> My Notification <span class="sidebar-count" *ngIf="notificationCount">{{notificationCount}}</span></a> -->
    <a routerLink="jobs/list" (click)="hideSidebar()"
      ><img src="assets/img/sidenar_job_junction.png" alt="" /> Job Junction</a
    >
    <!-- <a routerLink="chat" (click)="hideSidebar()">My Chat <span class="sidebar-count" *ngIf="chatCount">{{chatCount}}</span></a>
		<a routerLink="my-sale-post" (click)="hideSidebar()">My Sale Post</a>
		<a routerLink="my-buy-post" (click)="hideSidebar()">My Buy Post</a> -->
    <!-- <a routerLink="digital-card" (click)="hideSidebar()"><img src="assets/img/sidebar_digital_vcard.png" alt=""> My Digital Visiting Card</a> -->
    <!-- <a routerLink="analytics" (click)="hideSidebar()"><img src="assets/img/sidebar_analytics.png" alt=""> Analytics</a> -->
    <!-- <a routerLink="home" (click)="hideSidebar()">Multiple Whatsapp</a> -->
    <a routerLink="identity-find" (click)="hideSidebar()"
      ><img src="assets/img/sidebar_identify_find.png" alt="" /> Identity Find
    </a>
    <a routerLink="reference-find" (click)="hideSidebar()"
      ><img src="assets/img/sidebar_reference_find1.png" alt="" /> Refrence
      Find</a
    >
    <a routerLink="defaulter" (click)="hideSidebar()"
      ><img src="assets/img/sidebar_defaulter.png" alt="" /> Defaulter</a
    >

    <form [formGroup]="phoneForm">
      <h4>Whatsapp</h4>
      <div class="search-box4 whatsapp-search">
        <ngx-material-intl-tel-input
          class="search-input"
          fieldControlName="phone"
          [required]="true"
          [autoIpLookup]="false"
          [hidePhoneIcon]="true"
          [iconMakeCall]="false"
          autoSelectedCountry="in"
          
        >
        
        </ngx-material-intl-tel-input>
        <button class="ser-btn" (click)="sendMessage()">
          <i class="fa fa-paper-plane"></i>
        </button>
      </div>
    </form>
  </div>
</div>
