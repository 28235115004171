import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-upgrade-package',
    templateUrl: './upgrade-package.component.html',
    styleUrls: ['./upgrade-package.component.scss'],
    standalone: false
})
export class UpgradePackageComponent {
  readonly dialogRef = inject(MatDialogRef<LoginModalComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);

  constructor(
    private router: Router,
  ) { 
    this.router.events.subscribe(()=>{
			this.onNoClick()
		})
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
