<main>
  <!-- <app-breadcrumb
    [pageName]="'Directory'"
    *ngIf="!isCompactView"
  ></app-breadcrumb> -->
  <!-- design area start -->
  <section class="design-area directory-section" id="service">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 text-center">
          <div class="section-title">
            <h5 class="mt-5">Directory</h5>
            <h1>Directory List</h1>
          </div>
        </div>
      </div>
      <div class="row"></div>
      <div class="row justify-content-center">
        <ng-container *ngFor="let l of list">
          <div class="col-xl-3 col-md-4 col-sm-4 col-xs-12 ng-star-inserted">
            <a
              (click)="onClickDirectyory(l.id, l.type, l.slug)"
              class="card card-directory border-0 text-center"
            >
              <div class="card-body">
                <img
                  [src]="l.image"
                  alt="Directory Image"
                  class="directory-image"
                />
                <h2>{{ l.name }}</h2>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</main>
