import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { identity, Observable, throwError } from 'rxjs';
import { APIResponse } from '../Modal/apiresponse';
import { apiConstants } from '../constants/api-constants';
import { catchError, find } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class refrenceFind {
	constructor(private http: HttpClient) { }

	getList(params:HttpParams=new HttpParams({})): Observable<APIResponse> {
		return this.http.get(apiConstants.company.refrence_find,{
      params:params
    }

    ).pipe(catchError(this.handleError));
	}
  // company/search.iden-find
	// save(form: any): Observable<APIResponse> {
	// 	return this.http.post(apiConstants.defaulter.save, form).pipe(catchError(this.handleError));
	// }

	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}
}