import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-hotel-room-amenities',
    templateUrl: './hotel-room-amenities.component.html',
    styleUrls: ['./hotel-room-amenities.component.css'],
    standalone: false
})
export class HotelRoomAmenitiesComponent implements OnInit {
	@Input('rooms') rooms = [];
	@Input('amenities') amenities = [];
	@Input('description') description = '';

	constructor() { }

	ngOnInit(): void {
	}

}
