<div class="container bookvisit_popup_ban">
    <h2 class="acess_ban"><i class="fa fa-bookmark" aria-hidden="true"></i></h2>
    <h3>Book a visit</h3>
    <div *ngIf="links.length > 0; else noLinks">
      <div *ngFor="let link of links ;let i = index">
        <p>{{ i+1 }}.<a [href]="link" target="_blank"> Book Url</a></p>
      </div>
    </div>
    <ng-template #noLinks>
      <p>No links available.</p>
    </ng-template>
  </div>
