import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InformativePagesService } from "src/app/services/informative-pages.service";
import { UserService } from "src/app/services/user.service";

@Component({
    selector: "app-pages",
    templateUrl: "./pages.component.html",
    styleUrls: ["./pages.component.scss"],
    standalone: false
})
export class PagesComponent {
  data: any = "";
  slug: string = "";
  constructor(
    private pageService: InformativePagesService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let params: any = this.route.snapshot.params;
    // this.getPageContent(params.slug)
    this.route.url.subscribe((url) => {
      console.log()
      if (url[1]?.path) {
        this.getPageContent(url[1]?.path)
      }
    });
  }

  getPageContent(slug):void{
    history.state.slug = slug;
    this.slug = slug
    this.pageService.page(this.slug).subscribe((res) => {
      if (res.success) {
        this.data = res.data;
      } else {
        this.userService.errorMessage(res.message);
      }
    });
  }
}
