import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray, UntypedFormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class FormValidatorServiceService {
	constructor() { }
	validateAllFormFields(formGroup: any) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
			else if (control instanceof FormArray) {
				this.validateAllFormFields(control);
			}
		});
	}

	validateFormField(formGroup:UntypedFormGroup,field:string,message:string|null=null) {
		const control = formGroup.get(field);
		control.markAsTouched({ onlySelf: true });
		if (message) {
		  control.setErrors({ custom: message });
		}
	  }
}
