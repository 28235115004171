import { Component, OnInit } from '@angular/core';
import { InformativePagesService } from 'src/app/services/informative-pages.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-packing-details',
    templateUrl: './packing-details.component.html',
    styleUrls: ['./packing-details.component.css'],
    standalone: false
})
export class PackingDetailsComponent implements OnInit {
  type:number = 1;
  data1 :any = '';
  data2 : any = '';

  constructor(
    private pageService : InformativePagesService,
    private userService :UserService
  ) { }

  ngOnInit(): void {
    this.pageService.domestic().subscribe(res => {
      if(res.success){
        this.data1 = res.data
        // this.data2 = res.data
      }
      else{
        this.userService.errorMessage(res.message);

      }
    })
    this.pageService.export().subscribe(res => {
      if(res.success){
        this.data2 = res.data
        console.log(this.data2)
      }
      else{
        this.userService.errorMessage(res.message);

      }
    })
  }
  // typeChnage(type):void{
  //   this.type = parseInt(type)
  // }

}
