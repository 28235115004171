<div class="details content-details manue_cont_app_pro_size flex-grow-1">
    <h5 class="manue_cont_app_pro_size_title"><img src="assets/img/product_size.png" class="img-fluid" alt=""> <span>Product and Size</span> </h5>
    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">
        <h4>Hotel</h4>
        <div  class="manue_cont_app_pro_size_sub-text" >
            <div *ngFor="let r of rooms">
                <p class="mb-0 mr-1">{{r.room_name}}</p>
                <p ><span>{{r.price}}</span></p>
            </div>
        </div>
    </div>

    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">
        <div>
            <h4>Amenities</h4>
            <div class="manue_cont_app_pro_size_sub-text" *ngFor="let a of amenities">
                <p class="tiles-product-size">{{a}}</p>
            </div>
        </div>
        <div class="manue_cont_app_pro_size_sub-dec">
            <h6>Product Description</h6>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever...</p>
        </div>
            
    </div>
</div>