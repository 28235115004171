import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-top-slider',
    templateUrl: './top-slider.component.html',
    styleUrls: ['./top-slider.component.scss'],
    standalone: false
})
export class TopSliderComponent {
  @Input() id:string
  @Input('analytic') analytic_type:number
  @Input() banners:Array<any>
  @Output('onBannerClick') onBannerClick:EventEmitter<any> = new EventEmitter<any>();

  onClick(banner){
    const analytic_type = this.analytic_type
    this.onBannerClick.emit({banner,analytic_type})
  }
}
