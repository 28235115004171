import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-help-support',
    templateUrl: './help-support.component.html',
    styleUrls: ['./help-support.component.css'],
    standalone: false
})
export class HelpSupportComponent implements OnInit {
	message:any = '';
	user:any;
	constructor(
		private userService : UserService,
	) { }

	ngOnInit(): void {
		this.user = this.userService.getUser();
	}
	submit():void{
		if(this.message != ''){
			this.userService.support({query: this.message, company_id: this.user.company.id}).subscribe(res =>{
				if(res.success){
					this.message = '';
					this.userService.sucessMessage(res.message)
				}else{
					this.userService.errorMessage(res.message);
				}
			})
		}
	}
}
