<div class="details content-details manue_cont_app_pro_size flex-grow-1">
    <h5 class="manue_cont_app_pro_size_title"><img src="assets/img/product_size.png" class="img-fluid" alt=""> <span>Product and Size</span> </h5>
    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">
        <h4>Main Products </h4>
        <div class="manue_cont_app_pro_size_sub-text">
           <p *ngFor="let mp of mainProducts" >{{mp.product.category}}</p>
        </div>
    </div>

    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">
        <!-- <h4>Sub Products</h4>
        <div class="manue_cont_app_pro_size_sub-text">
            <p *ngFor="let sp of subProducts" >{{sp}}</p>
        </div> -->
        <!-- <div class="manue_cont_app_pro_size_sub-dec"> -->
            <h4>Product Description</h4>
            <p>{{description}}</p>
        <!-- </div> -->
    </div>
</div>