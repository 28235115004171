import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError, Observable, Subject } from 'rxjs';
import { apiConstants } from '../constants/api-constants';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APIResponse } from '../Modal/apiresponse';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class InformativePagesService {
	constructor(private http: HttpClient) { }

	page(slug:string): Observable<APIResponse> {
		return this.http.get(apiConstants.pages.page+slug).pipe(catchError(this.handleError));
	}

	about(): Observable<APIResponse> {
		return this.http.get(apiConstants.pages.about).pipe(catchError(this.handleError));
	}
	privacy(): Observable<APIResponse> {
		return this.http.get(apiConstants.pages.privacy).pipe(catchError(this.handleError));
	}
	term(): Observable<APIResponse> {
		return this.http.get(apiConstants.pages.term).pipe(catchError(this.handleError));
	}
	member(): Observable<APIResponse>{
		return this.http.get(apiConstants.pages.member).pipe(catchError(this.handleError));
	}
	specification(): Observable<APIResponse>{
		return this.http.get(apiConstants.pages.specification).pipe(catchError(this.handleError));
	}
	domestic():Observable<APIResponse>{
		return this.http.get(apiConstants.pages.domestic).pipe(catchError(this.handleError));
	}
	export():Observable<APIResponse>{
		return this.http.get(apiConstants.pages.export).pipe(catchError(this.handleError));
	}
		
	// member(): Observable<APIResponse> {
	// 	return this.http.get(apiConstants.pages.member).pipe(catchError(this.handleError));
	// }	

	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		return throwError('Something bad happened; please try again later.');
	}
}
