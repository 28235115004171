<main>
    <!-- <app-breadcrumb [pageName]="'Defaulter Details'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
					<div class="section-title ">
						<h4>Defaulter</h4>
						<h1>Defaulter Details</h1>
						<!-- <img src="assets/img/line.svg" width="120"> -->
					</div>
				</div>
            </div>

            <div class="row box-h">
                <div class="col-md-12 col-h text-center mt-3 mb-5 justify-content-center">
                    <img class="img-responsive" [src]="data?.image" alt="Defaulter Image">
                </div>
                <div class="col-md-12 col-h">
                    <div class="details gti_defa_detai_list_ban">
                        <div >
                            <h4>Details</h4>
                            <table>
                                <tr>
                                    <td>Firm Name</td>
                                    <td>{{data?.company_name ?? (data?.company?.name ?? '-')}}</td>
                                </tr>
                                <tr>
                                    <td>Role</td>
                                    <td>{{data?.company_role ?? (data?.company?.role ?? '-')}}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>{{data?.country.country}}</td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>{{data?.state.state}}</td>
                                </tr>
                                <tr>
                                    <td>District</td>
                                    <td>{{data?.district.district}}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>{{data?.city}}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{{data?.address ?? '-'}}</td>
                                </tr>
                                <tr >
                                    <td>Contact No.</td>
                                    <td>{{data?.mobile}}</td>
                                </tr>
                                <!-- <tr *ngIf="data?.cell_alt">
                                    <td>Other Cell No.</td>
                                    <td>{{data?.cell_alt}}</td>
                                </tr> -->
                                <tr >
                                    <td>Whatsapp No.</td>
                                    <td>{{data?.whats_app}}</td>
                                </tr>
                                <tr>
                                    <td>GST No.</td>
                                    <td>{{data?.gst}}</td>
                                </tr>
                                <tr>
                                    <td>Due Amount</td>
                                    <td>{{data?.amount_due}}</td>
                                </tr>
                                <tr>
                                    <td>Date of Billing</td>
                                    <td>{{data?.billing_date}}</td>
                                </tr>
                                <tr>
                                    <td class="border-0">Description</td>
                                    <td class="border-0">{{data?.description}}</td>
                                </tr>
                            </table>
                            <h4>Added by</h4>
                            <table>
                                <tr >
                                    <td>Company Name</td>
                                    <td>{{data?.user.company.name}}</td>
                                </tr>
                                <!-- <tr *ngIf="data?.user_name">
                                    <td>Add by</td>
                                    <td>{{data?.user_name}}</td>
                                </tr> -->
                                <tr>
                                    <td>City</td>
                                    <td>{{data?.user.company.city}}</td>
                                </tr>
                                <tr>
                                    <td>Company Person Name</td>
                                    <td>{{data?.user.name}}</td>
                                </tr>
                                <tr>
                                    <td class="border-0">Contact No.</td>
                                    <td class="border-0">{{data?.user.mobile}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- design area end -->

</main>