import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { GalleryItem, ImageItem } from 'ng-gallery';

@Component({
  selector: 'app-exhibition-gallary',
  templateUrl: './exhibition-gallary.component.html',
  styleUrl: './exhibition-gallary.component.scss',
  standalone: false,
})
export class ExhibitionGallaryComponent {
  readonly dialogRef = inject(MatDialogRef<LoginModalComponent>);
  readonly data = inject<any>(MAT_DIALOG_DATA);
  images:GalleryItem[] = []

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.images = this.data.images.map((image)=>{
      return new ImageItem({
        src :  image
      })
    })
  }
}
