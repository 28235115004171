import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SellerPostService } from '../../../services/seller-post.service';
import { UserService } from '../../../services/user.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { HomeService } from '../../../services/home.service';


@Component({
    selector: 'app-seller-posts-list',
    templateUrl: './seller-posts-list.component.html',
    styleUrls: ['./seller-posts-list.component.css'],
    standalone: false
})
export class SellerPostsListComponent implements OnInit {
	list:any = [];
	extra:any = [];
	nextPage:number = 0;
	totalPages:number = 0;

	products:any = [];
	sizes:any = [];

	selectedProduct:any = '';
	selectedProductType:any = '';
	selectedSize:any = '';

	//added to adjust banner and form alternatively
	data:any = [];
	bannercategorycount:number = 0;
	bannercurrentcategorycount:number = 0;



	constructor(
		private homeService: HomeService,
		private sellerPostService: SellerPostService,
		private userService: UserService,
		private router: Router,
		private analyticsService: AnalyticsService,
	) { }
	onChangeProduct(val):void{
		if(val != ''){
			val = val.split("-")
			this.selectedProduct = val[0];
			this.selectedProductType = val[1];
		}else{
			this.selectedProduct = ''
			this.selectedProductType = '';
		}
	}
	onChangeSize(val):void{
		this.selectedSize = val;
	}
	ngOnInit(): void {
		let params = {};
		if(history.state.filter && history.state.product_type){
			params = { ...params, ...{filter : history.state.filter}};
			params = { ...params, ...{product_type : history.state.product_type}};
		}
		this.sellerPostService.getPostList(params).subscribe(resposnse => {

			this.homeService.getHome().subscribe(response => {
				if(response.success){
					this.data = response.data;
					this.bannercategorycount=response.data.exhibitions.length;
					if(this.bannercategorycount>0) {
						this.bannercurrentcategorycount=1;
					}
				}else{
					this.userService.errorMessage(response.message);
				}
			});

			if(resposnse.success){
				this.extra = resposnse.extra;
				this.products = resposnse.extra.product;
				this.sizes = resposnse.extra.size;
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				this.list = resposnse.data;
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		});
	}
	filterOption(): void {
		let param = {};
		if (this.selectedProduct != '') {
			param = { ...param, ...{ product_id: this.selectedProduct } }
			param = { ...param, ...{ product_type: this.selectedProductType } }
		}
		if (this.selectedSize != '') {
			param = { ...param, ...{ size_id: this.selectedSize } }
		}
		if(history.state.filter && history.state.product_type){
			param = { ...param, ...{filter : history.state.filter}};
			param = { ...param, ...{product_type : history.state.product_type}};
		}
		this.sellerPostService.getPostList(param).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				this.list = resposnse.data
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		})
	}
	morePosts():void{
		let param = {next_page: this.nextPage};
		if (this.selectedProduct != '') {
			param = { ...param, ...{ product_id: this.selectedProduct } }
			param = { ...param, ...{ product_type: this.selectedProductType } }
		}
		if (this.selectedSize != '') {
			param = { ...param, ...{ size_id: this.selectedSize } }
		}
		if(history.state.filter && history.state.product_type){
			param = { ...param, ...{filter : history.state.filter}};
			param = { ...param, ...{product_type : history.state.product_type}};
		}
		this.sellerPostService.getPostList(param).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				resposnse.data.forEach(element => {
					this.list.push(element)
				});
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		})

		if(this.bannercategorycount>0) {
		if(this.bannercategorycount==this.bannercurrentcategorycount) {
			this.bannercurrentcategorycount=1;
		} else {
			this.bannercurrentcategorycount+=1;
		}
	}

	}

	 calculateExh(current, total) {
		 let newval = current / 8;
         while (newval>total){
			 newval=newval-total;
		 }
		return newval;
	  }

	  calculateBannerNo(current, total) {
		let newval = current / 8;
		while (newval>total){
			newval=newval-total;
		}
	   return newval;
	 }

	  randomNumber(min, max) {
		return  Math.floor(Math.random() * (max - min + 1)) + min;
	  }
	  
	  onClickExhibition(cId, type):void{
		this.router.navigate(['company/details'],{state: {id: cId, type: type}})
	}

	analytics(id, type):void{
		this.analyticsService.saveCount({id: id, type : type}).subscribe();
	}
}
