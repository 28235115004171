import { FormValidatorServiceService } from './../../../services/form-validator-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css'],
    standalone: false
})

export class ForgotPasswordComponent implements OnInit {

  	forgotPasswordForm: FormGroup;
	constructor(
		private router: Router,
		private userService : UserService,
		private formValidationService: FormValidatorServiceService, 
		private formBuilder: FormBuilder
	) {}
	
	ngOnInit(): void {
		this.forgotPasswordForm = this.formBuilder.group({
			email:['', [Validators.required]]
		});
	}

	onSubmit(): void {
		if (this.forgotPasswordForm.valid) {
			this.userService.forgot_password({email:this.forgotPasswordForm.value.email}).subscribe(response => {
				if(response.success) {
					this.userService.sucessMessage(response.message);
					this.userService.setUserId(response.data.id);
					this.router.navigate(['verify-otp']);
				}
				else {
					this.userService.errorMessage(response.message);
				}
			});	
		}
		else{
			this.formValidationService.validateAllFormFields(this.forgotPasswordForm);
			let firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
			firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}
	
}
