<main>
    <!-- <app-breadcrumb [pageName]="'Identity Find'"></app-breadcrumb> -->
    <!-- design area start -->
    <section class="design-area" id="service">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 text-center">
                    <div class="section-title ">
                        <!-- <h4>Identity</h4> -->
                        <h1>Identity Find</h1>
                    </div>
                </div>
            </div>

            <div class="row box-h">
                <div class="col-md-12 col-h text-center mb-5 justify-content-center">
                    <form class="identy_find_form_ban" (submit)="onSubmit()">
                        <ngx-material-intl-tel-input
          class="search-input"
          [fieldControl]="mobile"
          [required]="true"
          [autoIpLookup]="false"
		  [hidePhoneIcon]="true"
		  [iconMakeCall]="false"
		  autoSelectedCountry="in"
        ></ngx-material-intl-tel-input>
        <!-- <input type="submit" name=''value='Search' class="mt-3 identy_find_form_inpt" /> -->
        <button type="submit" name=''value='Search' class=" identy_find_form_inpt" ><i class="fa fa-paper-plane"></i></button>
                    </form>
                </div>
                <div class="col-md-12 col-h mt-4" >
                    <div class="details identy_find_detail_ban"  *ngFor="let iden of identity">
                        <h4>Details</h4>
                        <div style="overflow-x:auto;">
                            <table>
                                <tr>
                                    <td style="width: 50%;">Company Name</td>
                                    <td>{{iden.company.name}}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>{{iden.company.city}}</td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>{{iden.company.state.state}}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>{{iden.company.country.country}}</td>
                                </tr>
                                <tr>
                                    <td>Role</td>
                                    <td>{{iden.company.role}}</td>
                                </tr>
                                <tr>
                                    <td>Contact Person Name</td>
                                    <td>{{iden.name}}</td>
                                </tr>
                                <tr>
                                    <td>Contact Number</td>
                                    <td>{{iden.mobile}}</td>
                                </tr>                                
                            </table>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </section>
    <!-- design area end -->

</main>