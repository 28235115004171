import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExhibitionService } from 'src/app/services/exhibition.service';
import { UserService } from 'src/app/services/user.service';
import { LoginModalComponent } from '../common/login-modal/login-modal.component';
import { BookvisitComponent } from '../common/bookvisit/bookvisit.component';
import { ExhibitionGallaryComponent } from '../common/exhibition-gallary/exhibition-gallary.component';
// import { Router } from '@angular/router';



@Component({
    selector: 'app-exhibition',
    templateUrl: './exhibition.component.html',
    styleUrls: ['./exhibition.component.scss'],
    standalone: false
})
export class ExhibitionComponent implements OnInit {

  company_exhibition = []
  virtual_exhibition = []

  constructor(
    private userService : UserService,
    private router: Router,
    private exhibitionService : ExhibitionService,
    public dialog: MatDialog,
  ){}

  ngOnInit(): void {
    this.getCompanyExhibition()
    this.getVirtualExhibition()
  }

  getCompanyExhibition():void{
    this.exhibitionService.get_company_exhibition()
      .subscribe(res=>{
        if (res.success) {
          this.company_exhibition = res.data
        }
      })
  }
  getVirtualExhibition():void{
    this.exhibitionService.get_virtual_exhibition()
      .subscribe(res=>{
        if (res.success) {
          this.virtual_exhibition = res.data
        }
      })
  }

  virtualExhibitionClick(exhibition) {
    this.exhibitionService.click_virtual_exhibition({
      id:exhibition.id
    }).subscribe(()=>{})

    window.open(exhibition.view_link,'_blank')
  }
  exhibitionClick(exhibition) {
    this.exhibitionService.click_company_exhibition({
      id:exhibition.id
    }).subscribe(()=>{})

    window.open(exhibition.view_link,'_blank')
  }
  exhibitionReviewClick(exhibition) {
    window.open(exhibition.review_url,'_blank')
  }
  exhibitionBookVisitClick(exhibition) {
    this.dialog.open(BookvisitComponent,{
      data : {
        links : exhibition.links
      }
    })
  }
  exhibitionGalleryClick(exhibition) {
    this.dialog.open(ExhibitionGallaryComponent,{
      data : {
        images : exhibition.images
      }
    })
  }

  whatsappClick(company) {
    event.stopPropagation();
    const { whatsapp } = company
    if(!this.userService.isLoggedIn()){
      this.dialog.open(LoginModalComponent)
      return ;
    }

    window.open(`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hi,%20Got%20reference%20from%20GTI%20website`,'_blank')
  }

  clickCompany(company,event) {
    event.stopPropagation();
    const { name , id , type } = company
    this.router.navigate([`/company/${this.slug(name)}/${id}`], {
      state: { id: id, type: type },
    });
  }

  slug(value) {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^A-Z0-9]/gi, "-");
  }

}
