import { Component, OnInit } from '@angular/core';
// import { InformationEvent } from 'http';
import { InformativePagesService } from 'src/app/services/informative-pages.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-membership',
    templateUrl: './membership.component.html',
    styleUrls: ['./membership.component.css',
        '../../../assets/css/ckeditor.css'],
    standalone: false
})
export class MembershipComponent implements OnInit {
  data: any = '';
  constructor(
    private pageService:InformativePagesService,
    private userService:UserService
  ) { }

  ngOnInit(): void {
    this.pageService.member().subscribe(res => {
      if(res.success){
        this.data = res.data
      }else{
        this.userService.errorMessage(res.message);
      }
    }
      )
    }

}
