<main>
	<!-- <app-breadcrumb [pageName]="'Buyer post'"></app-breadcrumb> -->
	<!-- design area start -->
	<section class="design-area" id="service">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 text-center">
					<div class="section-title ">
						<h1>Buyer post details</h1>
						<img src="assets/img/line.svg" width="120" alt="">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4" *ngFor="let slide of data?.images">
					<img [src]="slide" class="buyer-seller-image" alt="Post Image">
				</div>
				<!-- <div class="col-xl-12">
					<div class="design-item owl-carousel" style="display: block;">
						<owl-carousel-o [options]="customOptions">
							<ng-container *ngFor="let slide of data?.images" [ngClass]="'buyer-slider-item'">
								<ng-template carouselSlide>
									<div class="single-new">
										<img [src]="slide" class="buyer-seller-image" alt="Post Image">
									</div>
								</ng-template>
							</ng-container>
						</owl-carousel-o>
					</div>
				</div> -->
			</div>
			<div class="row box-h mt-30">
				<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 col-h">
					<div class="details">
						<h2>Details</h2>
						<div style="overflow-x:auto;">
							<table>
								<tr *ngIf="data?.quantity">
									<td>Quantity</td>
									<td>{{data?.quantity}}</td>
								</tr>
								<tr>
									<td>Product Name</td>
									<td>{{data?.product}}</td>
								</tr>
								<tr *ngIf="data?.size">
									<td>Size</td>
									<td>{{data?.size}}</td>
								</tr>
								<tr>
									<td>Grade</td>
									<td>{{data?.grade}}</td>
								</tr>
								<tr>
									<td>Sale Type</td>
									<td>{{data?.requirement_type}}</td>
								</tr>
								<tr>
									<td>Product Type</td>
									<td><span *ngFor="let f of data?.features; let i = index"><span *ngIf="i != 0"> | </span>{{f}}</span></td>
								</tr>
								<tr>
									<td>Requirement Time</td>
									<td>{{data?.requirement_time}}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 col-h">
					<div class="details">
						<h2>Buyer Details</h2>
						<div style="overflow-x:auto;">
							<table>
								<tr>
									<td>Buyer Name</td>
									<td>{{data?.company_name}}</td>
								</tr>
								<tr>
									<td>Roll</td>
									<td>{{data?.company_role}}</td>
								</tr>
								<tr>
									<td>City</td>
									<td>{{data?.city}}</td>
								</tr>
								<tr>
									<td>Country</td>
									<td>{{data?.country}}</td>
								</tr>

								<tr>
									<td>Date</td>
									<td>{{data?.date}}</td>
								</tr>
								<tr>
									<td>Time</td>
									<td>{{data?.time}}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div class="col-xl-12 col-md-12 col-sm-12 col-xs-12 col-h mt-5">
					<div class="details">
						<h2>Description</h2>
						<div style="overflow-x:auto;">
							<table>
								<tr>
									<td>{{data?.product_description}}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- design area end -->

	<section class="bottom-sec bg-grey">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="bottom-link text-center">
						<ul>
							<li><a (click)="analytics(data?.id, 1)" href="tel:{{data?.company_contact}}" class="call-btn"> Call</a></li>
							<li><a (click)="analytics(data?.id, 2)" href="https://api.whatsapp.com/send?phone={{data?.company_whatsapp}}&text=Hi,%20Got%20reference%20from%20GTI%20website." target="_blank" class="whatsapp-btn"> Whatsapp</a></li>
							<li><a routerLink="/chat" [state]="{cIdChatid: data?.id, name: data?.company_name,image: data?.logo}" class="chat-btn"> Chat</a></li>
							<li><a class="view-btn" style="padding: 15px;" routerLink="/buyer-posts" [state]="{filter: data?.product_id, product_type: data?.product_type}"> Same Product Other Buy List</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>