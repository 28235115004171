<div class="details content-details manue_cont_app_pro_size flex-grow-1">
    <h5 class="manue_cont_app_pro_size_title"><img src="assets/img/product_size.png" class="img-fluid" alt=""> <span>Product and Size</span> </h5>
    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">
        <h4>Service Type</h4>
        <div class="manue_cont_app_pro_size_sub-text">
            <p  class="tiles-product-size" *ngFor="let s of services">{{s}}</p>
        </div>
        <div class="manue_cont_app_pro_size_sub-dec">
            <h6>Service Description</h6>
            <p>{{description}}</p>
        </div>
    </div>
</div>