<div class="details content-details manue_cont_app_pro_size flex-grow-1">
    <!-- <h2 clas="manue_cont_app_pro_size_title"> </h2> -->
    <h5 class="manue_cont_app_pro_size_title"><img src="assets/img/product_size.png" class="img-fluid" alt=""> <span>Product and Size</span> </h5>
    <div style="overflow-x:auto;" class="manue_cont_app_pro_size_sub-ban">

        <div >
            <div class="" *ngFor="let category of categories">
                <div class="manue_cont_app_pro_size_sub-text">
                    <p class="tiles-product-size text-capitalize" >{{category.subcategory.product}}</p>
                    <p class="manue_cont_app_pro_size_sub-ali"><span *ngFor="let size of category.sizes">{{size.size}}</span></p>                
                </div>
            </div>
           <div class="manue_cont_app_pro_size_sub-dec">
               <h6>Product Description</h6>
               <p>{{description}}</p>
           </div>     
        </div>
    </div>
</div>