import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DefaulterService } from '../../services/defaulter.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-defaulter',
    templateUrl: './defaulter.component.html',
    styleUrls: ['./defaulter.component.css'],
    standalone: false
})
export class DefaulterComponent implements OnInit {
	nextPage:number = 0;
	totalPages:number = 0;

	defaulters:any = [];
	companies:any = [];
	constructor(
		private defaulterService : DefaulterService,
		private userService : UserService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.defaulterService.getList({}).subscribe(res => {
			if(res.success){
				// console.log(res.data)
				this.nextPage = res.data.data.next_page;
				this.totalPages = res.data.data.total;
				this.defaulters = res.data.data;
				// this.companies = res.extra.companies
			}else{
				this.userService.errorMessage(res.message);
			}
		})
	}
	onClickDefaulter(id): void {
		const deft = this.defaulters.find((c) => {
			return c.id === id;
		});
		this.router.navigate(['defaulter/show'], {state: {deft: deft}})
	}
	more():void{
		let param = {next_page: this.nextPage};
		this.defaulterService.getList(param).subscribe(resposnse => {
			if(resposnse.success){
				this.nextPage = resposnse.extra.next_page;
				this.totalPages = resposnse.extra.total_pages;
				// this.totalPages = resposnse.data
				console.log( resposnse.extra.next_page)
				resposnse.data.forEach(element => {
					this.defaulters.push(element)
				});
			}else{
				this.userService.errorMessage(resposnse.message);
			}
		})
	}
}